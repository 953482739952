<template>
  <div v-if="isVisible" class="inspection_log_modal_overlay">
    <div class="inspection_log_header">
      <div class="inspection_log_title">{{ $t("term.dataLog") }}</div>
      <div class="inspection_log_header_x" @click="$emit('close')"></div>
    </div>
    <div class="inspection_log_container">
      <div
        class="inpsection_log_msg"
        v-for="(status, index) in statusText"
        :key="index"
        :style="{ color: getColor(status.severity) }"
      >
        {{ getTitle(status.severity) }} {{ status.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    statusText: Array,
  },
  emits: ["close"],
  methods: {
    getColor(severity) {
      if (severity >= 0 && severity <= 3) {
        return "red";
      } else if (severity >= 4 && severity <= 5) {
        return "yellow";
      } else if (severity >= 6 && severity <= 7) {
        return "green";
      }
    },
    getTitle(severity) {
      const title = {
        0: "[EMERGENCY]",
        1: "[ALERT]",
        2: "[CRITICAL]",
        3: "[ERROR]",
        4: "[WARNING]",
        5: "[NOTICE]",
        6: "[INFO]",
        7: "[DEBUG]",
      };
      return title[severity] || "";
    },
  },
};
</script>

<style lang="scss">
.inspection_log_modal_overlay {
  position: fixed;
  top: calc(20% - 10px);
  left: 35%;
  width: 30%;
  height: calc(60% - 10px);
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inspection_log_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(32, 32, 32, 0.85);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.inspection_log_title {
  @include flexbox;
  width: 100px;
  font-size: 1.8rem;
  color: $main_white;
  font-weight: 700;
}
.inspection_log_header_x {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: contain;
  cursor: pointer;
}

.inspection_log_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(90% - 10px);
  padding: 20px;
  overflow: auto;
}

.inspection_log_container::-webkit-scrollbar {
  width: 5px;
}

/* 스크롤바 트랙 스타일 */
.inspection_log_container::-webkit-scrollbar-track {
  background: transparent;
}

/* 스크롤바 손잡이 스타일 */
.inspection_log_container::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}

/* 스크롤바 손잡이:hover 스타일 */
.inspection_log_container::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}

.inpsection_log_msg {
  flex-shrink: 0;
  font-size: 1.2rem;
  color: #33ff00;
  width: 100%;
  height: 40px;
}
</style>
