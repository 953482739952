import {format} from 'date-fns'

export default class Ablum{
  constructor(ablum){
    this.id = null;
    this.facilityId = null;
    this.facilityMapId = null;
    this.cameraId = null;
    this.name = null; 
    this.photoCount = null;
    this.crackCount = null;
    this.otherCount = null;
    this.createdDatetime = null;
    this.analysisProcess = false;
    this.downloadProcess = false;
    this.downloadPercentage = 0;
    this.model = null;
    
    if(ablum){
      this.id = ablum.id;
      this.facilityId = ablum.facilityId;
      this.facilityMapId = ablum.facilityMapId;
      this.cameraId = ablum.cameraId;
      this.name = ablum.name; 
      this.photoCount = ablum.photoCount;
      this.crackCount = ablum.crackCount;
      this.otherCount = ablum.otherCount;
      this.createdDatetime = format(ablum.createdDatetime, "yyyy-MM-dd HH:mm:ss");  
      this.analysisProcess = false;
      this.downloadProcess = false;
      this.downloadPercentage = 0;
      this.model = null;
    }
  }
}