<template>
  <div class="sorting_box">
    <DefaultSelectBox
      :className="'simple right text_center with_auto'"
      @controlOptionToggle="sortingOptionToggle"
      :isOptionOpen="isOptionOpen"
      :defaultName="$t('term.newest')"
      :selectedName="this.clickedRecentValue"
      @update:isOptionOpen="isOptionOpen = $event"
    >
      <ul>
        <li
          v-for="(option, index) in sortingOption"
          :key="option + index"
          @click="handleClickSortingOption(option)"
          :class="{ active: option === this.clickedRecentValue }"
        >
          <button class="option_text" type="button">{{ option }}</button>
        </li>
      </ul>
    </DefaultSelectBox>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";

export default {
  name: "FacilitySorting",
  components: { DefaultSelectBox },
  emits: ["handleSortArray"],
  data() {
    return {
      isOptionOpen: false,
    };
  },
  props: {
    clickedRecentValue: {
      type: String,
      required: true,
    },
    sortingOption: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    sortingOptionToggle() {
      this.isOptionOpen = !this.isOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickSortingOption(value) {
      this.isOptionOpen = false;

      this.$emit("handleSortArray", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.sorting_box {
  @include flexbox(center, flex-end, row);
  margin-bottom: 20px;
}
</style>
