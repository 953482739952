import {format} from 'date-fns'

export default class Segmentation{
  constructor(segmentation){
    this.id = null;
    this.albumCrackRecognitionId = null;
    this.photoId = null;
    this.crackCount = null;
    this.crackRecognitionType = null;
    this.cracksInfoPath = null;
    this.createdDatetime = null;

    if(segmentation){
      this.id = segmentation.id;
      this.albumCrackRecognitionId = segmentation.albumCrackRecognitionId;
      this.photoId = segmentation.photoId;
      this.crackCount = segmentation.crackCount;
      this.crackRecognitionType = segmentation.crackRecognitionType;
      this.cracksInfoPath = segmentation.cracksInfoPath;
      this.createdDatetime = format(segmentation.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    }
  }
}