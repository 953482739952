<template>
  <section class="no_facility">
    <EmptyResult :title="$t('message.NoFacilitiesRegisteredText')">
      <button
        type="button"
        @click="clickAddFacility"
        class="default_border_hover_button add_facility"
      >
        {{ $t("button.addFacility") }}
      </button>
    </EmptyResult>
  </section>
</template>
<script>
import EmptyResult from "@/component/no-result/EmptyResult.vue";
import { mapState } from "vuex";

export default {
  components: { EmptyResult },
  name: "NoFacilitiesMessage",
  emits: ["handleOpenAddForm"],
  computed: {
    ...mapState(["user", "facilityTypeCounts"]),
  },
  props: {
    totalFacility: {
      type: Number,
      required: true,
    },
  },
  methods: {
    clickAddFacility() {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.$emit("handleOpenAddForm");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.no_facility {
  text-align: center;
}
h3 {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.5;

  @include mobile {
    font-size: 2rem;
  }
}
.icon_box {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url("@/../public/images/location_exclamation.svg") no-repeat
    center;
  background-size: contain;
  margin-bottom: 20px;
}
.add_facility {
  margin-top: 30px;
  font-size: 1.6rem;
}
</style>
