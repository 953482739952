<template>
  <div v-if="isMissionSaveModalVisible" class="inspection_mission_save_modal">
    <div class="inspection_mission_save_modal_header">
      {{ $t("term.saveMission") }}
    </div>
    <div
      class="inspection_mission_save_modal_x"
      @click="$emit('closeModal')"
    ></div>
    <div class="inspection_mission_save_modal_input_wrap">
      <input
        class="inspection_mission_save_modal_input input"
        type="text"
        :placeholder="$t('droneAlert.enterMissionName')"
        ref="missionName"
        @input="checkInputLimit($event, 20)"
      />
      <div class="inspection_mission_save_button button" @click="saveMission">
        {{ $t("button.save") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeModal", "saveMission"],
  props: {
    isMissionSaveModalVisible: Boolean,
  },
  methods: {
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
        event.target.value = event.target.value.substr(0, limit);
      }
    },
    saveMission() {
      if (this.$refs.missionName.value) {
        this.$emit("saveMission", this.$refs.missionName.value);
        this.$refs.missionName.value;
      } else {
        this.$store.commit("openAlert", this.$t("droneAlert.enterMissionName"));
      }
    },
  },
};
</script>

<style lang="scss">
.inspection_mission_save_modal {
  position: fixed;
  left: calc(50% - 200px);
  top: calc(50% - 45px);
  width: 400px;
  height: 90px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}
.inspection_mission_save_modal_header {
  @include flexbox;
  color: $main_white;
  font-size: 2rem;
  background-color: rgba(32, 32, 32, 0.85);
  width: 100%;
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.inspection_mission_save_modal_x {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
  cursor: pointer;
}
.inspection_mission_save_modal_input_wrap {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 50px;
  padding: 0px 20px 0px 20px;
}
.inspection_mission_save_modal_input {
  width: calc(100% - 70px);
  height: 30px;
  font-size: 1.4rem;
}
.inspection_mission_save_button {
  @include flexbox;
  width: 50px;
  height: 30px;
  font-size: 1.4rem;
}
</style>
