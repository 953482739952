import { i18n } from "@/main";

const getRegionList = () => [
  i18n.global.t("region.seoul"),
  i18n.global.t("region.busan"),
  i18n.global.t("region.incheon"),
  i18n.global.t("region.daegu"),
  i18n.global.t("region.daejeon"),
  i18n.global.t("region.gwangju"),
  i18n.global.t("region.ulsan"),
  i18n.global.t("region.sejong"),
  i18n.global.t("region.gyeongi"),
  i18n.global.t("region.chungbuk"),
  i18n.global.t("region.chungnam"),
  i18n.global.t("region.jeonam"),
  i18n.global.t("region.gyeongbuk"),
  i18n.global.t("region.gyeongnam"),
  i18n.global.t("region.gangwon"),
  i18n.global.t("region.jeonbuk"),
  i18n.global.t("region.jeju"),
];

export default getRegionList;
