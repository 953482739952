<template>
  <div id="color_picker_wrap">
    <label v-if="label" :style="labelStyle ? labelStyle : initialLabelStyle">{{
      label
    }}</label>
    <div class="color_picker_box">
      <input
        type="color"
        class="color_picker"
        v-model="hexColor"
        @input="$emit('colorChanged', $event.target.value)"
      />
      <p v-if="hexColor">{{ hexColor }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    labelStyle: {
      type: Object,
      required: false,
    },
    selectedHexColor: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      hexColor: this.selectedHexColor,
      initialLabelStyle: {
        fontSize: "1.2rem",
        color: "#f1f1f5",
      },
    };
  },
};
</script>
<style lang="scss">
#color_picker_wrap {
  @include flexbox(center, flex-start, row);
  gap: 10px;

  .color_picker_box {
    @include flexbox(center, flex-start, row);
    gap: 10px;
    .color_picker {
      cursor: pointer;
    }

    p {
      font-size: 1.2rem;
      color: $main_white;
    }
  }
}
</style>
