/**
 * 입력된 값의 첫 글자가 특수문자인지 확인한다.
 * @param {*} event
 * @returns {boolean} 첫 글자가 특수문자이면 true, 아니면 false
 */
export const isFirstLetterSpecialCharacter = (event) => {
  const targetValue = event.target.value;

  // 첫 글자가 특수문자인지 확인
  const firstChar = targetValue.charAt(0);
  const isSpecialChar = /[!@#$%^&*()_+={};':"\\|,.<>?~`:/\s-]/g.test(firstChar);

  return isSpecialChar;
};
