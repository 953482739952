<template>
  <div class="data_management_header">
    <div class="data_management_header_title">{{ $t("term.moduleId") }}</div>
    <div class="data_management_select" @click="clickDroneSelect">
      {{ selectedDrone.droneSerialNumber }}
    </div>
    <div
      class="data_management_select_unlink button"
      v-if="selectedDrone.droneSerialNumber"
      @click="checkDroneStatus(null)"
    ></div>
    <div
      v-if="droneSelectModal"
      class="data_management_select_option_wrap"
      :style="{ height: getHeightDrone }"
    >
      <div
        v-for="(drone, index) in droneList"
        :key="index"
        class="data_management_select_option"
        @click="checkDroneStatus(drone)"
      >
        {{ drone.droneSerialNumber }}
      </div>
    </div>
  </div>
  <div class="data_management_content_wrap">
    <div
      class="data_management_content"
      v-for="(data, index) in dataList"
      :key="index"
    >
      <div
        class="data_management_content_title"
        :class="{ on: data.facility.open }"
        @click="changeFacilityOpen(data)"
      >
        {{ data.facility.name }}
      </div>
      <div class="data_management_content_main" v-if="data.facility.open">
        <div
          class="data_management_content_map"
          v-for="(map, index) in data.mapList"
          :key="index"
        >
          <div class="data_management_content_map_title_wrap">
            <div
              class="data_management_content_map_title"
              :class="{ on: map.open }"
              @click="changeMapOpen(map)"
            >
              [{{ $t("term.map") }}] {{ map.name }}
            </div>
            <!-- <div class="data_management_content_map_title_checkbox" :class="{'on' : map.checked}"
                 @click="changeMapChecked(map)">
            </div> -->
          </div>
          <div class="data_management_content_map_main" v-if="map.open">
            <div
              class="data_management_content_image_wrap"
              v-if="map.existence"
            >
              <div class="data_management_content_image">
                [{{ $t("term.pointCloudMap") }}]
              </div>
              <div
                class="data_management_content_image_checkbox"
                :class="{ on: map.checked }"
                @click="changeMapChecked(map)"
              ></div>
              <div class="data_management_content_another_image_wrap">
                <div
                  class="data_management_content_image_spinner"
                  v-if="map.state == 'loading'"
                ></div>
                <div
                  class="data_management_content_image_complete"
                  v-else-if="map.state == 'complete'"
                ></div>
                <div
                  class="data_management_content_image_text"
                  v-if="map.state == 'loading'"
                >
                  {{ map.percentage }}
                </div>
              </div>
            </div>
            <div
              class="data_management_content_image_wrap"
              v-for="(image, index) in map.image_list"
              :key="index"
            >
              <div class="data_management_content_image">
                [{{ $t("term.album") }}] {{ image.name }}
              </div>
              <div
                class="data_management_content_image_checkbox"
                :class="{ on: image.checked }"
                @click="changeImageChecked(image)"
              ></div>
              <div class="data_management_content_another_image_wrap">
                <div
                  class="data_management_content_image_spinner"
                  v-if="image.state == 'loading'"
                ></div>
                <div
                  class="data_management_content_image_complete"
                  v-if="image.state == 'complete'"
                ></div>
                <div
                  class="data_management_content_image_text"
                  v-if="image.state == 'loading'"
                >
                  {{ image.percentage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="data_management_footer">
    <div class="data_management_footer_message">{{ message.content }}</div>
    <div class="data_management_footer_button_wrap">
      <div
        class="data_management_footer_button button"
        v-if="state == 'ready'"
        @click="openModal"
      >
        {{ $t("button.copy") }}
      </div>
      <div
        class="data_management_footer_button button"
        v-if="state == 'ready'"
        @click="clickDelete"
      >
        {{ $t("button.delete") }}
      </div>
    </div>
  </div>
  <div class="data_management_modal_wrap" v-if="modalState" @click="closeModal">
    <div class="data_management_modal" @click.stop>
      <div class="data_management_modal_title_wrap">
        <div class="data_management_modal_title">
          {{ $t("term.storageDeviceSetting") }}
        </div>
        <div class="data_management_modal_close" @click="closeModal"></div>
      </div>
      <div class="data_management_modal_content">
        <div class="data_management_modal_select" @click="clickUSBSelect">
          {{ selectedUSB.name }}
        </div>
        <div class="data_management_modal_button button" @click="checkUSB">
          {{ $t("button.confirm") }}
        </div>
        <div
          v-if="usbSelectModal"
          class="data_management_modal_select_option_wrap"
          :style="{ height: getHeightUSB }"
        >
          <div
            v-for="(usb, index) in usbList"
            :key="index"
            class="data_management_modal_select_option"
            @click="selectUsb(usb)"
          >
            {{ usb.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Drone from "@/model/Drone.js";
import DroneControl from "@/module/Drone/DroneControl.js";
import DroneMonitor from "@/module/Drone/DroneMonitor.js";
import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import DroneHardData from "@/model/DroneHardData.js";
import DroneHardDataMap from "@/model/DroneHardDataMap.js";
import DroneHardDataImage from "@/model/DroneHardDataImage.js";
import USB from "@/model/USB.js";
export default {
  computed: {
    ...mapState(["user", "api"]),
    getHeightDrone() {
      if (this.droneList) {
        return this.droneList.length * 25 + "px";
      } else {
        return "0px";
      }
    },
    getHeightUSB() {
      if (this.usbList) {
        return this.usbList.length * 25 + "px";
      } else {
        return "0px";
      }
    },
  },
  data() {
    return {
      droneList: [],
      droneSelectModal: false,
      selectedDrone: new Drone(),
      facilityList: [],
      dataList: [],
      state: "initial",
      message: {
        interval: null,
        content: null,
      },
      currentData: null,
      modalState: false,
      usbList: [],
      selectedUSB: new USB(),
      usbSelectModal: false,
    };
  },
  methods: {
    getFacilties() {
      this.api.getSites(this.user.id, this.getFaciltiesCallback);
    },
    getFaciltiesCallback(data) {
      let result = data.data.result;
      this.facilityList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let site = new Site(result[i]);
          this.facilityList.push(site);
        }
        this.api.getAllMaps(this.getAllMapsCallback, this.user.id);
      }
    },
    getAllMapsCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          if (result[i].url != "") {
            let map = new Map(result[i]);
            let facility = this.facilityList.find(
              (facility) => facility.id == map.facilityId
            );
            facility.mapList.push(map);
          }
        }
      }
    },
    getDrones() {
      this.api.getDrones(this.getDronesCallback, this.user);
    },
    getDronesCallback(data) {
      let result = data.data.result;
      this.droneList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneList.push(new Drone(result[i]));
      }
    },
    clickDroneSelect() {
      this.droneSelectModal = !this.droneSelectModal;
    },
    clickUSBSelect() {
      this.usbSelectModal = !this.usbSelectModal;
    },
    checkDroneStatus(drone) {
      if (drone) {
        this.api.getDrone(this.checkDroneStatusCallback, drone.id);
      } else {
        this.selectedDrone = new Drone();
        this.connectDrone();
      }
      this.droneSelectModal = false;
    },
    checkDroneStatusCallback(data) {
      let result = data.data.result;
      if (result.droneStatus == "disConnecting") {
        this.selectedDrone = new Drone(result);
      } else {
        this.$store.commit("openAlert", this.$t("droneAlert.alreadyInUseId"));
        this.selectedDrone = new Drone();
      }
      this.connectDrone();
    },
    connectDrone() {
      if (this.droneMonitor) {
        this.api.putDrone(this.droneMonitor.module.id, "disConnecting");
        this.droneMonitor.closeMqtt();
        this.droneMonitor = null;
        this.droneControl = null;
        this.state = "initial";
        this.message.content = null;
        this.currentData = null;
        this.dataList = [];
      }
      if (this.selectedDrone.droneSerialNumber) {
        this.droneMonitor = new DroneMonitor(this.selectedDrone);
        this.api.putDrone(this.droneMonitor.module.id, "connecting");
        this.droneMonitor.subDirectory(this.directoryCallback);
        this.droneMonitor.subErase(this.eraseCallback);
        this.droneMonitor.subCopy(this.copyCallback);
        this.droneMonitor.subUSB(this.usbCallback);
        this.droneMonitor.readMsg();

        this.droneControl = new DroneControl(
          this.droneMonitor.mqttClient,
          this.selectedDrone
        );
        this.state = "read";
        this.droneControl.dataManagementDirectory();
        this.message.content = this.$t("droneAlert.readingData");
        // let dotCount = 0;
        // this.message.interval = setInterval(()=>{
        //   dotCount = (dotCount%4) + 1;
        //   message.innerText = '데이터를 읽어오고 있습니다'+'.'.repeat(dotCount);
        // },500)
      }
    },
    selectUsb(usb) {
      if (usb) {
        this.selectedUSB = usb;
      } else {
        this.selectedUSB = new USB();
      }
      this.usbSelectModal = false;
    },
    directoryCallback(data) {
      this.state = "sync";
      this.dataList = [];
      this.message.content = this.$t("droneAlert.SynchronizingData");
      let info = [];
      for (let i = 0; i < data.info.length; i++) {
        let facility = this.facilityList.find(
          (facility) => facility.id == data.info[i].facility_id
        );
        if (facility) {
          let map = facility.mapList.find(
            (map) => map.id == data.info[i].map_id
          );
          if (map) {
            let finedData = this.dataList.find(
              (data) => data.facility?.id == facility.id
            );
            if (!finedData) {
              finedData = new DroneHardData(facility);
              this.dataList.push(finedData);
            }
            let newMap = new DroneHardDataMap(map, data.info[i].map_existence);
            finedData.mapList.push(newMap);
            if (data.info[i].image_list) {
              for (let j = 0; j < data.info[i].image_list.length; j++) {
                newMap.image_list.push(
                  new DroneHardDataImage(data.info[i].image_list[j])
                );
              }
            }
          } else {
            data.info[i].map_erase = data.info[i].map_existence;
            delete data.info[i].map_existence;
            data.info[i].type = "lack_of_map";
            info.push(data.info[i]);
          }
        } else {
          data.info[i].map_erase = data.info[i].map_existence;
          delete data.info[i].map_existence;
          data.info[i].type = "lack_of_facility";
          info.push(data.info[i]);
        }
      }
      if (info.length != 0) {
        this.state = "erage";
        this.message.content = this.$t("droneAlert.requestToDeleteData");
        this.droneControl.dataManagementErase(JSON.stringify(info));
      } else {
        this.state = "ready";
        this.message.content = null;
      }
    },
    eraseCallback(data) {
      if (data.state == "Complete") {
        this.message.content = this.$t("droneAlert.dataDeletionCompleted");
      }
      this.droneControl.dataManagementDirectory();
    },
    copyCallback(data) {
      if (data.state == "OnGoing") {
        this.message.content = this.$t("droneAlert.copyingDataInProgress");
        if (data.percent == 100) {
          this.currentData.percentage = "동기화";
        } else {
          this.currentData.percentage = `${parseInt(data.percent)}%`;
        }
      } else if (data.state == "Complete") {
        this.message.content = this.$t("droneAlert.copyingDataCompleted");
        this.currentData.checked = false;
        this.currentData.state = "complete";
        this.currentData.percentage = null;
        this.currentData = null;
        this.clickCopy();
      }
    },
    usbCallback(data) {
      this.usbList = [];
      if(data.info){
        for (let i = 0; i < data.info.length; i++) {
          this.usbList.push(new USB(data.info[i]));
        }
      }
    },
    checkUSB() {
      if (this.selectedUSB.name) {
        this.clickCopy();
        this.closeModal();
      } else {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.noStorageDeviceSelected")
        );
      }
    },
    changeFacilityOpen(data) {
      data.facility.open = !data.facility.open;
    },
    changeMapOpen(map) {
      map.open = !map.open;
    },
    changeMapChecked(map) {
      map.checked = !map.checked;
    },
    changeImageChecked(image) {
      image.checked = !image.checked;
    },
    clickCopy() {
      this.state = "copy";
      this.message.content = this.$t("droneAlert.startCopyingData");
      let total_data = {
        info: [],
        directory: this.selectedUSB.directory,
      };
      for (let i = 0; i < this.dataList.length; i++) {
        let data = {
          facility_id: null,
          facility_name: null,
          map_id: null,
          map_name: null,
          map_copy: false,
          image_list: [],
          type: "part",
        };
        for (let j = 0; j < this.dataList[i].mapList.length; j++) {
          if (this.dataList[i].mapList[j].checked) {
            data.facility_id = this.dataList[i].facility.id;
            data.facility_name = this.dataList[i].facility.name;
            data.map_id = this.dataList[i].mapList[j].id;
            data.map_name = this.dataList[i].mapList[j].name;
            data.map_copy = this.dataList[i].mapList[j].checked;
            data.image_list = null;
            total_data.info.push(data);
            this.droneControl.dataManagementCopy(JSON.stringify(total_data));
            this.dataList[i].mapList[j].state = "loading";
            this.currentData = this.dataList[i].mapList[j];
            return;
          } else {
            for (
              let k = 0;
              k < this.dataList[i].mapList[j].image_list.length;
              k++
            ) {
              if (this.dataList[i].mapList[j].image_list[k].checked) {
                data.facility_id = this.dataList[i].facility.id;
                data.facility_name = this.dataList[i].facility.name;
                data.map_id = this.dataList[i].mapList[j].id;
                data.map_name = this.dataList[i].mapList[j].name;
                data.map_copy = this.dataList[i].mapList[j].checked;
                data.image_list.push(
                  this.dataList[i].mapList[j].image_list[k].name
                );
                total_data.info.push(data);
                this.droneControl.dataManagementCopy(
                  JSON.stringify(total_data)
                );
                this.dataList[i].mapList[j].image_list[k].state = "loading";
                this.currentData = this.dataList[i].mapList[j].image_list[k];
                return;
              }
            }
          }
        }
      }
      this.message.content = this.$t("droneAlert.copyingDataCompleted");
      this.state = "ready";
    },
    clickDelete() {
      this.state = "erage";
      let info = [];
      for (let i = 0; i < this.dataList.length; i++) {
        let data = {
          facility_id: null,
          map_id: null,
          map_erase: false,
          image_list: [],
        };
        for (let j = 0; j < this.dataList[i].mapList.length; j++) {
          if (this.dataList[i].mapList[j].checked) {
            data.facility_id = this.dataList[i].facility.id;
            data.map_id = this.dataList[i].mapList[j].id;
            data.map_erase = this.dataList[i].mapList[j].checked;
          }
          for (
            let k = 0;
            k < this.dataList[i].mapList[j].image_list.length;
            k++
          ) {
            if (this.dataList[i].mapList[j].image_list[k].checked) {
              if (this.dataList[i].mapList[j].checked) {
                data.image_list.push(
                  this.dataList[i].mapList[j].image_list[k].name
                );
              } else {
                if (data.facility_id) {
                  data.image_list.push(
                    this.dataList[i].mapList[j].image_list[k].name
                  );
                } else {
                  data.facility_id = this.dataList[i].facility.id;
                  data.map_id = this.dataList[i].mapList[j].id;
                  data.map_erase = this.dataList[i].mapList[j].checked;
                  data.image_list.push(
                    this.dataList[i].mapList[j].image_list[k].name
                  );
                }
              }
            }
          }
        }
        if (data.facility_id) {
          if (data.image_list.length == 0) data.image_list = null;
          data.type = "part";
          info.push(data);
        }
      }
      this.droneControl.dataManagementErase(JSON.stringify(info));
    },
    closeModal() {
      this.modalState = false;
    },
    openModal() {
      this.droneControl.dataManagementUSB();
      this.selectedUSB = new USB();
      this.modalState = true;
    },
  },
  mounted() {
    this.getFacilties();
    this.getDrones();
  },
  beforeUnmount() {
    if (this.droneMonitor) {
      this.api.putDrone(this.droneMonitor.module.id, "disConnecting");
      this.droneMonitor.closeMqtt();
      this.droneMonitor = null;
    }
    if (this.droneControl) this.droneControl = null;
  },
};
</script>
<style lang="scss" scoped>
.data_management_header {
  position: relative;
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50px;
}
.data_management_header_title {
  @include flexbox(center, flex-start, row);
  width: 100px;
  height: 100%;
  font-size: 1.8rem;
  color: $main_white;
}
.data_management_select {
  @include flexbox(center, flex-start, row);
  padding-left: 10px;
  width: 80px;
  height: 25px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23, 0.6) url("@/../public/images/chevronDown.svg")
    no-repeat right 10px center;
  background-size: 12px 12px;
  cursor: pointer;
}
.data_management_select_unlink {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  background-image: url("@/../public/images/unlink.svg");
  background-size: 18px 18px;
}
.data_management_select_unlink:hover {
  background-image: url("@/../public/images/unlink_hover.svg");
  background-size: 18px 18px;
}
.data_management_select_option_wrap {
  position: absolute;
  @include flexbox(center, flex-start, column);
  left: 100px;
  top: 45px;
  width: 80px;
  height: 0px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23);
  z-index: 22;
  cursor: pointer;
}
.data_management_select_option {
  @include flexbox(center, flex-start);
  width: 100%;
  height: 25px;
  border-bottom: 1px solid rgba(127, 253, 239, 0.5);
}
.data_management_select_option:last-of-type {
  border: none;
}
.data_management_select_option:hover {
  color: rgba(1, 250, 254, 0.5);
}
.data_management_content_wrap {
  @include flexbox(center, flex-start, column);
  width: 100%;
  max-height: 600px;
  overflow: auto;
}
.data_management_content_wrap::-webkit-scrollbar {
  width: 4px;
}
/* 스크롤바 트랙 스타일 */
.data_management_content_wrap::-webkit-scrollbar-track {
  background: transparent;
}
/* 스크롤바 손잡이 스타일 */
.data_management_content_wrap::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.data_management_content_wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.data_management_content {
  @include flexbox(center, flex-start, column);
  width: 100%;
  font-size: 1.5rem;
}
.data_management_content_title {
  @include flexbox(center, flex-start);
  padding-left: 20px;
  background: url("@/../public/images/caret_right_white.svg") no-repeat left
    center;
  background-size: 15px 15px;
  width: 100%;
  height: 30px;
  color: $main_white;
  cursor: pointer;
}
.data_management_content_title.on {
  background: url("@/../public/images/caret_down_main.svg") no-repeat left
    center;
  background-size: 15px 15px;
  color: rgb(1, 250, 254);
}
.data_management_content_main {
  @include flexbox(flex-start, center, column);
  width: 100%;
  margin-bottom: 10px;
}
.data_management_content_map {
  @include flexbox(flex-start, center, column);
}
.data_management_content_map_title_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 30px;
}
.data_management_content_map_title {
  @include flexbox(center, flex-start, row);
  margin-left: 15px;
  padding-left: 20px;
  background: url("@/../public/images/caret_right_white.svg") no-repeat left
    center;
  background-size: 15px 15px;
  height: 100%;
  color: $main_white;
  cursor: pointer;
}
.data_management_content_map_title.on {
  background: url("@/../public/images/caret_down_main.svg") no-repeat left
    center;
  background-size: 15px 15px;
  color: rgb(1, 250, 254);
}
.data_management_content_map_title_checkbox {
  margin-left: 15px;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}
.data_management_content_map_title_checkbox.on {
  background-image: url("@/../public/images/check.svg");
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}
.data_management_content_map_main {
  @include flexbox(flex-start, center, column);
  width: 100%;
}
.data_management_content_image_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 30px;
}
.data_management_content_image {
  @include flexbox(center, flex-start, row);
  margin-left: 50px;
  height: 100%;
  color: $main_white;
}
.data_management_content_image_checkbox {
  width: 20px;
  height: 20px;
  margin: 0px 15px 0px 15px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}
.data_management_content_image_checkbox.on {
  background-image: url("@/../public/images/check.svg");
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}
.data_management_content_another_image_wrap {
  @include flexbox(center, center, row);
  position: relative;
  height: 30px;
  width: 30px;
}
.data_management_content_image_spinner {
  width: 25px;
  height: 25px;
  background-image: url("@/../public/images/spinner_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.data_management_content_image_complete {
  width: 20px;
  height: 20px;
  background-image: url("@/../public/images/check_green.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.data_management_content_image_text {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  @include flexbox(center, center, row);
  font-size: 0.8rem;
  color: $main_white;
}
.data_management_footer {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
}
.data_management_footer_message {
  @include flexbox(center, flex-start, row);
  width: calc(100% - 110px);
  height: 100%;
  color: red;
}
.data_management_footer_button_wrap {
  @include flexbox(center, space-between, row);
  width: 110px;
  height: 100%;
}
.data_management_footer_button {
  @include flexbox;
  width: 50px;
  height: 100%;
}
.data_management_modal_button {
  @include flexbox;
  width: 50px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
}
.data_management_modal_wrap {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.data_management_modal {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 150px);
  @include flexbox(center, flex-start, column);
  padding: 0px 20px 0px 20px;
  width: 300px;
  height: 100px;
  background: $main_black;
}
.data_management_modal_title_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $black_353542;
  color: $main_white;
  font-size: 2rem;
  font-weight: 700;
}
.data_management_modal_title {
  @include flexbox(center, flex-start, row);
  width: calc(100% - 30px);
  height: 100%;
}
.data_management_modal_close {
  width: 20px;
  height: 20px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: contain;
  cursor: pointer;
}
.data_management_modal_content {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 50px;
}
.data_management_modal_select {
  @include flexbox(center, flex-start, row);
  padding-left: 10px;
  width: 150px;
  height: 25px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23, 0.6) url("@/../public/images/chevronDown.svg")
    no-repeat right 10px center;
  background-size: 12px 12px;
  cursor: pointer;
}
.data_management_modal_select_option_wrap {
  position: absolute;
  @include flexbox(center, flex-start, column);
  left: 20px;
  top: 95px;
  width: 150px;
  height: 0px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23);
  z-index: 22;
  cursor: pointer;
}
.data_management_modal_select_option {
  @include flexbox(center, flex-start);
  width: 100%;
  height: 25px;
  border-bottom: 1px solid rgba(127, 253, 239, 0.5);
}
.data_management_modal_select_option:last-of-type {
  border: none;
}
.data_management_modal_select_option:hover {
  color: rgba(1, 250, 254, 0.5);
}
</style>
