<template>
  <div class="main korean">
    <MainHeaderVue :navigator="navigator" />
    <HomeMapControl :isRoadMap="isRoadMap" @changeMapType="setMapType" />
    <HomeLeftVue @createSite="createSite" @filterSite="filterSite">
      <SiteContainerVue
        v-for="(site, siteIndex) in filteredSiteList"
        :key="siteIndex"
        :site="site"
        @clickSite="selectSite"
        @deleteSite="deleteSite"
      />
    </HomeLeftVue>

    <HomeModalVue
      v-if="modalState === true && selectedFacility"
      @changeModalState="handleModalState"
      @deleteMap="deleteMap"
      @mappingRouter="mappingRouter"
      @changeMap="selectMap"
    />
    <HomeModalEditVue
      v-else-if="modalState === 'edit' && selectedFacility"
      :markerPosition="markerPosition"
      @updateSite="updateSite"
      @changeModalState="handleModalState"
      @updateSitePosition="handleSitePosition"
      @uploadStart="uploadStart"
    />

    <div id="map"></div>
  </div>
</template>

<script>
import { formatISO } from "date-fns";

import GoogleAPI from "@/module/Google/GoogleAPI.js";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";

import { mapState } from "vuex";

import HomeModalVue from "./HomeModal.vue";
import MainHeaderVue from "../Common/MainHeader.vue";
import HomeMapControl from "./HomeMapControl.vue";
import HomeLeftVue from "./HomeLeft.vue";
import SiteContainerVue from "./SiteContainer.vue";
import HomeModalEditVue from "./HomeModalEdit.vue";

export default {
  components: {
    HomeModalVue,
    MainHeaderVue,
    HomeMapControl,
    HomeLeftVue,
    SiteContainerVue,
    HomeModalEditVue,
  },
  data() {
    return {
      navigator: "Home",
      isRoadMap: false,
      modalState: false,
      filteredSiteList: [],
      markerPosition: null,
    };
  },
  computed: {
    ...mapState(["user", "api", "siteList", "selectedFacility"]),
  },
  watch: {
    modalState: {
      handler(modalState) {
        if (modalState == "edit") {
          this.googleMap.setClickEvent();
        } else {
          this.googleMap.deleteClickEvent();
        }
      },
    },
  },
  methods: {
    setMapType(type) {
      if (type == "roadmap") {
        this.isRoadMap = true;
      } else if (type == "hybrid") {
        this.isRoadMap = false;
      }
      this.googleMap.setMapType(type);
    },
    filterSite(event) {
      this.searchQuery = event.target.value;
      if (this.searchQuery.trim() === "") {
        this.filteredSiteList = [...this.siteList];
      } else {
        this.filteredSiteList = [
          ...this.siteList.filter(
            (site) =>
              site.name &&
              site.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          ),
        ];
      }
    },
    selectSite(site) {
      this.modalState = true;
      this.$store.dispatch("setSelectedFacility", site);
      if (this.selectedFacility.id) {
        if (!this.selectedFacility.mapList) {
          this.api.getMaps(this.getMapsCallback, this.selectedFacility.id);
        }
        this.googleMap.changeMapCenter({
          lat: parseFloat(this.selectedFacility.lat),
          lng: parseFloat(this.selectedFacility.lon),
        });
      }
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.$store.dispatch("setMapList", mapList);
      }
    },
    clickMarkerRouter(facilityId) {
      return () => {
        let findedSite = this.siteList.find((site) => site.id == facilityId);
        this.selectSite(findedSite);
      };
    },
    handleModalState(modalState) {
      this.modalState = modalState;
      if (!modalState) {
        this.$store.dispatch("setSelectedFacility", null);
      }
    },
    mappingRouter() {
      if (this.selectedFacility.mapList) {
        let emptyMap = this.selectedFacility.mapList.find(
          (map) => map.url == ""
        );
        if (emptyMap) {
          this.$router.push({
            name: "mapping",
            params: { facilityId: this.selectedFacility.id, mapId: emptyMap.id },
          });
        } else {
          this.api.postMap(
            this.mappingRouterCallback,
            this.selectedFacility.id,
            formatISO(new Date())
          );
        }
      } else {
        this.api.postMap(
          this.mappingRouterCallback,
          this.selectedFacility.id,
          formatISO(new Date())
        );
      }
    },
    mappingRouterCallback(data) {
      let result = data.data.result;
      this.$router.push({
        name: "mapping",
        params: { facilityId: this.selectedFacility.id, mapId: result.id },
      });
    },
    getSitesCallback(data) {
      let result = data.data.result;
      let siteList = [];
      this.googleMap.clearMarkers();
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let site = new Site(result[i]);
          siteList.push(site);
          if (site.lat && site.lon) {
            this.googleMap.addMarker(
              { lat: parseFloat(site.lat), lng: parseFloat(site.lon) },
              this.clickMarkerRouter(site.id),
              false
            );
          }
        }
      }
      this.$store.dispatch("setSiteList", siteList);
      this.filteredSiteList = [...this.siteList];
    },
    createSite() {
      if (confirm("새로운 시설물을 만드시겠습니까?")) {
        if (this.newSite) {
          this.$store.commit(
            "openAlert",
            "이미 등록 중인 시설물이 존재합니다."
          );
        } else {
          this.newSite = new Site({ name: "새로운 시설물" });
          this.$store.dispatch("addSite", this.newSite);
          this.filteredSiteList = [...this.siteList];
        }
      } else {
        return false;
      }
    },
    updateSite(body, file) {
      if (this.selectedFacility.id) {
        this.api.putSite(
          this.putSiteCallback,
          this.selectedFacility,
          body,
          file
        );
      } else {
        this.api.postSite(this.postSiteCallback, body, file);
      }
    },
    putSiteCallback(data, file) {
      let result = data.data.result;
      let resultSite = new Site(result);
      this.$store.dispatch("updateSite", resultSite);
      this.filteredSiteList = [...this.siteList];
      this.handleModalState(false);
      if (file) {
        this.uploadStart(resultSite.id, file);
      }
    },
    postSiteCallback(data, file) {
      let result = data.data.result;
      this.newSite = null;
      let deleteSite = this.siteList.find((site) => site.id == null);
      this.$store.dispatch("deleteSite", deleteSite);
      this.$store.dispatch("addSite", new Site(result));
      this.filteredSiteList = [...this.siteList];
      this.handleModalState(false);
      if (file) {
        this.uploadStart(result.id, file);
      }
    },
    deleteSite(seletedsite) {
      if (seletedsite.id) {
        this.api.deleteSite(this.deleteSiteCallback, seletedsite);
      } else {
        let deleteSite = this.siteList.find((site) => site.id == null);
        this.$store.dispatch("deleteSite", deleteSite);
        this.filteredSiteList = [...this.siteList];
        this.newSite = null;
      }
    },
    deleteSiteCallback(deletedsite) {
      this.$store.dispatch("deleteSite", deletedsite);
      this.filteredSiteList = [...this.siteList];
    },
    selectMap(map) {
      this.$store.dispatch("updateMap", map);
    },
    deleteMap(map) {
      if (confirm("해당 맵을 삭제하시겠습니까?")) {
        this.api.deleteMap(this.deleteMapCallback, map);
      } else {
        return false;
      }
    },
    deleteMapCallback(deletedMap) {
      this.$store.dispatch("deleteMap", deletedMap);
      this.filteredSiteList = [...this.siteList];
    },
    newMarkerCallback() {
      if (this.googleMap.marker) {
        this.markerPosition = {
          lat: this.googleMap.marker.getPosition().lat(),
          lng: this.googleMap.marker.getPosition().lng(),
        };
      } else {
        this.markerPosition = null;
      }
    },
    handleSitePosition(position) {
      this.googleMap.addNewMarker(position);
    },
    uploadStart(facilityId, file) {
      let formData = new FormData();
      formData.append("file", file);
      this.api.postSiteThumbnail(
        this.postSiteThumbnailCallback,
        facilityId,
        formData
      );
    },
    postSiteThumbnailCallback() {
      this.api.getSites(this.user.id, this.getSitesCallback);
    },
  },
  mounted() {
    this.googleMap = new GoogleAPI(
      document.querySelector("#map"),
      () => {
        this.api.getSites(this.user.id, this.getSitesCallback);
      },
      this.newMarkerCallback
    );
  },
};
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100%;
}
</style>
