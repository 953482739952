import koMessages from "@/locales/ko/messages.json";
import koRegions from "@/locales/ko/regions.json";
import koTerms from "@/locales/ko/terms.json";
import koStates from "@/locales/ko/states.json";
import koFacilityType from "@/locales/ko/facilityType.json";
import koButtons from "@/locales/ko/buttons.json";
import koAlbumAlert from "@/locales/ko/albumAlert.json";
import koOtherAlert from "@/locales/ko/otherAlert.json";
import koPlaceholder from "@/locales/ko/placeholder.json";
import koDroneAlert from "@/locales/ko/droneAlert.json";

import enMessages from "@/locales/en/messages.json";
import enRegions from "@/locales/en/regions.json";
import enTerms from "@/locales/en/terms.json";
import enStates from "@/locales/en/states.json";
import enFacilityType from "@/locales/en/facilityType.json";
import enButtons from "@/locales/en/buttons.json";
import enAlbumAlert from "@/locales/en/albumAlert.json";
import enOtherAlert from "@/locales/en/otherAlert.json";
import enPlaceholder from "@/locales/en/placeholder.json";
import enDroneAlert from "@/locales/en/droneAlert.json";

import jpMessages from "@/locales/jp/messages.json";
import jpTerms from "@/locales/jp/terms.json";
import jpButtons from "@/locales/jp/buttons.json";
import jpFacilityType from "@/locales/jp/facilityType.json";
import jpStates from "@/locales/jp/states.json";
import jpPlaceholder from "@/locales/jp/placeholder.json";
import jpOtherAlert from "@/locales/jp/otherAlert.json";
import jpDroneAlert from "@/locales/jp/droneAlert.json";

import cnMessages from "@/locales/cn/messages.json";
import cnTerms from "@/locales/cn/terms.json";
import cnStates from "@/locales/cn/states.json";
import cnFacilityType from "@/locales/cn/facilityType.json";
import cnButtons from "@/locales/cn/buttons.json";
import cnAlbumAlert from "@/locales/cn/albumAlert.json";
import cnOtherAlert from "@/locales/cn/otherAlert.json";
import cnPlaceholder from "@/locales/cn/placeholder.json";
import cnDroneAlert from "@/locales/cn/droneAlert.json";

export default {
  KO: {
    ...koMessages,
    ...koRegions,
    ...koTerms,
    ...koStates,
    ...koFacilityType,
    ...koButtons,
    ...koAlbumAlert,
    ...koOtherAlert,
    ...koPlaceholder,
    ...koDroneAlert,
  },
  EN: {
    ...enMessages,
    ...enRegions,
    ...enTerms,
    ...enStates,
    ...enFacilityType,
    ...enButtons,
    ...enAlbumAlert,
    ...enOtherAlert,
    ...enPlaceholder,
    ...enDroneAlert,
  },
  JP: {
    ...jpMessages,
    ...jpTerms,
    ...jpButtons,
    ...jpFacilityType,
    ...jpStates,
    ...jpPlaceholder,
    ...jpOtherAlert,
    ...jpDroneAlert,
  },
  CN: {
    ...cnMessages,
    ...cnButtons,
    ...cnTerms,
    ...cnFacilityType,
    ...cnStates,
    ...cnPlaceholder,
    ...cnOtherAlert,
    ...cnDroneAlert,
    ...cnAlbumAlert,
  },
};
