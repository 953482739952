/**
 * 앨범 상태를 확인하여 적절한 상태값을 리턴
 * - 1. 에러 상태가 하나라도 있을 때.
 * - 1-1. "Failed" 상태가 하나라도 있을 경우 "분석 오류"(Failed)
 * - 2. 에러 상태가 하나도 없을 때.
 * - 2-1. 미분석 상태가 1개라도 존재한다면 "분석 필요"(Waiting)
 * - 2-2. 2-1이 없고, "Running" 상태가 하나라도 있을 경우 "분석중"(Running)
 * - 2-3. 모든 상태가 "Completed"라면, "분석 완료"(Completed)
 * @param {Array} albums
 * @returns string
 */
export const getAlbumState = (albums) => {
  // 상태값을 저장할 변수
  let hasRunning = false;
  let hasError = false;
  let hasWaiting = false;
  let allCompleted = true;

  // 모든 data 아이템이 albumsInfo에 존재하는지 확인
  for (let album of albums) {
    if (album.recognitionStatus === null) {
      hasWaiting = true;
    } else {
      // albumsInfo 리스트 상태 체크
      if (album.recognitionStatus === "Failed") {
        hasError = true;
        allCompleted = false;
      } else if (album.recognitionStatus === "Running") {
        hasRunning = true;
        allCompleted = false;
      } else if (album.recognitionStatus !== "Completed") {
        allCompleted = false;
      }
    }
  }

  // 우선순위에 따라 상태 리턴
  if (hasError) {
    return "Failed"; // 1-1. "분석 오류" 상태
  }
  if (hasRunning) {
    return "Running"; // 2-2. "분석중" 상태
  }
  if (hasWaiting) {
    return "Waiting"; // 2-1. "분석 필요" 상태
  }
  if (allCompleted) {
    return "Completed"; // 2-3. "분석 완료" 상태
  }

  // 디폴트 상태는 "분석 필요"(Waiting)
  return "Waiting";
};
