<template>
  <div class="home_site_modal">
    <div class="home_modal_x_button_wrap">
      <div class="home_modal_x_button" @click="changeModalState(false)"></div>
    </div>
    <div class="home_modal_thumb_wrap">
      <div class="home_modal_thumb_header">
        <div class="home_modal_thumb_header_title" :style="{ width: getWidth }">
          {{ selectedFacility.name }}
        </div>
        <div
          class="home_modal_thumb_header_edit button"
          @click="changeModalState('edit')"
        ></div>
      </div>
      <div
        class="home_modal_thumb"
        :style="getBackground(selectedFacility.thumbnailUrl)"
      ></div>
      <div class="home_modal_position">
        <p class="home_modal_position_content">
          위도 : {{ selectedFacility.lat }}
        </p>
        <p class="home_modal_position_content">
          경도 : {{ selectedFacility.lon }}
        </p>
      </div>
      <div class="home_modal_detail_info_title">정보</div>
      <div class="home_modal_detail_info">
        <p class="home_modal_detail_info_content">
          {{ selectedFacility.description }}
        </p>
      </div>
    </div>
    <div class="home_modal_button_wrap">
      <div
        class="home_modal_button button"
        id="mapping_button"
        v-if="selectedFacility.id"
        @click="mappingRouter"
      >
        새로운 맵 만들기
      </div>
    </div>
    <div class="home_modal_map_wrap">
      <div class="home_modal_map_title">선택된 맵의 생성날짜</div>
      <div class="home_modal_map" v-if="selectedFacility.map">
        {{ selectedFacility.map.createdDatetime }}
        <div class="home_modal_map_sub_wrap">
          <div class="home_modal_map_info" v-if="getVisibleMapInfo">최신</div>
          <div class="home_modal_map_button button" @click="handleSeletor">
            맵 변경
          </div>
        </div>
      </div>
    </div>
    <div
      class="home_modal_map_selector_wrap"
      v-if="selectedFacility.mapList && mapSelectorState"
      :style="{ height: getHeight }"
    >
      <div
        class="home_modal_map_selector button"
        v-for="(map, index) in selectedFacility.mapList"
        :key="index"
        v-show="map.url != ''"
        @click="clickMap(map)"
      >
        {{ map.createdDatetime }}
      </div>
    </div>
    <div class="home_modal_button_wrap">
      <div
        id="report_button"
        class="home_modal_button button"
        v-if="getVisibleRouter"
        @click="reportRouter"
      >
        레포트
      </div>
      <div id="report_button" class="home_modal_button_disable" v-else>
        레포트
      </div>
      <div
        class="home_modal_button button"
        v-if="getVisibleRouter"
        @click="inspectionRouter"
      >
        인스펙션
      </div>
      <div class="home_modal_button_disable" v-else>인스펙션</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  emits: ["changeModalState", "deleteMap", "mappingRouter", "changeMap"],
  computed: {
    ...mapState(["selectedFacility"]),
    getVisibleRouter() {
      if (this.selectedFacility.mapList) {
        let check = this.selectedFacility.mapList.filter(
          (map) => map.url != ""
        );
        if (check.length != 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getVisibleMapInfo() {
      if (this.selectedFacility.mapList && this.selectedFacility.map) {
        let filteredMapList = this.selectedFacility.mapList.filter(
          (map) => map.url != ""
        );
        if (
          filteredMapList[filteredMapList.length - 1].id ==
          this.selectedFacility.map.id
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getWidth() {
      if (this.selectedFacility.name) {
        return this.selectedFacility.name.length * 14 + 10 + "px";
      } else {
        return "70px";
      }
    },
    getHeight() {
      if (this.selectedFacility.mapList) {
        let filteredMapList = this.selectedFacility.mapList.filter(
          (map) => map.url != ""
        );
        return filteredMapList.length * 40 + 10 + "px";
      } else {
        return "0px";
      }
    },
    getBackground() {
      return (thumbnailUrl) => {
        if (thumbnailUrl) {
          return { backgroundImage: `url(${thumbnailUrl})` };
        } else {
          return null;
        }
      };
    },
  },
  watch: {
    selectedFacility() {
      this.mapSelectorState = false;
    },
  },
  data() {
    return {
      mapSelectorState: false,
    };
  },
  methods: {
    reportRouter() {
      this.$router.push({
        name: "report",
        params: { facilityId: this.selectedFacility.id },
      });
    },
    mappingRouter() {
      if (this.selectedFacility.mapList) {
        if (this.selectedFacility.mapList.length < 30) {
          this.$emit("mappingRouter");
        } else {
          this.$store.commit(
            "openAlert",
            `시설물당 맵은 최대 30개까지 등록이 가능합니다. 맵을 추가 등록하시려면 레포트에서 기존 맵을 삭제해 주세요.`
          );
        }
      } else {
        this.$emit("mappingRouter");
      }
    },
    patternRouter() {
      this.$router.push({
        name: "pattern",
        params: {
          facilityId: this.selectedFacility.id,
          mapId: this.selectedFacility.map.id,
        },
      });
    },
    inspectionRouter() {
      this.$router.push({
        name: "inspection",
        params: {
          facilityId: this.selectedFacility.id,
          mapId: this.selectedFacility.map.id,
        },
      });
    },
    changeModalState(modalState) {
      this.$emit("changeModalState", modalState);
    },
    deleteMap(map) {
      if (confirm("해당 맵을 삭제하시겠습니까?")) {
        this.$emit("deleteMap", map);
      } else {
        return false;
      }
    },
    clickMap(map) {
      if (map.id != this.selectedFacility.map.id) {
        this.$emit("changeMap", map);
      }
      this.mapSelectorState = false;
    },
    handleSeletor() {
      this.mapSelectorState = !this.mapSelectorState;
    },
  },
};
</script>

<style lang="scss">
.home_site_modal {
  position: fixed;
  top: 100px;
  left: 380px;
  display: flex;
  flex-direction: column;
  width: calc(100% / 12 * 3 - 60px);
  height: calc(100% / 12 * 9);
  background: rgba(23, 23, 23, 0.5);
  border-radius: 10px;
  z-index: 10;
  max-width: 260px;
  padding: 10px;
}
@media (max-width: 1460px) {
  .home_site_modal {
    position: fixed;
    top: 100px;
    left: calc(100% / 12 * 3 + 15px);
    display: flex;
    flex-direction: column;
    width: calc(100% / 12 * 3 - 60px);
    height: calc(100% / 12 * 9);
    background: rgba(23, 23, 23, 0.5);
    border-radius: 10px;
    z-index: 10;
    max-width: 260px;
    padding: 10px;
  }
}
.home_modal_x_button_wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 25px;
}
.home_modal_x_button {
  width: 25px;
  height: 100%;
  border-radius: 5px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 15px 15px;
  cursor: pointer;
}
.home_modal_thumb_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc((100% - 175px));
  background: rgba(23, 23, 23, 0.85);
  border-radius: 10px;
  padding: 10px;
}
.home_modal_thumb_header {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
}
.home_modal_thumb_header_title {
  @include flexbox;
  width: 20px;
  height: 25px;
  font-size: 1.4rem;
  font-weight: bold;
  color: $main_white;
  border-radius: 5px;
}
.home_modal_thumb_header_title::placeholder {
  padding-left: 5px;
  color: gray;
}
.home_modal_thumb_header_edit {
  width: 25px;
  height: 25px;
  background-image: url("@/../public/images/edit.svg");
  background-size: 15px 15px;
}
.home_modal_thumb_header_edit:hover {
  background-image: url("@/../public/images/edit_hover.svg");
  background-size: 15px 15px;
}
.home_modal_thumb {
  width: 100%;
  height: 165px;
  background: no-repeat center;
  border-radius: 10px;
  background-size: cover;
  margin-bottom: 5px;
}

.home_modal_position {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 50px;
  padding-left: 10px;
}
.home_modal_position_content {
  width: 100%;
  height: 25px;
  padding-top: 5px;
  font-size: 1.3rem;
}
.home_modal_detail_info_title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 15px;
  color: $main_white;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 10px 0px 10px 10px;
}
.home_modal_detail_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 290px);
  padding: 0px 10px 0px 10px;
  overflow: auto;
}
.home_modal_detail_info::-webkit-scrollbar {
  width: 4px;
}
.home_modal_detail_info::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
.home_modal_detail_info::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.home_modal_detail_info_content {
  color: $main_white;
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.home_modal_button_wrap {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 10px 0px 0px 0px;
  flex-wrap: wrap;
}
.home_modal_button {
  display: flex;
  align-items: center;
  background: #171717;
  justify-content: center;
  width: calc((100% - 10px) / 2);
  height: 30px;
  padding: 0px 10px 0px 10px;
}
#mapping_button {
  width: 100%;
}
.home_modal_map_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  color: $main_white;
  margin-top: 10px;
  background-color: #171717;
  font-size: 1.3rem;
  padding: 0px 5px 0px 5px;
}
.home_modal_map_title {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50%;
  padding-left: 10px;
  color: rgba(172, 165, 165, 0.938);
}
.home_modal_map {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  width: 100%;
  height: 50%;
}
.home_modal_map_sub_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 75px;
  height: 100%;
  padding: 5px 0px 5px 0px;
}
.home_modal_map_info {
  @include flexbox;
  width: 30px;
  height: 100%;
  color: rgba(172, 165, 165, 0.938);
  font-size: 1.2rem;
  margin-right: 5px;
}
.home_modal_map_button {
  @include flexbox;
  width: 45px;
  height: 100%;
  font-size: 1.2rem;
}
.home_modal_map_selector_wrap {
  position: absolute;
  left: calc(100% + 10px);
  top: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  max-height: 130px;
  background: rgba(23, 23, 23, 0.5);
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  flex-wrap: wrap;
  overflow: auto;
}
.home_modal_map_selector_wrap::-webkit-scrollbar {
  width: 4px;
}
.home_modal_map_selector_wrap::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
.home_modal_map_selector_wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.home_modal_map_selector {
  @include flexbox;
  width: 100%;
  height: 30px;
}
#report_button {
  margin-right: 10px;
}
.home_modal_button_disable {
  display: flex;
  align-items: center;
  background: #171717;
  justify-content: center;
  width: calc((100% - 10px) / 2);
  height: 30px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  color: gray;
}
.home_modal_button_disable:nth-child(2n) {
  margin-right: 0px;
}
</style>
