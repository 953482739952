<template>
  <div
    class="report_bottom_layout"
    :class="{ detail: reportDetails }"
    ref="reportBottomLayout"
  >
    <div class="report_bottom_title_wrap">
      <div class="report_bottom_title">{{ $t("term.photoList") }}</div>
      <div
        v-if="!uploadState"
        class="report_bottom_details_button button"
        @click="$emit('changeReportVue')"
      ></div>
    </div>

    <div
      class="report_bottom_contents"
      ref="reportBottomContents"
      @scroll="handleScroll"
    >
      <div
        class="report_bottom_content"
        ref="reportBottomContent"
        v-for="(photo, photoIndex) in photoList"
        :key="photoIndex"
        :class="getColor(photoIndex)"
        @click="$emit('clickPhoto', photo)"
        :data-id="photo.id"
      >
        <img
          v-if="visiblePhotoList.includes(photoIndex)"
          class="report_bottom_content_img"
          :src="getSrc(photo)"
          alt=""
        />
        <div class="analysis_state">{{ getText(photoIndex) }}</div>
        <div
          class="report_bottom_delete button"
          @click.stop="deletePhoto(photo)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  emits: [
    "changeReportVue",
    "changeVisiblePhotoList",
    "clickPhoto",
    "deletePhoto",
  ],
  computed: {
    ...mapState(["user"]),
    getSrc() {
      return (photo) => {
        if (photo.photo.albumPhotoThumbnailsPath) {
          return photo.photo.albumPhotoThumbnailsPath;
        } else {
          return photo.photo.albumPhotoPath;
        }
      };
    },
  },
  props: {
    uploadState : Boolean,
    reportDetails: Boolean,
    selectedAlbumList: Array,
    photoList: Array,
    selectedPhoto: Object,
    visiblePhotoList: Array,
    segmentationList: Array,
    detectionList : Array,
  },
  watch: {
    selectedPhoto(photo) {
      if (photo) {
        this.scrollToSelectedPhoto(photo);
      }
    },
  },
  methods: {
    getColor(photoIndex) {
      let findedSegmentation = this.segmentationList.find(
        (segmentation) =>
          segmentation.photoId == this.photoList[photoIndex].photo.id
      );
      let findedDetection = this.detectionList.find(
        (detection) =>
          detection.photoId == this.photoList[photoIndex].photo.id
      );
      if (this.selectedPhoto) {
        if (this.photoList[photoIndex].id == this.selectedPhoto.id) {
          return "green";
        } else {
          if (findedSegmentation || findedDetection) {
            if (findedSegmentation.crackCount != 0 || findedDetection.otherCount != 0) {
              return "pink";
            } else {
              return "blue";
            }
          } else {
            return "white";
          }
        }
      } else {
        if (findedSegmentation || findedDetection) {
          if (findedSegmentation.crackCount != 0 || findedDetection.otherCount != 0) {
            return "pink";
          } else {
            return "blue";
          }
        } else {
          return "white";
        }
      }
    },
    getText(photoIndex) {
      let findedSegmentation = this.segmentationList.find(
        (segmentation) =>
          segmentation.photoId == this.photoList[photoIndex].photo.id
      );
      let findedDetection = this.detectionList.find(
        (detection) =>
          detection.photoId == this.photoList[photoIndex].photo.id
      );
      if (findedSegmentation || findedDetection) {
        if (findedSegmentation.crackCount != 0 || findedDetection.otherCount != 0) {
          return this.$t("term.faultDetected");
        } else {
          return this.$t("term.noFaultDetected");
        }
      } else {
        return this.$t("term.unanalyzed");
      }
    },
    handleScroll() {
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        this.photoUpdate();
      }, 200);
    },
    photoUpdate() {
      this.$refs.reportBottomContent.forEach((element, index) => {
        if (
          0 < element.getBoundingClientRect().left &&
          element.getBoundingClientRect().left < window.innerWidth
        ) {
          this.$emit("changeVisiblePhotoList", index);
        }
      });
    },
    scrollToSelectedPhoto(photo) {
      this.$nextTick(() => {
        let selectedElement = this.$refs.reportBottomContent.find(
          (el) => el.dataset.id == photo.id
        );
        if (selectedElement) {
          let parent = this.$refs.reportBottomContents;

          let elementRect = selectedElement.getBoundingClientRect();
          let parentRect = parent.getBoundingClientRect();

          let offsetLeft =
            elementRect.left - parentRect.left + parent.scrollLeft - 10;

          parent.scrollTo({
            left: offsetLeft,
            behavior: "smooth",
          });
        }
      });
    },
    deletePhoto(photo) {
      let findedAlbum = this.selectedAlbumList.find(album => album.id == photo.photo.albumId);
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      }
      else if(findedAlbum?.crackRecognition?.status == 'Running'){
        this.$store.commit(
          "openAlert",
          this.$t("albumAlert.analyzingPhotoDelete")
        );
      }
      else {
        this.$emit("deletePhoto", photo);
      }
    },
  },
  created() {
    this.scrollTimer;
  },
};
</script>

<style lang="scss">
.report_bottom_layout {
  position: fixed;
  bottom: 15px;
  right: 10px;
  width: calc(100% - 345px);
  height: 25%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  overflow: hidden;
  z-index: 3;
}
@media (max-width: 1230px) {
  .report_bottom_layout {
    position: fixed;
    bottom: 15px;
    right: 10px;
    width: calc(75% - 35px);
    height: 25%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    overflow: hidden;
    z-index: 3;
  }
}
.report_bottom_layout.detail {
  left: 10px;
  bottom: 15px;
  width: calc(100% - 540px);
}
@media (max-width: 1300px) {
  .report_bottom_layout.detail {
    left: 10px;
    bottom: 15px;
    width: calc(60% - 20px);
  }
}

.report_bottom_title_wrap {
  @include flexbox(center, space-between, row);
  width: calc(100% - 10px);
  height: 20%;
  margin-left: 10px;
}
.report_bottom_title {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100%;
  color: $main_white;
  font-size: 2rem;
}
.report_bottom_details_button {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/slide.svg");
  background-size: 15px 15px;
}
.report_bottom_details_button:hover {
  background-image: url("@/../public/images/slide_hover.svg");
  background-size: 15px 15px;
}

.report_bottom_contents {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  overflow: auto;
}
.report_bottom_contents::-webkit-scrollbar {
  height: 7px;
}

/* 스크롤바 트랙 스타일 */
.report_bottom_contents::-webkit-scrollbar-track {
  border: 1px solid rgba(1, 250, 254, 0.5);
  border-radius: 5px;
}

/* 스크롤바 손잡이 스타일 */
.report_bottom_contents::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}

/* 스크롤바 손잡이:hover 스타일 */
.report_bottom_contents::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}

.report_bottom_content {
  position: relative;
  flex: 0 0 auto;
  background: no-repeat center;
  background-size: cover;
  width: calc(100% / 7 * 2 - 10px);
  max-width: 300px;
  height: calc(100% - 10px);
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.report_bottom_content.white {
  border: 1px solid rgb(255, 255, 255);
}
.report_bottom_content.blue {
  border: 1px solid rgb(0, 139, 225);
}
.report_bottom_content.pink {
  border: 1px solid rgb(255, 105, 180);
}
.report_bottom_content.green {
  border: 1px solid rgb(0, 253, 16);
}
.report_bottom_lazy_image {
  opacity: 0;
  transition: opacity 0.5s;
}
.report_bottom_lazy_image.loaded {
  opacity: 1;
}
.report_bottom_delete {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/trash.svg");
  background-size: 15px 15px;
}
.report_bottom_delete:hover {
  background-image: url("@/../public/images/trash_hover.svg");
  background-size: 15px 15px;
}
.analysis_state {
  position: absolute;
  @include flexbox;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 30px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(1, 250, 254, 0.6);
  box-shadow: $main_box_shadow;
  color: $main_white;
  border-radius: 5px;
  font-size: 1.2rem;
}
</style>
