<template>
  <router-view></router-view>
  <MainAlert
    v-if="$store.state.alertState.isAlertOpened"
    :alertMessage="$store.state.alertState.alertMessage"
  />
  <LoadingSpinner v-if="isLoading" />
</template>

<script>
import { mapState } from "vuex";
import MainAlert from "./component/modal/alert/MainAlert.vue";
import LoadingSpinner from "./component/loading/LoadingSpinner.vue";

export default {
  name: "App",
  components: { MainAlert, LoadingSpinner },
  computed: {
    ...mapState(["isLoading"]),
  },
  mounted() {
    document.title = "SIRIUS v1.0.0";
    this.updateTouchDeviceStatus();
    window.addEventListener("resize", this.updateTouchDeviceStatus);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateTouchDeviceStatus);
  },
  methods: {
    updateTouchDeviceStatus() {
      this.$store.dispatch("detectTouchDevice");
    },
  },
};
</script>

<style lang="scss">
@import "@/style/component/badge";
@import "@/style/component/formContent";
@import "@/style/component/inputs";
@import "@/style/component/selectBox";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"] {
  padding-right: 0;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: $main_white;
  background-color: black;
  z-index: 20;
}
#progress_bar {
  width: 30%;
  height: 2%;
  margin-top: 4px;
}
.english {
  font-family: "Poppins", sans-serif;
}
.korean {
  font-family: "Pretendard Medium", sans-serif;
}
.main {
  width: 100vw;
  height: 100vh;
}
.button {
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  color: rgba(1, 250, 254, 1);
  cursor: pointer;
  background-color: #171717;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  &.disabled {
    box-shadow: none;
    border: 1px solid $gray_6e6e82;
    color: $gray_6e6e82;
    cursor: not-allowed;

    &:hover {
      background-color: #171717;
      color: $gray_6e6e82;
    }
  }
}
.button:hover {
  background-color: rgba(1, 250, 254, 1);
  background-position: center;
  background-repeat: no-repeat;
  color: #171717;
}
.button.on {
  background-color: $main_color;
  color: black;
  cursor: default;
}
.input {
  color: $main_white;
  background: #171717;
  border: 1px solid rgba(1, 250, 254, 0.6);
  box-shadow: $main_box_shadow;
  border-radius: 5px;
  padding-left: 10px;
}
.radio {
  background-image: url("@/../public/images/circleButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  cursor: pointer;
}
.radio.on {
  background-image: url("@/../public/images/radio_button_selected.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
}
</style>
