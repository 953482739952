<template>
  <section
    class="no_facility"
    :style="{ paddingTop: sectionPadding, paddingBottom: sectionPadding }"
  >
    <div class="icon_box" :style="{ width: iconSize, height: iconSize }"></div>
    <h3 class="no_facility_title">{{title}}</h3>
    <slot></slot>
  </section>
</template>
<script>
export default {
  name: "EmptyResult",
  props: {
    title: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: "40px",
    },
    sectionPadding: {
      type: String,
      required: false,
      default: "40px",
    },
  },
};
</script>
<style lang="scss" scoped>
.no_facility {
  text-align: center;
}
h3 {
  font-weight: 400;
  line-height: 1.5;

  @include mobile {
    font-size: 2rem;
  }
}
.no_facility_title{
  white-space: pre-line;
  font-size: 2.4rem;
}
.icon_box {
  display: inline-block;
  background: url("@/../public/images/location_exclamation.svg") no-repeat
    center;
  background-size: contain;
  margin-bottom: 20px;
}
.add_facility {
  margin-top: 30px;
  font-size: 1.6rem;
}
</style>
