/**
 * 입력된 값에서 모든 특수문자와 공백을 제거하고 반환한다.
 * @param {*} event
 * @returns {string} 특수문자와 공백이 제거된 문자열
 */
export const removeEverySpecialChars = (event) => {
  const targetValue = event.target.value;

  // 모든 특수문자와 공백을 포함하는 정규 표현식
  const invalidCharPattern = /[~`!@#$%^&*()_+=\\[\]{}|\\:;'"<>,.?\\/-\s]/g; // 모든 특수문자 및 공백

  // 허용되지 않는 모든 특수문자와 공백 제거
  return targetValue.replace(invalidCharPattern, "");
};
