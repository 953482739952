<template>
  <div class="mapping_left_side_button_wrap">
    <!-- <div class="mapping_left_side_button button" @click="clickPause">일시 정지</div> -->
    <div class="mapping_left_side_button button" @click="clickClear">
      {{ $t("button.clear") }}
    </div>
    <div class="mapping_left_side_button button" @click="clickSave">
      {{ $t("button.save") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  emits: ["clickClear", "clickSave"],
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    clickClear() {
      if (confirm(this.$t("droneAlert.reallyClearPointCloudData"))) {
        this.$emit("clickClear");
      } else {
        return false;
      }
    },
    clickSave() {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        if (confirm(this.$t("droneAlert.reallySavePointCloudData"))) {
          this.$emit("clickSave");
        } else {
          return false;
        }
      }
    },
    // clickPause(){
    //   if(confirm('포인트 클라우드 데이터 수신을 일시 정지하시겠습니까?')){
    //     return true
    //   }
    //   else{
    //     return false
    //   }
    // }
  },
};
</script>

<style lang="scss">
.mapping_left_side_button_wrap {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 35px;
}
.mapping_left_side_button {
  @include flexbox;
  width: calc((100% - 20px) / 2);
  height: 30px;
  font-size: 1.4rem;
}
</style>
