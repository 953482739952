<template>
  <component :is="$route.meta.layout || 'main'">
    <div class="page_not_found">
      <h1>
        <img
          src="@/../public/images/message_exclamation.svg"
          alt="404 Page not Found"
        />
      </h1>
      <h2>{{ $t("message.404ErrorTitle") }}</h2>
      <p class="error_text">{{$t('message.404ErrorText')}}</p>
      <button
        class="default_border_hover_button"
        type="button"
        @click="changeRouter"
      >
        {{ $t("button.goMain") }}
      </button>
    </div>
  </component>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "NotFoundPage",
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    changeRouter() {
      if (this.user) {
        return this.$router.push("/");
      }
      return this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.page_not_found {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background: $main_black;
  @include flexbox(center, center, column);
}

h1 {
  max-width: 70px;
  width: 100%;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

h2 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 12px;
}

a {
  font-size: 1.4rem;
  color: #fff;
}

p {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.5;
  white-space: pre-line;
}

.default_border_hover_button {
  padding: 8px 12px;
  margin-top: 20px;
}
</style>
