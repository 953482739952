/**
 *
 * @param {String} isoString
 * @param {Boolean} noTimeStamp
 * @returns `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
 */
export const formatDateTime = (isoString, noTimeStamp = false) => {
  // Date 객체 생성
  const date = new Date(isoString);

  // 유효한 날짜인지 확인
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  if (noTimeStamp) {
    return `${year}-${month}-${day}`;
  }

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
