class SSE_CAPTURE {
  constructor(url, captureInfoList, result, callback) {
    this.url = url;
    this.result = result;
    this.callback = callback;
    this.captureInfoList = captureInfoList;
    this.eventSource = new EventSource(this.url);
    this.onMessage = this.onMessage.bind(this);
    this.onError = this.onError.bind(this);
    this.init();
  }
  init() {
    this.eventSource.onmessage = this.onMessage;
    this.eventSource.onerror = this.onError;
  }
  onMessage(event) {
    const data = event.data; // JSON 데이터로 변환
    // console.log("SSE 메세지: " + data);
    if (data.includes("Complete")) {
      this.captureInfoList.forEach((item) => {
        if (item.id === this.result.id) {
          item.elevationSetInfo = item.elevationSetInfo || {}; // elevationSetInfo가 없으면 초기화
          item.elevationSetInfo.elevationSetStatus = "Generation Complete";
          this.result.elevationCrackRecognitionInfo = null;
        }
      });
      this.callback(this.captureInfoList);
      this.cleanup();
    }
  }
  onError() {
    // console.error("SSE 오류가 발생했습니다:", error);
    this.captureInfoList.forEach((item) => {
      if (item.id === this.result.id) {
        item.elevationSetInfo = item.elevationSetInfo || {}; // elevationSetInfo가 없으면 초기화
        item.elevationSetInfo.elevationSetStatus = "Generation Failed";
        this.result.elevationCrackRecognitionInfo = null;
      }
    });
    this.callback(this.captureInfoList);
    this.cleanup();
  }
  cleanup() {
    if (this.eventSource) {
      this.eventSource.close(); // 연결 종료
      this.eventSource.onmessage = null; // 이벤트 리스너 제거
      this.eventSource.onerror = null;
      this.eventSource = null; // 객체 참조 해제
    }
  }
}

export default SSE_CAPTURE;
