<template>
  <div id="form_content_wrap" class="userFormContent">
    <form class="form_wrap">
      <div class="content_area custom_scrollbar">
        <div class="profile">
          <div
            class="profile_image_box"
            :class="{
              no_image: !uploadedImageUrl || isDeleted,
            }"
            :style="[
              uploadedImageUrl && !isDeleted
                ? { backgroundImage: `url(${uploadedImageUrl})` }
                : null,
            ]"
          >
            <input
              type="file"
              id="profile_image"
              accept=".jpg, .jpeg, .png"
              @change="uploadFile($event)"
            />
            <label for="profile_image"></label>
            <div class="camera_icon"></div>
          </div>
        </div>
        <!-- 이미 등록된 이미지가 있거나, 새로 업로드한 이미지가 있고, 삭제 상태가 아닐 때 때에만 활성화되도록 -->
        <div class="delete_area">
          <button
            type="button"
            :disabled="!uploadedImageUrl"
            @click="removeProfileImage"
          >
            {{ $t("button.deleteProfileImage") }}
          </button>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="required">{{ $t("term.fullName") }}</label>
            <div class="input_container focus">
              <input
                class=""
                ref="name"
                type="text"
                :placeholder="$t('placeholder.inputName')"
                @input="inputCheckAndRemove($event, 10)"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="required">{{ $t("term.admin") }}</label>
            <DefaultSelectBox
              :className="`focus full`"
              :isOptionOpen="isOptionOpen"
              :defaultName="$t('placeholder.selectAdmin')"
              :selectedName="clickedOptionValue?.name"
              @update:isOptionOpen="isOptionOpen = $event"
              @controlOptionToggle="controlOptionToggle"
            >
              <ul>
                <li
                  v-for="(item, index) in adminArray"
                  :key="item + index"
                  @click="handleClickOption(item)"
                  :class="item === this.clickedOptionValue ? 'active' : ''"
                >
                  <button class="option_text" type="button">
                    {{ item.name }}
                  </button>
                </li>
              </ul>
            </DefaultSelectBox>
          </div>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="required">{{ $t("term.department") }}</label>
            <div class="input_container focus">
              <input
                class=""
                ref="field"
                type="text"
                :placeholder="$t('placeholder.inputDepartment')"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="required">{{ $t("term.rank") }}</label>
            <div class="input_container focus">
              <input
                class=""
                ref="position"
                type="text"
                :placeholder="$t('placeholder.inputPosition')"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <p class="gray_text">{{ `*${$t("message.inputDepartmentMethod")}` }}</p>
        <label class="required">{{ $t("term.id") }}</label>
        <div class="input_container focus">
          <input
            class=""
            ref="userId"
            type="text"
            :placeholder="$t('placeholder.inputId')"
            @input="inputCheckAllAndRemove($event, 10)"
            autocomplete="off"
          />
        </div>
        <label class="required">{{ $t("term.password") }}</label>
        <div class="input_container focus">
          <input
            class=""
            type="password"
            ref="password"
            :placeholder="$t('placeholder.inputPassword')"
            autocomplete="off"
          />
          <button
            class="eye_button"
            :class="isPasswordShow ? 'on' : ''"
            type="button"
            @click="showPassword($event, 'password')"
          ></button>
        </div>
        <label class="">{{ $t("term.contact") }}</label>
        <div class="input_container focus">
          <input
            class=""
            ref="contact"
            type="text"
            :placeholder="$t('placeholder.inputOnlyNumber')"
            autocomplete="off"
            v-model="defaultUserContactValue"
            @input="onInputChange"
          />
        </div>
      </div>
      <div class="button_box">
        <button @click="submitAdd" class="default_bg_button" type="button">
          {{ $t("button.add") }}
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          {{ $t("button.cancel") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { changeSiblingInputType } from "@/shared/util/changeSiblingInputType";
// import { checkAuthorizeLimit } from "@/shared/util/checkAuthorizeLimit";
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { filterNonNumeric } from "@/shared/util/filterNonNumeric";
import { mapState } from "vuex";
import CryptoJS from "crypto-js";
import { hasSpecialCharacter } from "@/shared/util/hasSpecialCharacter";
import { hasInvalidSpecialChars } from "@/shared/util/hasInvalidSpecialChars";
import { removeInvalidSpecialChars } from "@/shared/util/removeInvalidSpecialChars";
import { removeEverySpecialChars } from "@/shared/util/removeEverySpecialChars";

export default {
  name: "UserAddFormContent",
  components: { DefaultSelectBox },
  computed: {
    ...mapState(["user"]),
  },
  emits: ["handleUploadFile", "handleAddUser", "handleRemoveProfileImage"],
  props: {
    uploadedImageUrl: {
      type: String,
      required: false,
    },
    isDeleted: {
      type: Boolean,
      required: false,
    },
    userListArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      adminArray: [
        { id: "normal", name: this.$t("term.normal") },
        { id: "manager", name: this.$t("term.manager") },
      ],
      isPasswordShow: false,
      isPasswordConfirmShow: false,
      isOptionOpen: false,
      clickedOptionValue: null,
      imageFileInfo: null,
      defaultUserContactValue: null,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.updateAdminOptions();
    },
  },
  methods: {
    updateAdminOptions() {
      this.adminArray = [
        { id: "normal", name: this.$t("term.normal") },
        { id: "manager", name: this.$t("term.manager") },
      ];
    },
    /**
     * 모든 특수문자 체크해서 삭제
     */
    inputCheckAllAndRemove(event, limit) {
      if (hasSpecialCharacter(event)) {
        event.target.value = removeEverySpecialChars(event);

        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.somethingCannotContainSpecialCharacter", {
            something: "유저 아이디",
          })}\n${this.$t("otherAlert.specialCharacterNotAllowedRemoved")}`
        );
      }

      if (checkInputLimit(event, limit)) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    /**
     * 특수문자와 공백을 체크해서 삭제 + 글자수 제한
     */
    inputCheckAndRemove(event, limit) {
      if (hasInvalidSpecialChars(event)) {
        event.target.value = removeInvalidSpecialChars(event); // 입력 필드에 수정된 값 반영

        this.$store.commit(
          "openAlert",
          `${this.$t(
            "otherAlert.specialCharacterSpacingNotAllowedExceptSomething",
            {
              character: "_ - ,",
            }
          )}\n${this.$t("otherAlert.specialCharacterNotAllowedRemoved")}`
        );
      }

      if (checkInputLimit(event, limit)) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    inputCheck(event, limit) {
      if (checkInputLimit(event, limit)) {
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    showPassword(event, target) {
      if (target === "password") {
        this.isPasswordShow = !this.isPasswordShow;
      } else {
        this.isPasswordConfirmShow = !this.isPasswordConfirmShow;
      }
      changeSiblingInputType(event);
    },
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlOptionToggle() {
      this.isOptionOpen = !this.isOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickOption(value) {
      this.isOptionOpen = false;
      this.clickedOptionValue = value;
    },
    /**
     * 파일 업로드
     */
    uploadFile(event) {
      const inputElement = event.target;

      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        const basename = file.name.replace(/\.[^/.]+$/, "");

        // 수정된 특수 문자 정규 표현식
        let regExp = /[`~!@#$%^&*|\\'";:/?()]/gi;

        // 특수 문자가 포함되어 있는지 체크
        if (regExp.test(basename)) {
          return this.$store.commit(
            "openAlert",
            this.$t("otherAlert.pleaseRemoveSpecialCharacterAndUploadImageFile")
          );
        }

        this.imageFileInfo = file;
        this.$emit("handleUploadFile", { file, inputElement });
      }
    },
    /**
     * 사용자 정보 추가 기능
     */
    submitAdd() {
      let userName = this.$refs.name.value;
      let userRole =
        this.clickedOptionValue === null ? null : this.clickedOptionValue.id;
      let userId = this.$refs.userId.value;
      let userPw = this.$refs.password.value;
      let userContact = this.$refs.contact.value;
      let userField = this.$refs.field.value;
      let userPosition = this.$refs.position.value;

      const userInfo = {
        adminId: this.user.id,
        userLoginId: userId ? userId : null,
        userPassword: userPw ? CryptoJS.SHA256(userPw).toString() : null,
        fullName: userName ? userName : null,
        role: userRole ? userRole : null,
        contact: userContact ? userContact : null,
        affiliation: {
          departmentName: userField ? userField : null,
          rankName: userPosition ? userPosition : null,
        },
      };

      if (
        !userName ||
        userRole === null ||
        !userId ||
        !userPw ||
        !userField ||
        !userPosition
      ) {
        if (!userName) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.fullName"),
            })}`
          );
        } else if (!userRole || userRole === null) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.admin"),
            })}`
          );
        } else if (!userField) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.department"),
            })}`
          );
        } else if (!userPosition) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.rank"),
            })}`
          );
        } else if (!userId) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.id"),
            })}`
          );
        } else if (!userPw) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.password"),
            })}`
          );
        }

        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.enterEveryRequiredInput")
        );
      }

      this.$emit("handleAddUser", userInfo);
    },
    /**
     * 프로필 이미지 삭제
     */
    removeProfileImage() {
      this.$emit("handleRemoveProfileImage");
    },
    onInputChange(event) {
      // 숫자가 아닌 입력값을 제외시킨다.
      this.defaultUserContactValue = filterNonNumeric(event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.userFormContent {
  .profile {
    position: relative;
    @include flexbox;
    margin-bottom: 12px;

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }

    .profile_image_box {
      position: relative;
      width: 70px;
      height: 70px;
      border-radius: 50px;
      background-size: contain;
      background-repeat: no-repeat;

      &.no_image {
        @include basicProfile(70px, 70px);
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .camera_icon {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 20px;
        height: 20px;
        background: #000 url("@/../public/images/camera.svg") no-repeat center;
        background-size: 60%;
        border-radius: 50px;
        border: 1px solid $main_white;
      }
    }
  }

  label {
    position: relative;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 12px;
    display: inline-block;
    margin-top: 18px;

    & ~ label {
      margin-top: 18px;
    }
  }

  p.gray_text {
    font-size: 1.25rem;
    margin-top: 12px;
    line-height: 1.4;
    white-space: pre-line;
  }

  .delete_area {
    @include flexbox(center, flex-end, row);
    margin: 12px 0;

    button {
      text-decoration: underline;
      color: $main_white;

      &:disabled {
        color: $gray_6e6e82;
      }

      &.red_text {
        color: $red_ff7070;
      }
    }
  }
}
</style>
