<template>
  <div v-if="currentPageGroup.length" class="pagination_wrap">
    <button
      v-if="canJumpToPreviousPageGroup"
      @click="jumpToPreviousPageGroup"
      class="previous"
    ></button>
    <div class="number_list">
      <button
        v-for="page in currentPageGroup"
        :key="page"
        @click="changePage(page)"
        class="number"
        :class="{ active: page === currentPage }"
      >
        {{ page }}
      </button>
    </div>
    <button
      v-if="canJumpToNextPageGroup"
      @click="jumpToNextPageGroup"
      class="next"
    ></button>
  </div>
</template>

<script>
export default {
  name: "PagiNation",
  emits: ["handleClickPaginationNumber"],
  props: {
    /**
     * DB 아이템 총 개수
     */
    totalCount: {
      type: Number,
      required: true,
    },
    /**
     * 한 페이지에 보여질 아이템 개수
     * @default 6
     */
    itemsPerPage: {
      type: Number,
      default: 6,
    },
    /**
     * 페이지 그룹 당 페이지 개수
     * (= 페이지 네비게이션 바에 보여질 페이지 숫자의 개수)
     * @default 5
     */
    pagesPerGroup: {
      type: Number,
      default: 5,
    },
    /**
     * 마운트 이후 초기 페이지 번호 (혹은 현재 번호)
     * 컴포넌트의 마운트 이후 가질 초기 페이지 번호를 지정해야 한다.
     * @default 1
     */
    initialPageOnMount: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    initialPageOnMount(newPage) {
      this.currentPage = newPage;
    },
  },
  data() {
    return {
      currentPage: this.initialPageOnMount, // 마운트 이후 시작 페이지 번호
    };
  },
  computed: {
    totalPages() {
      // js ceil -> 소숫점 이하 반올림
      return this.totalCount
        ? Math.ceil(this.totalCount / this.itemsPerPage)
        : 0;
    },
    currentPageGroup() {
      // js floor -> 소숫점 이하 버림
      // 첫번째 페이지 번호를 구한다
      const startPage =
        Math.floor((this.currentPage - 1) / this.pagesPerGroup) *
          this.pagesPerGroup +
        1;
      // js Math.min -> 주어진 숫자 중 가장 낮은 숫자 반환
      // 그룹의 마지막 페이지 번호를 계산한다
      const endPage = Math.min(
        startPage + this.pagesPerGroup - 1,
        this.totalPages
      );
      // 길이가  endPage - startPage + 1  인 배열처럼 동작한다.
      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
    canJumpToPreviousPageGroup() {
      return Math.ceil(this.currentPage / this.pagesPerGroup) !== 1;
    },
    canJumpToNextPageGroup() {
      return (
        Math.ceil(this.currentPage / this.pagesPerGroup) * this.pagesPerGroup <
        this.totalPages
      );
    },
  },
  methods: {
    changePage(pageNumber) {
      const targetPageNumber = Number(pageNumber);
      if (targetPageNumber > 0 && targetPageNumber <= this.totalPages) {
        this.currentPage = targetPageNumber;
      }

      this.$emit("handleClickPaginationNumber", pageNumber);
    },
    jumpToPreviousPageGroup() {
      if (this.canJumpToPreviousPageGroup) {
        this.currentPage =
          (Math.ceil(this.currentPage / this.pagesPerGroup) - 1) *
          this.pagesPerGroup;

        this.$emit("handleClickPaginationNumber", this.currentPage);
      }
    },
    jumpToNextPageGroup() {
      if (this.canJumpToNextPageGroup) {
        this.currentPage =
          Math.ceil(this.currentPage / this.pagesPerGroup) *
            this.pagesPerGroup +
          1;

        this.$emit("handleClickPaginationNumber", this.currentPage);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.pagination_wrap {
  @include flexbox;

  .number_list {
    @include flexbox;
    gap: 10px;
    margin: 0 12px;
  }

  .number {
    border-radius: 4px;
    font-size: 1.4rem;
    padding: 6px 10px;
    color: $main_white;
    transition: 0.15s;

    &.active {
      font-weight: 700;
      background-color: $main_color_10;
      color: $main_color;
    }

    &:hover {
      font-weight: 700;
      background-color: $main_color_10;
      color: $main_color;
    }
  }

  .previous {
    width: 20px;
    height: 20px;
    background: url("@/../public/images/angle-left-light.svg") no-repeat center;
    background-size: contain;

    &:hover {
      background: url("@/../public/images/angle-left-light-main.svg") no-repeat
        center;
    }
  }

  .next {
    width: 20px;
    height: 20px;
    background: url("@/../public/images/angle-left-light.svg") no-repeat center;
    background-size: contain;
    transform: rotate(180deg);

    &:hover {
      background: url("@/../public/images/angle-left-light-main.svg") no-repeat
        center;
    }
  }
}
</style>
