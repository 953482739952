<template>
  <component :is="$route.meta.layout || 'main'">
    <div class="main_fixed main korean">
      <MissionSaveModal
        :isMissionSaveModalVisible="isMissionSaveModalVisible"
        @closeModal="isMissionSaveModalVisible = false"
        @saveMission="handleSaveMission"
      />
      <MainImportantMessage
        v-if="importantMessage"
        :importantMessage="importantMessage"
      />
      <MissionListModal
        :isMissionListModalVisible="isMissionListModalVisible"
        :missionList="missionList"
        @closeModal="isMissionListModalVisible = false"
        @loadingMission="handleLoadingMission"
        @deleteMission="handleDeleteMission"
      />
      <div class="cloud_viewer_3d" ref="cloud_viewer_3d">
        <div class="inspection_main_menu">
          <div class="inspection_site_title">{{ $t("term.facility") }}</div>
          <div class="inspection_site_content" v-if="selectedFacility">
            {{ selectedFacility.name }}
          </div>
          <div class="inspection_current_map_content">
            <div class="inspection_current_map_name">
              {{ $t("term.selectedMap") }}
            </div>
            <div
              class="inspection_currnet_map_container"
              v-if="selectedFacilityMap"
            >
              {{ selectedFacilityMap.createdDatetime }}
            </div>
          </div>
          <div class="inspection_button_contents">
            <div
              v-if="selectedDroneType?.name !== 'Handy'"
              class="inspection_plan_control button"
              @click="planOn()"
            >
              {{ $t("term.flightPlanInspectionControl") }}
            </div>
            <div class="inspection_view_buttons_container">
              <div
                v-if="isLogVisible"
                class="inspection_log_button button on"
                @click="logOn()"
              ></div>
              <div
                v-else
                class="inspection_log_button button"
                @click="logOn()"
              ></div>
              <div
                v-if="isMonitorVisible"
                class="inspection_monitor_button button on"
                @click="monitorOn()"
              ></div>
              <div
                v-else
                class="inspection_monitor_button button"
                @click="monitorOn()"
              ></div>
              <div
                v-if="isSettingModalVisible"
                class="inspection_setting_button button on"
                @click="settingOn()"
              ></div>
              <div
                v-else
                class="inspection_setting_button button"
                @click="settingOn()"
              ></div>
            </div>
          </div>
        </div>
        <div
          v-if="!isMonitorVisible"
          class="inspection_view_label input"
          style="right: calc(50% + 20px)"
        >
          {{ $t("term.3dMap") }}
        </div>
      </div>
      <div
        v-if="isMobile"
        class="cloud_viewer_2d"
        @touchstart="handleMouseDown"
        @touchmove="handleMouseMove"
        @touchend="handleMouseUp"
        ref="cloud_viewer_mobile_2d"
      >
        <div
          v-if="!isMonitorVisible"
          class="inspection_view_label input"
          @click.stop
        >
          {{ $t("term.2dMap") }}
        </div>
      </div>
      <div
        v-else
        class="cloud_viewer_2d"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        ref="cloud_viewer_2d"
      >
        <div
          v-if="!isMonitorVisible"
          class="inspection_view_label input"
          @click.stop
        >
          {{ $t("term.2dMap") }}
        </div>
      </div>
      <div
        v-if="selectedDroneType.name !== 'Handy'"
        class="inspection_drone_selection_container"
        @click.stop="activeDroneSelection()"
      >
        <div class="inspection_selected_drone">
          {{ $t("term.drone") }} {{ currentDroneId + 1 }}
        </div>
        <div class="isnpection_select_drone_image"></div>
      </div>
      <div
        v-if="
          !droneData[this.currentDroneId].state.localization ||
          !moduleData.state.localization
        "
        class="inspection_drone_localization_apply_button"
        @click.stop="setLocalizationConfig"
      >
        {{ $t("term.setting_Camel") }}
      </div>
      <div
        v-if="droneSelectionFlag && selectedDroneType.name !== 'Handy'"
        class="inspection_select_drone_modal"
      >
        <div class="inspection_drone_item" @click.stop="droneSelection(0)">
          {{ $t("term.drone") }} 1
        </div>
        <div class="inspection_drone_item" @click.stop="droneSelection(1)">
          {{ $t("term.drone") }} 2
        </div>
        <div class="inspection_drone_item" @click.stop="droneSelection(2)">
          {{ $t("term.drone") }} 3
        </div>
        <div class="inspection_drone_item" @click.stop="droneSelection(3)">
          {{ $t("term.drone") }} 4
        </div>
        <div class="inspection_drone_item last" @click.stop="droneSelection(4)">
          {{ $t("term.drone") }} 5
        </div>
      </div>
      <div
        class="inspection_area_height_controller_localization"
        v-if="
          !droneData[this.currentDroneId].state.localization ||
          !moduleData.state.localization
        "
      >
        <div class="inspection_area_currnet_height">
          {{ $t("term.rangeSetting") }}
        </div>
        <div class="inspection_area_height_area">
          <input
            :placeholder="localization_height.min"
            class="inspection_area_height_area_value"
            @blur="handleLocalizationHeight($event, 'min')"
          />
          <div
            :style="{
              width: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }"
          >
            ~
          </div>
          <input
            class="inspection_area_height_area_value"
            :placeholder="localization_height.max"
            @blur="handleLocalizationHeight($event, 'max')"
          />
        </div>
      </div>
      <div
        id="handy_controller"
        :class="{ wide: isVideoWideMode }"
        v-if="
          isShootingControlModalVisible && selectedDroneType.name === 'Handy'
        "
      >
        <div class="joystick_wrap">
          <JoyStick
            v-if="joyStickDir === 'all'"
            :hasResetButton="true"
            :joyStickDir="joyStickDir"
            :joyStickCustomOption="joyStickCustomOption"
            :joyStickReturnValue="joyStickReturnValue"
            :isJoyStickTouchActive="isJoyStickTouchActive"
            @handleJoyStickReturnValue="handleJoyStickReturnValue"
            @handleJoyStickEndMethod="handleJoyStickEndMethod"
            @handleJoyStickStartMethod="handleJoyStickStartMethod"
            @handleResetJoyStickData="handleResetJoyStickData"
            @handleChangeJoyStickDir="handleChangeJoyStickDir"
          />
          <JoyStick
            v-else-if="joyStickDir === 'yaw'"
            :hasResetButton="true"
            :joyStickDir="joyStickDir"
            :joyStickCustomOption="joyStickCustomOption2"
            :joyStickReturnValue="joyStickReturnValue"
            :isJoyStickTouchActive="isJoyStickTouchActive"
            @handleJoyStickReturnValue="handleJoyStickReturnValue"
            @handleJoyStickEndMethod="handleJoyStickEndMethod"
            @handleJoyStickStartMethod="handleJoyStickStartMethod"
            @handleResetJoyStickData="handleResetJoyStickData"
            @handleChangeJoyStickDir="handleChangeJoyStickDir"
          />
          <JoyStick
            v-else-if="joyStickDir === 'pitch'"
            :hasResetButton="true"
            :joyStickDir="joyStickDir"
            :joyStickCustomOption="joyStickCustomOption3"
            :joyStickReturnValue="joyStickReturnValue"
            :isJoyStickTouchActive="isJoyStickTouchActive"
            @handleJoyStickReturnValue="handleJoyStickReturnValue"
            @handleJoyStickEndMethod="handleJoyStickEndMethod"
            @handleJoyStickStartMethod="handleJoyStickStartMethod"
            @handleResetJoyStickData="handleResetJoyStickData"
            @handleChangeJoyStickDir="handleChangeJoyStickDir"
          />
          <RangeSelector
            class="rangeSelector"
            :name="'sensitivity'"
            :id="'GimbalSensitivity'"
            :label="$t('term.sensitivity')"
            :min="0.1"
            :max="0.9"
            :isValueVisible="true"
            :step="0.05"
            @handleChangeInputValue="handleChangeInputValue"
          />
        </div>
        <!-- <InspectionHandyVideo /> -->
        <div id="VideoBoxContainer" ref="VideoBoxContainer">
          <div class="video_box">
            <video
              id="camera"
              ref="liveCamera"
              autoplay
              playsinline
              @click="makeVideoBigger"
            ></video>
            <div class="signal_text" v-if="!hasVideoSignal">NO SIGNAL</div>
          </div>
          <button
            type="button"
            class="handyLiveVideoCamera_select_button default_border_color_button"
            @click="handleToggleChangeCameraModal(true)"
          >
            {{ $t("term.selectCamera") }}
          </button>
          <!-- TODO : 카메라 해제 -->
          <!-- <button
            v-if="selectedVideoInputElement"
            type="button"
            class="handyLiveVideoCamera_select_button default_border_color_button"
            @click="handleDisconnectLiveVideo"
          >
            {{ $t("term.turnOffCamera") }}
          </button>
          <button
            v-else
            type="button"
            class="handyLiveVideoCamera_select_button default_border_color_button"
            @click="handleToggleChangeCameraModal(true)"
          >
            {{ $t("term.selectCamera") }}
          </button> -->
        </div>

        <ShootingControlModal
          :handyShootingMode="handyShootingMode"
          :handyDayNightTime="handyDayNightTime"
          :handyIntervalShootingOn="handyIntervalShootingOn"
          :droneControl="droneControl"
          :currentDroneId="currentDroneId"
          :pitchMin="pitchMin"
          :pitchMax="pitchMax"
          :yawMin="yawMin"
          :yawMax="yawMax"
          @handleChangeHandyShootingMode="handleChangeHandyShootingMode"
          @handleShootingStatus="handleShootingStatus"
          @handleSendHandyDayNightTime="handleSendHandyDayNightTime"
          @handleChangeIntervalShootingState="handleChangeIntervalShootingState"
        />
      </div>

      <InspectionHandyCameraSelectModal
        v-if="isVideoSelectModalOpen"
        :videoDeviceList="videoDeviceList"
        :selectedVideoInputElement="selectedVideoInputElement"
        @handleToggleChangeCameraModal="handleToggleChangeCameraModal"
        @selectVideoDevice="selectVideoDevice"
      />

      <PlanModal
        :isVisible="isPlanModalVisible"
        :isMonitorVisible="isMonitorVisible"
        :waypointList="waypoints"
        :groupList="groups"
        :currentDroneId="currentDroneId"
        :lineParams="lineParams"
        :undersideParams="undersideParams"
        :circleParams="circleParams"
        :rectangleParams="rectangleParams"
        :lowerHeight="lowerHeight"
        :upperHeight="upperHeight"
        :droneControl="droneControl"
        :droneData="droneData"
        :droneParams="droneParams"
        :fitShape="fitShape"
        :isMissionListModalVisible="isMissionListModalVisible"
        :isMissionSaveModalVisible="isMissionSaveModalVisible"
        @openMissionListModal="missionListOn"
        @openMissionSaveModel="missionSaveOn"
        @missionStateChange="handleMissionState"
        @fitShapeSelected="handleFitShape"
        @fitApplyButtonClicked="handleFitApplyButtonClicked"
        @deleteWaypoint="handleDeleteWaypoint"
        @clearWaypoint="handleClearWaypoint"
        @deleteGroup="handleDeleteGroup"
        @rotateGroup="handleRotateGroup"
        @mergeGroup="handleMergeGroup"
        @swapGroups="handleSwapGroup"
        @checkCollision="handleCheckCollision"
        @updateWaypoint="handleUpdateWaypoint"
        @heightChanged="handleHeightChanged"
        @lineParamsChanged="handleLineParamsChanged"
        @lineNFitPointsChanged="handleLineNFitPointsChanged"
        @undersideParamsChanged="handleUndersideParamsChanged"
        @undersideNBoxPointsChanged="handleUndersideNBoxPointsChanged"
        @circleParamsChanged="handleCircleParamsChanged"
        @rectangleParamsChanged="handleRectangleParamsChanged"
        @lowerHeightChanged="handleLowerHeightChanged"
        @upperHeightChanged="handleUpperHeightChanged"
        @planRTHClicked="handlePlanRTH"
        @planRTLClicked="handlePlanRTL"
        @changeArmPosition="handleArmPosition"
        @resetClicked="resetClicked"
        @clickRtl="this.droneData[currentDroneId].state.rtl = true"
        @clickRth="this.droneData[currentDroneId].state.rth = true"
        @changeWaypointNumber="handleWaypointNumber"
        @close="isPlanModalVisible = false"
      >
      </PlanModal>

      <MonitorModal
        :isVisible="isMonitorVisible"
        :droneData="droneData"
        :currentDroneId="currentDroneId"
        :selectedDroneType="selectedDroneType"
        @close="isMonitorVisible = false"
      >
      </MonitorModal>

      <LogModal
        :isVisible="isLogVisible"
        :statusText="statusText"
        @close="isLogVisible = false"
      >
      </LogModal>

      <SettingModal
        :isVisible="isSettingModalVisible"
        :selectedDrone="selectedDrone"
        :droneList="droneList"
        :selectedModule="selectedModule"
        :droneTypeList="droneTypeList"
        :selectedDroneType="selectedDroneType"
        :droneParams="droneParams"
        :pointCloudParams="pointCloudParams"
        :selectedHexColor="this.pointCloudParams.hexColor"
        @getDroneTypes="getDroneTypes"
        @droneTypeChanged="handleDroneTypeChanged"
        @droneChanged="selectDrone"
        @moduleChanged="handleModuleChanged"
        @takeoffChanged="hadleTakeoffChanged"
        @waitingTimeChanged="handleWaitingTimeChanged"
        @batteryFailSafeChanged="handleBatteryFailSafeChanged"
        @changePointCloudParam="handlePointCloudParam"
        @close="isSettingModalVisible = false"
      >
      </SettingModal>
    </div>
    <MainLoadingVue ref="mainLoading" />
  </component>
</template>

<script>
import Waypoint from "@/model/Waypoint.js";
import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Shape from "@/model/Shape.js"
import Drone from "@/model/Drone.js";
import DroneData from "@/model/InspectionDroneData.js";
import DroneType from "@/model/DroneType.js";
import PointCloudParams from "@/model/PointCloudParams.js";
import CollisionInfo from "@/model/CollisionInfo.js";

import PCReportViewer from "@/module/PointCloud/PointCloudViewInspection.js";
import WebSoketHandler from "@/module/Communication/WebSocketHandler.js";
import GroupHandler from "@/module/GroupHandler.js";
import DroneMonitor from "@/module/Drone/DroneMonitor.js";
import DroneControl from "@/module/Drone/DroneControl.js";

import "./InspectionMainMenu.scss";
import PlanModal from "./InspectionPlanModal.vue";
import MonitorModal from "./InspectionMonitorModal.vue";
import LogModal from "./InspectionLogModal.vue";
import SettingModal from "./InspectionSettingModal.vue";
import MissionSaveModal from "./InspectionMissionSaveModal.vue";
import MissionListModal from "./InspectionMissionListModal.vue";
import MainLoadingVue from "../Common/MainLoading.vue";
import MainImportantMessage from "../Common/MainImportantMessage.vue";

import { mapState } from "vuex";
import { markRaw } from "vue";
import InspectionMission from "@/model/InspectionMission";
// import { formatISO } from "date-fns";
import ShootingControlModal from "./ShootingControlModal.vue";
import JoyStick from "@/component/joystick/JoyStick.vue";
import InspectionHandyCameraSelectModal from "./InspectionHandyCameraSelectModal.vue";
import RangeSelector from "@/component/range-selector/RangeSelector.vue";
// import InspectionHandyVideo from "./InspectionHandyVideo.vue";

export default {
  name: "InspectionPage",
  data() {
    return {
      importantMessage: null,
      importantMessageTimer: null,
      map: null,
      cloudViewer: null,
      droneSelectionFlag: false,
      droneTypeList: [],
      selectedDroneType: new DroneType(),
      droneList: [],
      selectedDrone: [
        new Drone(),
        new Drone(),
        new Drone(),
        new Drone(),
        new Drone(),
      ],
      droneControl: [null, null, null, null, null],
      droneMonitor: [null, null, null, null, null],
      droneData: [
        new DroneData(),
        new DroneData(),
        new DroneData(),
        new DroneData(),
        new DroneData(),
      ],
      statusText: [],
      selectedModule: new Drone(),
      moduleControl: null,
      moduleMonitor: null,
      moduleData: { state: { localization: true } },
      currentDroneId: 0,
      isPlanModalVisible: false,
      isShootingControlModalVisible: false,
      isMonitorVisible: false,
      isLogVisible: false,
      isSettingModalVisible: false,
      isMissionListModalVisible: false,
      isMissionSaveModalVisible: false,
      waypoints: [[], [], [], [], []],
      groups: [[], [], [], [], []],
      shapeCreateCounter: [0, 0, 0, 0, 0],
      waypointCreateCounter: 0,
      fitShape: null,
      webSoketHandler: null,
      droneParams: {
        takeoffHeight: 10,
        waitingTime: 1,
        batteryFailSafe: 25,
        connectionLost: 10,
      },
      pointCloudParams: new PointCloudParams(),
      currentHeight: 0,
      lineParams: {
        heightInterval: 2,
        height: 5,
        fromWall: 5,
        nFitPoints: 2,
        gimbalPitch: 0,
        direction: "left",
        isAuto: true,
      },
      undersideParams: {
        interval: 3,
        fromWall: 7,
        nBoxPoints: 8,
        direction: "long",
        isAuto: true,
        droneDimX: 1,
        droneDimY: 1,
        droneDimZ: 1,
        gimbalPitch: 90,
      }, // dimension은 setting에서 받아올 것
      circleParams: {
        interval: 30,
        startAngle: 0,
        fromWall: 7,
        direction: "inward",
        gimbalControl: true,
      },
      rectangleParams: {
        interval: 2,
        fromWall: 7,
        direction: "inward",
        gimbalControl: true,
      },
      startPoint: { x: 0, y: 0 },
      endPoint: { x: 0, y: 0 },
      localization_height: { min: 0, max: 0, limitMin: 0, limitMax: 0 },
      lowerHeight: 0,
      upperHeight: 0,
      parsingCompletedListener: null,
      setCollidedWPListener: null,
      missionList: [],
      selectedHexColor: "",
      collisionInfoList: [],
      connectionAlert: [null, null, null, null, null, null],
      handyShootingMode: "",
      handyGimbalPitch: 0,
      handyGimbalYaw: 0,
      handyDayNightTime: "",
      handyIntervalShootingOn: false,
      moduleTypeIndex: 0,
      pitchMin: -20,
      pitchMax: 90,
      yawMin: -90,
      yawMax: 90,
      joyStickReturnValue: {},
      joyStickCustomOption: {
        mode: "static", // 'static' 또는 'dynamic'
        position: { left: "50%", top: "50%" }, // 초기 위치
        restOpacity: 1,
        follow: false,
        size: 150,
        lockX: false,
        lockY: false,
      },
      joyStickCustomOption2: {
        // yaw
        mode: "static", // 'static' 또는 'dynamic'
        position: { left: "50%", top: "50%" }, // 초기 위치
        restOpacity: 1,
        follow: false,
        size: 150,
        lockX: true,
        lockY: false,
      },
      joyStickCustomOption3: {
        // pitch
        mode: "static", // 'static' 또는 'dynamic'
        position: { left: "50%", top: "50%" }, // 초기 위치
        restOpacity: 1,
        follow: false,
        size: 150,
        lockX: false,
        lockY: true,
      },
      joyStickSensitivity: 0.75,
      isJoyStickTouchActive: false,
      joyStickInterval: null,
      joyStickDir: "all",
      videoDeviceList: [],
      selectedVideoInputElement: null,
      isVideoSelectModalOpen: false,
      isVideoWideMode: false,
      hasVideoSignal: false,
    };
  },
  computed: {
    ...mapState([
      "user",
      "apiIp",
      "apiPort",
      "selectedFacility",
      "selectedFacilityMap",
      "isMobile",
      "api",
    ]),
  },
  watch: {
    selectedDroneType: {
      handler(selectedDroneType) {
        if (selectedDroneType) {
          this.undersideParams.droneDimX = selectedDroneType.dimensionX;
          this.undersideParams.droneDimY = selectedDroneType.dimensionY;
          this.undersideParams.droneDimZ = selectedDroneType.dimensionZ;
        }
      },
    },
  },
  components: {
    PlanModal,
    MonitorModal,
    LogModal,
    SettingModal,
    MissionListModal,
    MissionSaveModal,
    MainLoadingVue,
    MainImportantMessage,
    ShootingControlModal,
    JoyStick,
    InspectionHandyCameraSelectModal,
    RangeSelector,
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    planOn() {
      if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.droneLocalizationNeeded")
        );
      } else {
        this.isShootingControlModalVisible = false;
        this.isPlanModalVisible = true;
        this.isLogVisible = false;
        this.isSettingModalVisible = false;
        this.isMissionListModalVisible = false;
        this.isMissionSaveModalVisible = false;
      }
    },
    monitorOn() {
      this.isMonitorVisible = !this.isMonitorVisible;
    },
    logOn() {
      this.isLogVisible = !this.isLogVisible;
      this.isPlanModalVisible = false;
      this.isSettingModalVisible = false;
      this.isMissionListModalVisible = false;
      this.isMissionSaveModalVisible = false;
    },
    settingOn() {
      this.isSettingModalVisible = !this.isSettingModalVisible;
      this.isMissionListModalVisible = false;
      this.isPlanModalVisible = false;
      this.isLogVisible = false;
      this.isMissionSaveModalVisible = false;
    },
    missionListOn() {
      this.isSettingModalVisible = false;
      this.isLogVisible = false;
      this.isMissionSaveModalVisible = false;
      this.isMissionListModalVisible = !this.isMissionListModalVisible;
    },
    missionSaveOn() {
      this.isMissionSaveModalVisible = !this.isMissionSaveModalVisible;
      this.isSettingModalVisible = false;
      this.isLogVisible = false;
      this.isMissionListModalVisible = false;
    },
    activeDroneSelection() {
      if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.droneLocalizationNeeded")
        );
      } else {
        this.droneSelectionFlag = !this.droneSelectionFlag;
      }
    },
    setLocalizationConfig() {
      let rectPoints = this.cloudViewer.getRectPoints();
      if (rectPoints.length == 5) {
        let minX = rectPoints[0].x;
        let maxX = rectPoints[3].x;
        let minY = -rectPoints[1].z;
        let maxY = -rectPoints[0].z;
        if (maxX - minX < 100) {
          let centerX = (minX + maxX) / 2;
          minX = centerX - 50;
          maxX = centerX + 50;
        }
        if (maxY - minY < 100) {
          let centerY = (minY + maxY) / 2;
          minY = centerY - 50;
          maxY = centerY + 50;
        }
        if (this.selectedDroneType.name == "Anafi") {
          this.moduleControl.localizationConfig(
            minX,
            maxX,
            minY,
            maxY,
            this.localization_height.min,
            this.localization_height.max
          );
          this.cloudViewer.rectClear();
          if (this.importantMessageTimer)
            clearTimeout(this.importantMessageTimer);
          this.importantMessage = this.$t(
            "droneAlert.siriusInitialPositionSetting"
          );
          this.importantMessageTimer = setTimeout(() => {
            this.importantMessage = null;
          }, 10000);
        } else {
          this.droneControl[this.currentDroneId].localizationConfig(
            minX,
            maxX,
            minY,
            maxY,
            this.localization_height.min,
            this.localization_height.max
          );
          this.cloudViewer.rectClear();
          if (this.importantMessageTimer)
            clearTimeout(this.importantMessageTimer);
          this.importantMessage = this.$t(
            "droneAlert.droneInitialPositionSetting",
            { droneId: this.currentDroneId + 1 }
          );
          this.importantMessageTimer = setTimeout(() => {
            this.importantMessage = null;
          }, 10000);
        }
      } else {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.dragOn2DMakeInitialDroneArea")
        );
      }
    },
    droneSelection(id) {
      this.currentDroneId = id;
      this.webSoketHandler.setDroneId(this.currentDroneId);
      this.droneSelectionFlag = false;
    },
    handleMouseDown(event) {
      if (event.button === 2) return;
      let intersectPoint;
      if (this.isMobile) {
        intersectPoint = this.cloudViewer.getInterSectionPointMobile(event);
      } else {
        intersectPoint = this.cloudViewer.getInterSectionPoint(event);
      }

      this.startPoint.x = intersectPoint.x;
      this.startPoint.y = -intersectPoint.z;

      if (this.fitShape == "Waypoint") {
        let wpRotation = this.getWpRotation(intersectPoint.x, intersectPoint.z);
        this.cloudViewer.drawWaypoint(
          this.currentDroneId,
          intersectPoint.x,
          intersectPoint.z,
          this.currentHeight,
          wpRotation
        );
        let waypoint = new Waypoint(
          intersectPoint.x,
          -intersectPoint.z,
          this.currentHeight,
          wpRotation,
          0,
          "waypoint",
          false,
          false,
          0,
          null
        );

        this.waypoints[this.currentDroneId].push(waypoint);
      } else if (this.fitShape == "Line") {
        if (this.isMobile) {
          this.cloudViewer.setStartPointLineMobile(
            intersectPoint.x,
            intersectPoint.z,
            this.currentHeight
          );
        } else
          this.cloudViewer.setStartPointLine(
            intersectPoint.x,
            intersectPoint.z,
            this.currentHeight
          );
      } else if (this.fitShape == "Underside") {
        if (this.isMobile)
          this.cloudViewer.setRegionPointsUndersideMobile(
            intersectPoint.x,
            intersectPoint.z
          );
        else
          this.cloudViewer.setRegionPointsUnderside(
            intersectPoint.x,
            intersectPoint.z
          );
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        this.cloudViewer.setStartPointRect(intersectPoint.x, intersectPoint.z);
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.cloudViewer.setStartPointRect(intersectPoint.x, intersectPoint.z);
      }
    },
    handleMouseMove(event) {
      if (event.button === 2) return;
      if (this.fitShape == "Line") {
        if (this.isMobile) return;
        if (this.cloudViewer.getLinePointsCount() == 0) return;
      } else if (this.fitShape == "Underside") {
        if (this.cloudViewer.getUndersidePointsCount() == 0) return;
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        if (!this.cloudViewer.getRectClicked()) return;
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        if (!this.cloudViewer.getRectClicked()) return;
      }

      let intersectPoint;
      if (this.isMobile)
        intersectPoint = this.cloudViewer.getInterSectionPointMobile(event);
      else intersectPoint = this.cloudViewer.getInterSectionPoint(event);
      this.endPoint.x = intersectPoint.x;
      this.endPoint.y = -intersectPoint.z;

      if (this.fitShape == "Line" && !this.isMobile)
        this.cloudViewer.fitRegionLineDraw(intersectPoint.x, intersectPoint.z);
      else if (this.fitShape == "Underside" && !this.isMobile) {
        this.cloudViewer.fitRegionUndersideBorderDraw(
          intersectPoint.x,
          intersectPoint.z
        );
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        this.cloudViewer.fitRegionRectDraw(
          this.startPoint,
          this.endPoint,
          intersectPoint.x,
          intersectPoint.z,
          this.lowerHeight,
          this.upperHeight
        );
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.cloudViewer.fitRegionRectDraw(
          this.startPoint,
          this.endPoint,
          intersectPoint.x,
          intersectPoint.z,
          this.lowerHeight,
          this.upperHeight
        );
      }
    },
    handleMouseUp(event) {
      if (event.button === 2) return;
      if (this.fitShape == "Line") {
        this.cloudViewer.fitRegionLineSurfaceDraw(this.lineParams.height);
      } else if (this.fitShape == "Underside") {
        this.cloudViewer.fitRegionUndersideSurfaceDraw(
          this.lowerHeight,
          this.upperHeight
        );
      } else if (this.fitShape == "Circle" || this.fitShape == "Rectangle") {
        this.cloudViewer.setRectClicked(false);
      } else if (
        !this.droneData[this.currentDroneId].state.localization ||
        !this.moduleData.state.localization
      ) {
        this.cloudViewer.setRectClicked(false);
      }
    },
    handleMissionState(boolean) {
      this.droneData[this.currentDroneId].state.mission = boolean;
    },
    getPrevPoint() {
      let prevX;
      let prevY;
      if (this.waypoints[this.currentDroneId].length === 0) {
        prevX = 0;
        prevY = 0;
      } else {
        prevX =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].getX();
        prevY =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].getY();
      }

      return { x: prevX, y: prevY };
    },
    getWpRotation(x, z) {
      let wpRotation;
      if (this.waypoints[this.currentDroneId].length == 0) {
        let deltaX = 0 - x;
        let deltaZ = 0 - z;
        if (deltaX === 0) return 0;
        let slope = deltaZ / deltaX;
        wpRotation = Math.atan(slope);
        if (deltaX < 0) wpRotation = wpRotation + Math.PI;
      } else {
        let deltaX =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].x - x;
        let deltaZ =
          -this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].y - z;
        if (deltaX === 0) return 0;
        let slope = deltaZ / deltaX;
        wpRotation = Math.atan(slope);
        if (deltaX < 0) wpRotation = wpRotation + Math.PI;
      }
      return wpRotation;
    },
    waypointReset(setFalse) {
      for (let i = 0; i < this.waypoints[this.currentDroneId].length; i++) {
        let x = this.waypoints[this.currentDroneId][i].getX();
        let z = -this.waypoints[this.currentDroneId][i].getY();
        // let y =  this.waypoints[this.currentDroneId][i].z;
        let tempGroupNum = this.waypoints[this.currentDroneId][i].getGroupNum();
        if (setFalse) this.waypoints[this.currentDroneId][i].setChecked(false);

        let wpRotation;
        if (i == 0) {
          let delta_x = 0 - x;
          let delta_z = 0 - z;
          if (delta_x === 0) wpRotation = 0;
          else {
            let slope = delta_z / delta_x;
            wpRotation = Math.atan(slope);
            if (delta_x < 0) wpRotation = wpRotation + Math.PI;
          }
          if (tempGroupNum === 0) {
            this.waypoints[this.currentDroneId][i].setYaw(wpRotation);
          }
        } else {
          let delta_x = this.waypoints[this.currentDroneId][i - 1].getX() - x;
          let delta_z = -this.waypoints[this.currentDroneId][i - 1].getY() - z;
          if (delta_x === 0) wpRotation = 0;
          else {
            let slope = delta_z / delta_x;
            wpRotation = Math.atan(slope);
            if (delta_x < 0) wpRotation = wpRotation + Math.PI;
          }
          if (tempGroupNum === 0)
            this.waypoints[this.currentDroneId][i].setYaw(wpRotation);
        }
      }
    },

    messageParsingFinished() {
      this.cloudViewer.resetWaypoints(this.currentDroneId);
    },

    setCollidedWaypoints() {
      this.cloudViewer.resetWaypoints(this.currentDroneId);
    },
    handleFitShape(shape) {
      this.fitShape = shape;
      this.cloudViewer.lineClear();
      this.cloudViewer.undersideClear();
      this.cloudViewer.rectClear();
      if (shape) {
        this.cloudViewer.setHeight(this.currentHeight);
        this.cloudViewer.setHeightRange(this.lowerHeight, this.upperHeight);
        this.cloudViewer.setHeightInfoVisible(true);
      } else {
        this.cloudViewer.setHeightInfoVisible(false);
      }
    },
    handleFitApplyButtonClicked() {
      if (this.fitShape == "Line") {
        this.webSoketHandler.setLineParams(this.lineParams);
        if (this.cloudViewer.checkLineRegion()) {
          let linePoints = this.cloudViewer.getLinePoints();
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setLineData(
            linePoints,
            this.currentHeight,
            prevWP
          );
          this.webSoketHandler.send(dataToSend);
        } else {
          this.$store.commit("setIsLoading", false);
          this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.fitPointError")}`
          );
        }
      } else if (this.fitShape == "Underside") {
        this.webSoketHandler.setUndersideParams(this.undersideParams);
        if (this.cloudViewer.checkUndersideRegion()) {
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setUndersideDataAuto(
            this.cloudViewer.getUndersidePointsFirst(),
            this.cloudViewer.getUndersidePointsSecond(),
            prevWP,
            this.currentHeight
          );
          this.webSoketHandler.send(dataToSend);
        } else {
          this.$store.commit("setIsLoading", false);
          this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.fitPointError")}`
          );
        }
      } else if (this.fitShape == "Circle") {
        this.webSoketHandler.setCircleParams(this.circleParams);
        if (this.cloudViewer.checkRectRegion()) {
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setCircleData(
            prevWP,
            this.currentHeight,
            this.startPoint,
            this.endPoint
          );
          this.webSoketHandler.send(dataToSend);
        } else {
          this.$store.commit("setIsLoading", false);
          this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.fitAreaError")}`
          );
        }
      } else if (this.fitShape == "Rectangle") {
        this.webSoketHandler.setRectParams(this.rectangleParams);
        if (this.cloudViewer.checkRectRegion()) {
          let prevWP = this.getPrevPoint();
          let dataToSend = this.webSoketHandler.setRectData(
            prevWP,
            this.currentHeight,
            this.startPoint,
            this.endPoint
          );
          this.webSoketHandler.send(dataToSend);
        } else {
          this.$store.commit("setIsLoading", false);
          this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.fitAreaError")}`
          );
        }
      }
      this.cloudViewer.lineClear();
      this.cloudViewer.undersideClear();
      this.cloudViewer.rectClear();
    },
    handleDeleteWaypoint() {
      if (this.waypoints[this.currentDroneId].length === 0) return;

      let tempArray = new Array(this.groups[this.currentDroneId].length).fill(
        0
      );

      for (
        let i = this.waypoints[this.currentDroneId].length - 1;
        i >= 0;
        i--
      ) {
        if (this.waypoints[this.currentDroneId][i].checked) {
          this.waypoints[this.currentDroneId].splice(i, 1);
        } else {
          if (this.waypoints[this.currentDroneId][i].getGroupNum() > 0)
            tempArray[
              this.waypoints[this.currentDroneId][i].getGroupNum() - 1
            ] =
              tempArray[
                this.waypoints[this.currentDroneId][i].getGroupNum() - 1
              ] + 1;
        }
      }
      this.waypointReset(true);
      this.cloudViewer.resetWaypoints(this.currentDroneId);

      if (this.groups[this.currentDroneId].length == 0) return;
      for (let i = this.groups[this.currentDroneId].length - 1; i >= 0; i--) {
        if (tempArray[i] == 0) {
          for (
            let j = this.waypoints[this.currentDroneId].length - 1;
            j >= 0;
            j--
          ) {
            if (this.waypoints[this.currentDroneId][j].getGroupNum() >= i + 1)
              this.waypoints[this.currentDroneId][j].setGroupNum(
                this.waypoints[this.currentDroneId][j].getGroupNum() - 1
              );
          }
          for (let j = 0; j < this.groups[this.currentDroneId].length; j++) {
            if (
              this.groups[this.currentDroneId][j].getGroupNum() >
              this.groups[this.currentDroneId][i].getGroupNum()
            )
              this.groups[this.currentDroneId][j].setGroupNum(
                this.groups[this.currentDroneId][j].getGroupNum() - 1
              );
          }
          this.groups[this.currentDroneId].splice(i, 1);
        }
      }
      this.groupHandler.reset(this.currentDroneId, true);
    },
    clearWaypointAll() {
      for (let i = 0; i < 5; i++) {
        this.waypoints[i] = [];
        this.groups[i] = [];
        this.droneData[i].state.waypointNumber = 0;
        this.cloudViewer.arrowLineClear(i);
      }
    },
    handleClearWaypoint() {
      this.cloudViewer.deleteTempWaypoint(this.currentDroneId);
      if (this.waypoints[this.currentDroneId].length === 0) return;
      this.waypoints[this.currentDroneId] = [];
      this.groups[this.currentDroneId] = [];
      this.droneData[this.currentDroneId].state.waypointNumber = 0;
      this.cloudViewer.arrowLineClear(this.currentDroneId);
    },
    handleDeleteGroup() {
      if (this.groups[this.currentDroneId].length === 0) return;
      this.groupHandler.delete(this.currentDroneId);
      this.waypointReset(true);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, true);
    },
    handleRotateGroup() {
      if (!this.groupHandler.rotate(this.currentDroneId)) return;
      this.waypointReset(false);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, false);
    },
    handleMergeGroup() {
      if (!this.groupHandler.merge(this.currentDroneId)) return;
      this.waypointReset(false);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, false);
    },
    handleSwapGroup(swapIdx) {
      this.groupHandler.swap(this.currentDroneId, swapIdx);
      this.waypointReset(false);
      this.cloudViewer.resetWaypoints(this.currentDroneId);
      this.groupHandler.reset(this.currentDroneId, false);
    },
    handleUpdateWaypoint() {
      this.cloudViewer.resetWaypoints(this.currentDroneId);
    },
    handleCheckCollision() {
      this.webSoketHandler.setDroneId(this.currentDroneId);
      let x = this.selectedDroneType.dimensionX / 1000;
      let y = this.selectedDroneType.dimensionY / 1000;
      let z = this.selectedDroneType.dimensionZ / 1000;
      this.webSoketHandler.sendWPData(this.currentDroneId, {
        x: x,
        y: y,
        z: z,
      });
    },
    handleHeightChanged(height) {
      this.currentHeight = height;
      this.cloudViewer.setHeight(height);
    },
    handleLowerHeightChanged(height) {
      if (height > this.upperHeight) {
        this.lowerHeight = height;
        this.upperHeight = height;
      } else this.lowerHeight = height;
      this.cloudViewer.setHeightRange(this.lowerHeight, this.upperHeight);
      this.webSoketHandler.setHeightRange(this.lowerHeight, this.upperHeight);
    },
    handleUpperHeightChanged(height) {
      if (height < this.lowerHeight) {
        this.lowerHeight = height;
        this.upperHeight = height;
      } else this.upperHeight = height;
      this.cloudViewer.setHeightRange(this.lowerHeight, this.upperHeight);
      this.webSoketHandler.setHeightRange(this.lowerHeight, this.upperHeight);
    },
    handlePlanRTH() {
      let z = this.droneParams.takeoffHeight;
      let yaw;

      if (this.waypoints[this.currentDroneId].length == 0) {
        yaw = -Math.PI;
      } else {
        yaw =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].yaw;
      }
      this.cloudViewer.drawWaypoint(this.currentDroneId, 0, 0, z, yaw);
      this.waypoints[this.currentDroneId].push(
        new Waypoint(0, 0, z, yaw, 0, "rth", false, false, 0, null)
      );
    },
    handlePlanRTL() {
      let x = this.droneData[this.currentDroneId].armPosition.x;
      let y = this.droneData[this.currentDroneId].armPosition.y;
      let z = this.droneParams.takeoffHeight;
      let yaw;

      if (this.waypoints[this.currentDroneId].length == 0) {
        yaw = -Math.PI;
      } else {
        yaw =
          this.waypoints[this.currentDroneId][
            this.waypoints[this.currentDroneId].length - 1
          ].yaw;
      }
      this.cloudViewer.drawWaypoint(this.currentDroneId, x, y, z, yaw);
      this.waypoints[this.currentDroneId].push(
        new Waypoint(x, y, z, yaw, 0, "rtl", false, false, 0, null)
      );
    },
    handleArmPosition() {
      this.droneData[this.currentDroneId].armPosition.x =
        this.droneData[this.currentDroneId].pose.x;
      this.droneData[this.currentDroneId].armPosition.y =
        this.droneData[this.currentDroneId].pose.y;
      this.droneData[this.currentDroneId].armPosition.yaw =
        this.droneData[this.currentDroneId].pose.yaw;
    },
    resetClicked() {
      this.handleWaypointNumber(this.currentDroneId, 0);
      this.cloudViewer.deleteTempWaypoint(this.currentDroneId);
    },
    handleWaypointNumber(droneNum, waypointNumber) {
      this.droneData[droneNum].state.waypointNumber = waypointNumber;
      for (let i = 0; i < this.waypoints[droneNum].length; i++) {
        if (i < waypointNumber) {
          this.waypoints[droneNum][i].completed = true;
        } else {
          this.waypoints[droneNum][i].completed = false;
        }
      }
      this.cloudViewer.resetWaypoints(droneNum);
    },
    handleLineParamsChanged(lineParams) {
      this.lineParams = lineParams;
    },
    handleLineNFitPointsChanged(lineParams) {
      this.lineParams = lineParams;
      this.cloudViewer.setNumLinePoints(lineParams.nFitPoints);
    },
    handleUndersideParamsChanged(undersideParams) {
      this.undersideParams = undersideParams;
    },
    handleUndersideNBoxPointsChanged(undersideParams) {
      this.undersideParams = undersideParams;
      this.cloudViewer.setUndersideNumRegionPoints(undersideParams.nBoxPoints);
    },
    handleCircleParamsChanged(circleParams) {
      this.circleParams = circleParams;
    },
    handleRectangleParamsChanged(rectangleParams) {
      this.rectangleParams = rectangleParams;
    },
    handleClearHandyInfo() {
      // 드론 모듈 타입 변경되면 우선 핸디 짐벌 관련 데이터 초기화
      this.joyStickReturnValue = {};
      this.handyGimbalYaw = 0;
      this.handyGimbalPitch = 0;
      this.handyShootingMode = "";
      this.handyDayNightTime = "";
      this.videoDeviceList = [];
      this.selectedVideoInputElement = null;
      this.isVideoSelectModalOpen = false;
      this.hasVideoSignal = false;
      this.handyIntervalShootingOn = false;
    },
    handleDroneTypeChanged(droneType) {
      this.handleClearHandyInfo();

      if (droneType) {
        if (droneType.name === "Handy") {
          this.currentDroneId = 0;
          this.isShootingControlModalVisible = true;
          this.isPlanModalVisible = false;
        }
        this.selectedDroneType = droneType;
      } else {
        for (let i = 0; i < 5; i++) {
          if (this.selectedDrone[i].id) this.selectDrone(i);
          if (this.selectedModule.id) this.handleModuleChanged();
        }
        this.selectedDroneType = new DroneType();
        this.isShootingControlModalVisible = false; // 촬영제어 모달 끈다
      }
    },
    selectDrone(num, drone) {
      if (this.selectedDroneType.id) {
        /**
         * 선택된 드론 정보(sirius, rotum, handy 등등)가 있을 때
         *
         */
        this.checkDroneStatus(num, drone);
      } else {
        /**
         * 선택된 모듈종류 없이 모듈 시리얼 넘버부터 고르면 선택된 드론 초기화 하고 알럿 띄운다.
         */
        this.selectedDrone[num] = new Drone();
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.selectModuleTypeFirst")
        );
      }
    },
    checkDroneStatus(num, drone) {
      if (drone) {
        this.api.getDrone(this.checkDroneStatusCallback, drone.id, num);
      } else {
        this.selectedDrone[num] = new Drone();
        this.connectDrone(num);
      }
    },
    checkDroneStatusCallback(data, num) {
      let result = data.data.result;
      if (result.droneStatus == "disConnecting") {
        this.selectedDrone[num] = new Drone(result);
      } else {
        this.$store.commit("openAlert", this.$t("droneAlert.alreadyInUseId"));
        this.selectedDrone[num] = new Drone();
      }
      this.connectDrone(num);
    },
    connectDrone(num) {
      this.moduleTypeIndex = num;

      if (this.droneMonitor[num]) {
        this.api.putDrone(this.droneMonitor[num].module.id, "disConnecting");
        if (this.connectionAlert[num]) {
          clearTimeout(this.connectionAlert[num]);
          this.connectionAlert[num] = null;
        }
        this.collisionInfoList = this.collisionInfoList.filter(
          (info) => info.drone_num != num
        );
        this.collisionInfoList.forEach((info) => {
          if (info.target_info.drone_num == num) {
            info.target_info.stop = true;
            info.target_info.x = null;
            info.target_info.y = null;
            info.target_info.z = null;
          }
        });
        this.droneMonitor[num].closeMqtt();
        this.droneMonitor[num] = null;
        this.droneControl[num] = null;
        this.cloudViewer.deleteTempWaypoint(num);
        this.cloudViewer.deleteDroneModel(num);
        this.cloudViewer.rectClear();
        this.droneData[num] = new DroneData();
      }
      if (this.selectedDrone[num].droneSerialNumber) {
        /**
         * 드론 모듈 시리얼 넘버 선택하자 마자
         * 선택된 드론 모듈 정보와 , 드론 index를 DroneMonitor의 Class 파일로 보낸다.
         * 그리고 droneMonitor 배열의 index에 해당 class 정보를 저장하고, mqtt 통신을 시작한다.
         * 아래에 있는 모든 토픽의 subscribe를 켜고, 드론에서 오는 메세지를 기다린다
         * 모두 구독한 후 토픽을 기다렸다가 , 토픽에 알맞은 동작(callback)을 수행한다.
         */
        // if (this.selectedDroneType?.name === "Handy") {
        //   this.isSettingModalVisible = false;
        //   this.droneMonitor[num] = new DroneMonitor(
        //     this.selectedDrone[num],
        //     num
        //   );
        //   this.api.putDrone(this.droneMonitor[num].module.id, "connecting");
        //   this.droneMonitor[num].readMsg();

        //   this.droneControl[num] = new DroneControl(
        //     this.droneMonitor[num].mqttClient,
        //     this.selectedDrone[num]
        //   );
        //   // this.cloudViewer.createDroneModel(num); 드론 모양을 three에 띄우는건데 필요 없을수도
        //   return;
        // }

        this.isSettingModalVisible = false;
        this.droneMonitor[num] = new DroneMonitor(this.selectedDrone[num], num);
        this.api.putDrone(this.droneMonitor[num].module.id, "connecting");
        this.droneMonitor[num].subConnection(this.connectionCallback);
        this.droneMonitor[num].subRtl(this.rtlCallback);
        this.droneMonitor[num].subSiriusState(this.siriusStateCallback);
        this.droneMonitor[num].subSiriusError(this.siriusErrorCallback);
        this.droneMonitor[num].subDataState(this.dataStateCallback);
        this.droneMonitor[num].subDataError(this.dataErrorCallback);
        this.droneMonitor[num].subSlamWarning(this.slamWarningCallback);
        this.droneMonitor[num].subLocalizationState(
          this.localizationStateCallback
        );
        this.droneMonitor[num].subLocalizationWarning(
          this.localizationWarningCallback
        );
        this.droneMonitor[num].subWaypointError(this.waypointErrorCallback);
        this.droneMonitor[num].subLocalPose(this.localPoseCallback);
        this.droneMonitor[num].subState(this.stateCallback);
        this.droneMonitor[num].subBattery(this.batteryCallback);
        this.droneMonitor[num].subStatusText(this.statusTextCallback);
        this.droneMonitor[num].subSensorState(this.sensorStateCallback);
        this.droneMonitor[num].subGroundSpeed(this.groundSpeedCallback);
        this.droneMonitor[num].subGpsMode(this.gpsModeCallback);
        this.droneMonitor[num].subWaypoint(this.waypointCallback);
        this.droneMonitor[num].subTempWaypoint(this.tempWaypointCallback);
        this.droneMonitor[num].subSlamState(this.slamStateCallback);
        this.droneMonitor[num].subStorage(this.storageCallback);
        this.droneMonitor[num].subDjiConnection(this.djiConnectionCallback);
        this.droneMonitor[num].subDjiModulePoseStop(
          this.djiModulePoseStopCallback
        );
        this.droneMonitor[num].subStop(this.collisionStopCallback);
        this.droneMonitor[num].readMsg();

        this.droneControl[num] = new DroneControl(
          this.droneMonitor[num].mqttClient,
          this.selectedDrone[num]
        );

        this.cloudViewer.createDroneModel(num);
      }
    },
    handleModuleChanged(selectedModule) {
      if (this.selectedDroneType.id) {
        if (selectedModule) {
          this.selectedModule = selectedModule;
        } else {
          this.selectedModule = new Drone();
        }
        this.connectModule();
      } else {
        this.selectedModule = new Drone();
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.selectModuleTypeFirst")
        );
      }
    },
    connectModule() {
      if (this.moduleMonitor) {
        this.moduleMonitor.closeMqtt();
        if (this.connectionAlert[5]) {
          clearTimeout(this.connectionAlert[5]);
          this.connectionAlert[5] = null;
        }
        this.moduleMonitor = null;
        this.moduleControl = null;
        this.api.putDrone(this.moduleMonitor.module.id, "disConnecting");
      }
      if (this.selectedModule.droneSerialNumber) {
        this.isSettingModalVisible = false;
        this.api.putDrone(this.moduleMonitor.module.id, "connecting");
        this.moduleMonitor = new DroneMonitor(this.selectedModule);
        this.moduleMonitor.subConnection(this.moduleConnectionCallback);
        this.moduleMonitor.subRtl(this.moduelRtlCallback);
        this.moduleMonitor.subSiriusState(this.moduleStateCallback);
        this.moduleMonitor.subSlamState(this.modulePoseCallback);
        this.moduleMonitor.subSiriusError(this.moduleStateErrorCallback);
        this.moduleMonitor.subDataState(this.moduleDataCallback);
        this.moduleMonitor.subDataError(this.moduleDataErrorCallback);
        this.moduleMonitor.subSlamWarning(this.moduleSlamWarningCallback);
        this.moduleMonitor.subLocalizationState(
          this.moduleLoalizationStateCallback
        );

        this.moduleMonitor.subLocalizationWarning(
          this.moduleLoalizationWarningCallback
        );
        this.moduleMonitor.readMsg();
        this.moduleControl = new DroneControl(
          this.moduleMonitor.mqttClient,
          this.selectedModule
        );
      }
    },
    hadleTakeoffChanged(height) {
      if (height) {
        this.droneParams.takeoffHeight = height;
      }
    },
    handleWaitingTimeChanged(waitingTime) {
      if (waitingTime) {
        this.droneParams.waitingTime = waitingTime;
      }
    },
    handleBatteryFailSafeChanged(batteryFailSafe) {
      if (batteryFailSafe) {
        this.droneParams.batteryFailSafe = batteryFailSafe;
      }
    },
    // handleConnectionLostChanged(connectionLost) {
    //   if (connectionLost) {
    //     this.droneParams.connectionLost = connectionLost;
    //   }
    // },
    handlePointCloudParam(type, value) {
      switch (type) {
        case "mode":
          if (value == "intensity") {
            this.pointCloudParams.mode = "intensity";
            this.cloudViewer.setUseHexColor(false);
            this.cloudViewer.material2d.uniforms.colorMap.value = false;
            this.cloudViewer.material3d.uniforms.colorMap.value = false;
          } else if (value == "height") {
            this.pointCloudParams.mode = "height";
            this.cloudViewer.setUseHexColor(false);
            this.cloudViewer.material2d.uniforms.colorMap.value = true;
            this.cloudViewer.material3d.uniforms.colorMap.value = true;
          } else if (value == "pointHexColor") {
            this.pointCloudParams.mode = "pointHexColor";
            this.cloudViewer.setUseHexColor(true);
            this.cloudViewer.material2d.uniforms.colorMap.value = false;
            this.cloudViewer.material3d.uniforms.colorMap.value = false;
          }
          break;
        case "pointSize":
          this.pointCloudParams.pointSize = parseFloat(value);
          this.cloudViewer.material2d.uniforms.size.value = value;
          this.cloudViewer.material3d.uniforms.size.value = value;
          break;
        case "opacity":
          this.pointCloudParams.opacity = parseFloat(value);
          this.cloudViewer.material2d.uniforms.opacity.value = value;
          this.cloudViewer.material3d.uniforms.opacity.value = value;
          break;
        case "heightMin":
          this.pointCloudParams.height.min = parseFloat(value);
          this.cloudViewer.material2d.uniforms.minHeight.value = value;
          this.cloudViewer.material3d.uniforms.minHeight.value = value;
          break;
        case "heightMax":
          this.pointCloudParams.height.max = parseFloat(value);
          this.cloudViewer.material2d.uniforms.maxHeight.value = value;
          this.cloudViewer.material3d.uniforms.maxHeight.value = value;
          break;
        case "pointHexColor":
          this.pointCloudParams.hexColor = value;
          this.cloudViewer.setHexColor(value);
          break;
        default:
          break;
      }
    },
    getSite() {
      if (
        !this.selectedFacility ||
        this.selectedFacility.id != this.$route.params.facilityId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.facilityId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedFacility", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.facilityId);
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.selectedFacility.mapList = mapList;
        let map = mapList.find((map) => map.id == this.$route.params.mapId);
        this.$store.commit("setSelectedFacilityMap", map);
        this.cloudViewer.loadPCD(this.selectedFacilityMap.url);
      }
    },
    handleDeleteMission(seletedMission) {
      this.api.deleteInspectionMission(
        this.deleteInspectionMissionCallback,
        this.$route.params.facilityId,
        this.$route.params.mapId,
        seletedMission.id
      );
    },
    deleteInspectionMissionCallback() {
      this.getInspectionMissions();
    },
    getInspectionMissions() {
      this.missionList = [];
      this.api.getInspectionMissions(
        this.getInspectionMissionsCallback,
        this.$route.params.facilityId,
        this.$route.params.mapId
      );
    },
     getInspectionMissionsCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let mission = new InspectionMission(result[i]);
          this.missionList.push(mission);
        }
      }
    },
    handleLoadingMission(mission) {
      this.$store.commit("setIsLoading", true);
      this.clearWaypointAll();
      this.api.getInsepctionMissionData(this.getInsepctionMissionDataCallback, mission);
    },
    getInsepctionMissionDataCallback(data){
      for(let i=0; i<5; i++){
        for(let j=0; j<data.data.waypoints[i].length; j++){
          let wp = data.data.waypoints[i][j];
          let waypoint = new Waypoint(wp.x,wp.y,wp.z,wp.yaw,wp.pitch,wp.fitType,wp.checked,wp.completed
            ,wp.groupNum, wp.mission
          );
          this.waypoints[i].push(waypoint);
        }
        for(let j=0; j<data.data.groups[i].length; j++){
          let gp = data.data.groups[i][j];
          let group = new Shape(gp.group_num, gp.shape, gp.from_wall, gp.interval);
          group.setHeighParams(gp.height_interval,gp.lower_height,gp.upper_height);
          switch (group.shape) {
            case 'Circle':
              group.setCircleInfo(gp.circle_direction,gp.circle_start_angle,gp.circle_coeffs);
              break;
            case 'Rectangle':
              group.setRectInfo(gp.rect_direction,gp.rect_coeffs);
              break;
            case 'Underside':
              group.setBottomInfo(gp.bottom_auto,gp.bottom_direction);
              break;
            case 'Line':
              group.setLineInfo(gp.line_auto,gp.line_direction);
              break;
            case 'Merged Circle':
              group.setCircleInfo(gp.circle_direction,gp.circle_start_angle,gp.circle_coeffs);
              break;
            case 'Merged Rectangle':
              group.setRectInfo(gp.rect_direction,gp.rect_coeffs);
              break;
            default:
              break;
          }
          this.groups[i].push(group);
        }
      }
      for (let i = 0; i < 5; i++) {
        this.cloudViewer.resetWaypoints(i);
      }
      this.isMissionListModalVisible = false;
      this.$store.commit("setIsLoading", false);
    },

    handleSaveMission(missionName) {
      let checked = this.missionList.find(
        (mission) => mission.name == missionName
      );
      if (checked) {
        this.$store.commit(
          "openAlert",
          this.$t("droneAlert.duplicatedMissionName")
        );
      } else {
        this.$store.commit("setIsLoading", true);
        let jsonData = {
          waypoints : this.waypoints,
          groups : this.groups
        }
        let jsonBlob = new Blob([JSON.stringify(jsonData)], { type: "application/json" });
        let jsonFile = new File([jsonBlob], "data.json", { type: "application/json" });

        // FormData 생성
        const formData = new FormData();
        formData.append("name", missionName);
        formData.append("file", jsonFile);

        this.api.postInspectionMissions(
          this.postInspectionMissionsCallback,
          this.$route.params.facilityId,
          this.$route.params.mapId,
          formData,
        );
      }
    },
    postInspectionMissionsCallback(){
      this.getInspectionMissions();
      this.isMissionSaveModalVisible = false;
      this.$store.commit("setIsLoading", false);
    },
    connectionCallback(num) {
      if (this.connectionAlert[num]) {
        clearTimeout(this.connectionAlert[num]);
        this.connectionAlert[num] = null;
      }
      this.connectionAlert[num] = setTimeout(() => {
        this.droneData[num].state.mission = false;
        this.selectedDrone[num] = new Drone();
        this.connectDrone(num);
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.moduleDisconnected", {
            droneId: num + 1,
          })}\n${this.$t("droneAlert.changeToSomethingMode", { mode: "RTL" })}`
        );
        this.moduleTypeIndex = 0;
      }, 30000);
    },
    rtlCallback(num) {
      this.droneData[num].state.mission = false;
      this.$store.commit(
        "openAlert",
        `${this.$t("droneAlert.moduleLowerBattery", {
          droneId: num + 1,
        })}\n${this.$t("droneAlert.changeToSomethingMode", { mode: "RTL" })}`
      );
    },
    siriusStateCallback(data, num) {
      if (data.state == "REQUESTING") {
        this.droneControl[num].initialize(
          this.selectedDroneType.name,
          "inspection"
        );
        this.droneControl[num].ftpDownloadInfo(this.ftpDownloadInfo);
      } else if (data.state == "DATAMANAGE") {
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = this.$t("droneAlert.droneMapSettingOnGoing", {
          droneId: num + 1,
        });
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "LOCALIZATIONCONFIG") {
        this.droneData[num].state.localization = false;
        if (num == this.currentDroneId) {
          if (this.importantMessageTimer) {
            clearTimeout(this.importantMessageTimer);
          }
          this.importantMessage = this.$t(
            "droneAlert.heightSetting3DAndDrag2D"
          );
          this.importantMessageTimer = setTimeout(() => {
            this.importantMessage = null;
          }, 10000);
          this.isPlanModalVisible = false;
          this.isMissionListModalVisible = false;
          this.isMissionSaveModalVisible = false;
          this.localization_height.max =
            this.cloudViewer.heightLimitMax.toFixed(0);
          this.localization_height.min =
            this.cloudViewer.heightLimitMin.toFixed(0);
          this.localization_height.limitMax =
            this.cloudViewer.heightLimitMax.toFixed(0);
          this.localization_height.limitMin =
            this.cloudViewer.heightLimitMin.toFixed(0);
          this.cloudViewer.setHeight(this.cloudViewer.heightLimitMax + 1);
          this.cloudViewer.setHeightRange(
            this.localization_height.min,
            this.localization_height.max
          );
          this.cloudViewer.setHeightInfoVisible(true);
        }
      } else if (data.state == "INITIALIZATION") {
        this.droneData[num].state.localization = true;
        this.cloudViewer.rectClear();
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `${this.$t("droneAlert.droneProgramOnGoing", {
          droneId: num + 1,
        })}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
        this.cloudViewer.setHeightInfoVisible(false);
      } else if (data.state == "STANBY") {
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `${this.$t(
          "droneAlert.droneProgramDoneFinalInspection",
          { droneId: num + 1 }
        )} ${this.$t("droneAlert.CheckFinalInspection")}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "FAILSAFE") {
        if (this.droneControl[num]) {
          this.droneControl[num].stop();
          this.droneData[num].state.mission = false;
        }
      }
    },

    siriusErrorCallback(data, num) {
      this.statusText.push({
        severity: 0,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    dataStateCallback(data, num) {
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    dataErrorCallback(data, num) {
      this.statusText.push({
        severity: 3,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    slamWarningCallback(data, num) {
      this.statusText.push({
        severity: 4,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    localizationStateCallback(data, num) {
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    localizationWarningCallback(data, num) {
      this.statusText.push({
        severity: 4,
        text: `[#${num + 1}] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone${num + 1} ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    waypointErrorCallback(data, num) {
      this.statusText.push({
        severity: 0,
        text: `[#${num + 1}] ${data.message}`,
      });
    },
    moduleConnectionCallback() {
      if (this.connectionAlert[5]) {
        clearTimeout(this.connectionAlert[5]);
        this.connectionAlert[5] = null;
      }
      this.connectionAlert[5] = setTimeout(() => {
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.moduleDisconnected")}\n${this.$t(
            "droneAlert.CheckCommunication"
          )}`
        );
      }, 30000);
    },
    moduelRtlCallback() {
      this.$store.commit(
        "openAlert",
        `${this.$t("droneAlert.lowerBatterCheckBatteryState")}`
      );
    },
    moduleStateCallback(data) {
      if (data.state == "REQUESTING") {
        this.moduleControl.initialize(
          this.selectedDroneType.name,
          "inspection"
        );
        this.moduleControl.ftpDownloadInfo(this.ftpDownloadInfo);
      } else if (data.state == "DATAMANAGE") {
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `${this.$t(
          "droneAlert.siriusMapSettingOnGoing"
        )}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "LOCALIZATIONCONFIG") {
        this.moduleData.state.localization = false;
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `${this.$t(
          "droneAlert.dragOn2DMakeInitialSriusArea"
        )}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 10000);
        this.isPlanModalVisible = false;
        this.isMissionListModalVisible = false;
        this.isMissionSaveModalVisible = false;
        this.localization_height.max =
          this.cloudViewer.heightLimitMax.toFixed(0);
        this.localization_height.min =
          this.cloudViewer.heightLimitMin.toFixed(0);
        this.localization_height.limitMax =
          this.cloudViewer.heightLimitMax.toFixed(0);
        this.localization_height.limitMin =
          this.cloudViewer.heightLimitMin.toFixed(0);
        this.cloudViewer.setHeight(this.cloudViewer.heightLimitMax + 1);
        this.cloudViewer.setHeightRange(
          this.localization_height.min,
          this.localization_height.max
        );
        this.cloudViewer.setHeightInfoVisible(true);
      } else if (data.state == "INITIALIZATION") {
        this.moduleData.state.localization = true;
        this.cloudViewer.rectClear();
        if (this.importantMessageTimer)
          clearTimeout(this.importantMessageTimer);
        this.importantMessage = `${this.$t("droneAlert.siriusProgramOnGoing")}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == "STANBY") {
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `${this.$t(
          "droneAlert.siriusProgramDone"
        )} ${this.$t("droneAlert.CheckFinalInspection")}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      }
    },
    localPoseCallback(data, num) {
      this.droneData[num].pose.x = data.position_x;
      this.droneData[num].pose.y = data.position_y;
      this.droneData[num].pose.z = data.position_z;
      this.droneData[num].pose.roll =
        (Math.atan2(
          2 *
            (data.orientation_w * data.orientation_x +
              data.orientation_y * data.orientation_z),
          1 -
            2 *
              (data.orientation_x * data.orientation_x +
                data.orientation_y * data.orientation_y)
        ) *
          180) /
        Math.PI;
      this.droneData[num].pose.pitch =
        (Math.asin(
          Math.max(
            -1.0,
            Math.min(
              1.0,
              2 *
                (data.orientation_w * data.orientation_y -
                  data.orientation_z * data.orientation_x)
            )
          )
        ) *
          180) /
        Math.PI;
      this.droneData[num].pose.yaw =
        (Math.atan2(
          2 *
            (data.orientation_w * data.orientation_z +
              data.orientation_x * data.orientation_y),
          1 -
            2 *
              (data.orientation_y * data.orientation_y +
                data.orientation_z * data.orientation_z)
        ) *
          180) /
        Math.PI;

      if (this.cloudViewer.droneModel2D[num]) {
        this.cloudViewer.droneModel2D[num].position.set(
          data.position_x,
          0,
          -data.position_y
        );
        this.cloudViewer.droneModel2D[num].rotation.order = "YXZ";
        this.cloudViewer.droneModel2D[num].rotation.set(
          0,
          (this.droneData[num].pose.yaw * Math.PI) / 180 - Math.PI / 2,
          0
        );
      }
      if (this.cloudViewer.droneModel3D[num]) {
        this.cloudViewer.droneModel3D[num].position.set(
          data.position_x,
          data.position_z,
          -data.position_y
        );
        this.cloudViewer.droneModel3D[num].rotation.order = "YXZ";
        this.cloudViewer.droneModel3D[num].rotation.set(
          (-this.droneData[num].pose.pitch * Math.PI) / 180,
          (this.droneData[num].pose.yaw * Math.PI) / 180 - Math.PI / 2,
          (-this.droneData[num].pose.roll * Math.PI) / 180,
        );
      }
      if (
        this.droneData[num].state.takeOff &&
        (this.droneData[num].state.mission ||
          this.droneData[num].state.rtl ||
          this.droneData[num].state.rth)
      ) {
        if (this.droneData[num].state.collision) {
          this.checkCollisionState(num);
        } else {
          this.checkDroneCollisions(num);
        }
      }
    },
    checkDroneCollisions(num) {
      for (let i = 0; i < 5; i++) {
        if (
          num != i &&
          this.droneMonitor[i] &&
          this.droneData[i].state.takeOff
        ) {
          let distance =
            (this.droneData[num].pose.x - this.droneData[i].pose.x) ** 2 +
            (this.droneData[num].pose.y - this.droneData[i].pose.y) ** 2 +
            (this.droneData[num].pose.z - this.droneData[i].pose.z) ** 2;
          if (distance <= 25) {
            this.collisionInfoList = this.collisionInfoList.filter(
              (info) => info.drone_num != num
            );
            let collisionInfo = new CollisionInfo();
            collisionInfo.drone_num = num;
            collisionInfo.target_info.drone_num = i;
            collisionInfo.target_info.x = this.droneData[i].pose.x;
            collisionInfo.target_info.y = this.droneData[i].pose.y;
            collisionInfo.target_info.z = this.droneData[i].pose.z;
            if (!this.droneData[i].state.mission) {
              collisionInfo.target_info.stop = true;
            }
            this.collisionInfoList.push(collisionInfo);
            this.droneControl[num].stop();
            this.droneData[num].state.mission = false;
            this.droneData[num].state.collision = true;
          }
        }
      }
    },
    collisionStopCallback(num) {
      this.collisionInfoList.forEach((info) => {
        if (info.drone_num == num) {
          info.stop = true;
          info.x = this.droneData[num].pose.x;
          info.y = this.droneData[num].pose.y;
          info.z = this.droneData[num].pose.z;
        }
        if (info.target_info.drone_num == num) {
          info.target_info.stop = true;
          info.target_info.x = this.droneData[num].pose.x;
          info.target_info.y = this.droneData[num].pose.y;
          info.target_info.z = this.droneData[num].pose.z;
        }
      });
      this.collisionRestart();
    },
    collisionRestart() {
      let lastInfo = this.collisionInfoList[this.collisionInfoList.length - 1];
      if (lastInfo) {
        if (lastInfo.stop && lastInfo.target_info.stop && !lastInfo.retart) {
          lastInfo.restart = true;
          let num = lastInfo.drone_num;
          let collisionInfoList = this.collisionInfoList.filter(
            (info) =>
              info.drone_num != num &&
              info.drone_num != lastInfo.target_info.drone_num
          );
          let targetInfo = new CollisionInfo();
          targetInfo.drone_num = lastInfo.target_info.drone_num;
          targetInfo.stop = lastInfo.target_info.stop;
          targetInfo.x = lastInfo.target_info.x;
          targetInfo.y = lastInfo.target_info.y;
          targetInfo.z = lastInfo.target_info.z;
          if (targetInfo.x !== undefined || targetInfo.x !== null) {
            collisionInfoList.push(targetInfo);
          }
          if (this.droneData[num].state.rth) {
            this.droneControl[num].setWaypoint(
              0,
              0,
              this.droneParams.takeoffHeight,
              this.droneData[num].armPosition.yaw,
              0,
              "rth",
              collisionInfoList
            );
          } else if (this.droneData[num].state.rtl) {
            this.droneControl[this.currentDroneId].setWaypoint(
              this.droneData[this.currentDroneId].armPosition.x,
              this.droneData[this.currentDroneId].armPosition.y,
              this.droneParams.takeoffHeight,
              this.droneData[this.currentDroneId].armPosition.yaw,
              0,
              "rtl",
              collisionInfoList
            );
          } else {
            this.droneData[num].state.mission = true;
            this.droneControl[num].setWaypoint(
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .x,
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .y,
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .z,
              this.waypoints[num][
                this.droneData[num].state.waypointNumber - 1
              ].getYawDegree(),
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .pitch,
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .fitType,
              collisionInfoList
            );
            if (
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .mission
            ) {
              this.droneControl[num].mission(
                this.waypoints[num][
                  this.droneData[num].state.waypointNumber - 1
                ].mission.gimbalPitch,
                this.waypoints[num][
                  this.droneData[num].state.waypointNumber - 1
                ].mission.shouldCapture
              );
            }
          }
        }
      }
    },
    checkCollisionState(num) {
      let findTarget = this.collisionInfoList.find(
        (info) => info.drone_num == num
      ).target_info;
      if (findTarget) {
        if (findTarget.drone_num != undefined) {
          let distance =
            (this.droneData[num].pose.x -
              this.droneData[findTarget.drone_num].pose.x) **
              2 +
            (this.droneData[num].pose.y -
              this.droneData[findTarget.drone_num].pose.y) **
              2 +
            (this.droneData[num].pose.z -
              this.droneData[findTarget.drone_num].pose.z) **
              2;
          if (distance >= 36) {
            this.droneData[num].state.collision = false;
            this.collisionInfoList = this.collisionInfoList.filter(
              (info) => info.drone_num != num
            );
            this.collisionInfoList.forEach((info) => {
              if (info.target_info.drone_num == num) {
                info.target_info.drone_num = null;
                info.target_info.stop = true;
                info.target_info.x = null;
                info.target_info.y = null;
                info.target_info.z = null;
              }
            });
            this.collisionRestart();
          }
        } else {
          this.droneData[num].state.collision = false;
          this.collisionInfoList = this.collisionInfoList.filter(
            (info) => info.drone_num != num
          );
          this.collisionInfoList.forEach((info) => {
            if (info.target_info.drone_num == num) {
              info.target_info.drone_num = null;
              info.target_info.stop = true;
              info.target_info.x = null;
              info.target_info.y = null;
              info.target_info.z = null;
            }
          });
          this.collisionRestart();
        }
      }
    },
    stateCallback(data, num) {
      this.droneData[num].fcu = Boolean(data.connection);
      this.droneData[num].flightMode = data.mode;
      this.droneData[num].state.arming = Boolean(data.arming);
      if (this.droneData[num].state.arming) {
        if (!this.droneData[num].timeCheck) {
          this.droneData[num].armingTime = new Date();
          this.droneData[num].timeCheck = true;
        } else {
          let nowTime = new Date();
          let differenceInMilliseconds =
            nowTime - this.droneData[num].armingTime;
          let hours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60);
          let minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
          let seconds = Math.floor((differenceInMilliseconds / 1000) % 60);
          this.droneData[num].flightTime.h = String(hours).padStart(2, "0");
          this.droneData[num].flightTime.m = String(minutes).padStart(2, "0");
          this.droneData[num].flightTime.s = String(seconds).padStart(2, "0");
        }
      } else {
        this.droneData[num].timeCheck = false;
        if (this.droneData[num].state.rtl)
          this.droneData[num].state.rtl = false;
        if (this.droneData[num].state.rth)
          this.droneData[num].state.rth = false;
        if (this.droneData[num].state.mission)
          this.droneData[num].state.mission = false;
      }
      if (data.system_status == 3) {
        this.droneData[num].state.takeOff = false;
      } else if (data.system_status == 4) {
        this.droneData[num].state.takeOff = true;
      }
    },
    batteryCallback(data, num) {
      if (data.voltage) {
        this.droneData[num].battery = (data.voltage/100).toFixed(2);
      }
    },
    statusTextCallback(data, num) {
      if (
        data.severity == 0 ||
        data.severity == 1 ||
        data.severity == 2 ||
        data.severity == 3
      ) {
        // this.api.postDroneDataLog(this.droneMonitor[num].clientId, data, formatISO(new Date()));
      }
      this.statusText.push({
        severity: data.severity,
        text: `[#${num + 1}] ${data.text}`,
      });
    },
    sensorStateCallback(data, num) {
      this.droneData[num].sensorState.lidar = Boolean(data.lidar_connection);
      this.droneData[num].sensorState.imu = Boolean(data.imu_connection);
      this.droneData[num].sensorState.encoder = Boolean(
        data.encoder_connection
      );
    },
    groundSpeedCallback(data, num) {
      this.droneData[num].speed = data.ground_speed.toFixed(2);
    },
    gpsModeCallback(data, num) {
      this.droneData[num].gpsMode = Boolean(data.gps_mode);
    },
    waypointCallback(data, num) {
      if (this.droneData[num].state.mission) {
        if (this.waypoints[num][data.waypoint_number - 1]) {
          if (
            this.droneData[num].state.waypointNumber != data.waypoint_number
          ) {
            this.droneData[num].state.waypointNumber = data.waypoint_number;
            this.handleWaypointNumber(
              num,
              this.droneData[num].state.waypointNumber
            );
          }
          this.droneControl[num].setWaypoint(
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1].x,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1].y,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1].z,
            this.waypoints[num][
              this.droneData[num].state.waypointNumber - 1
            ].getYawDegree(),
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .pitch,
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .fitType
          );
          if (
            this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
              .mission
          ) {
            this.droneControl[num].mission(
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .mission.gimbalPitch,
              this.waypoints[num][this.droneData[num].state.waypointNumber - 1]
                .mission.shouldCapture
            );
          }
        } else {
          this.droneControl[num].stop();
          this.droneData[num].state.mission = false;
          this.handleWaypointNumber(num, 0);
          this.$store.commit("openAlert", this.$t("droneAlert.missionEnded"));
        }
        this.cloudViewer.deleteTempWaypoint(num);
      }
      // else if(this.droneData[num].state.rtl){
      //   this.droneControl[num].land();
      // }
      // else if(this.droneData[num].state.rth){
      //   this.droneControl[num].land();
      // }
    },
    tempWaypointCallback(data, num) {
      // if(this.droneData[num].state.mission){
      // }
      this.cloudViewer.deleteTempWaypoint(num);
      let paths = data.paths;
      this.cloudViewer.drawTempWaypoint(paths, num);
    },
    slamStateCallback(data, num) {
      if (this.selectedDroneType?.name === "Handy") {
        this.droneData[num].pose.x = data.slam_position_x.toFixed(3);
        this.droneData[num].pose.y = data.slam_position_y.toFixed(3);
        this.droneData[num].pose.roll =
          (Math.atan2(
            2 *
              (data.slam_orientation_w * data.slam_orientation_x +
                data.slam_orientation_y * data.slam_orientation_z),
            1 -
              2 *
                (data.slam_orientation_x * data.slam_orientation_x +
                  data.slam_orientation_y * data.slam_orientation_y)
          ) *
            180) /
          Math.PI;
        this.droneData[num].pose.pitch =
          (Math.asin(
            Math.max(
              -1.0,
              Math.min(
                1.0,
                2 *
                  (data.slam_orientation_w * data.slam_orientation_y -
                    data.slam_orientation_z * data.slam_orientation_x)
              )
            )
          ) *
            180) /
          Math.PI;
        this.droneData[num].pose.yaw =
          (Math.atan2(
            2 *
              (data.slam_orientation_w * data.slam_orientation_z +
                data.slam_orientation_x * data.slam_orientation_y),
            1 -
              2 *
                (data.slam_orientation_y * data.slam_orientation_y +
                  data.slam_orientation_z * data.slam_orientation_z)
          ) *
            180) /
          Math.PI;

        if (this.cloudViewer.droneModel2D[num]) {
          this.cloudViewer.droneModel2D[num].position.set(
            data.slam_position_x,
            data.slam_position_z,
            -data.slam_position_y
          );
          this.cloudViewer.droneModel3D[num].position.set(
            data.slam_position_x,
            data.slam_position_z,
            -data.slam_position_y
          );

          this.cloudViewer.droneModel2D[num].rotation.order = "YXZ";
          this.cloudViewer.droneModel3D[num].rotation.order = "YXZ";

          this.cloudViewer.droneModel2D[num].rotation.set(
            (-this.droneData[num].pose.pitch * Math.PI) / 180,
            (this.droneData[num].pose.yaw * Math.PI) / 180 - Math.PI / 2,
            0
          );
          this.cloudViewer.droneModel3D[num].rotation.set(
            (-this.droneData[num].pose.pitch * Math.PI) / 180,
            (this.droneData[num].pose.yaw * Math.PI) / 180 - Math.PI / 2,
            0
          );
        }
      }

      this.droneData[num].slamState = Boolean(data.slam_state);
    },
    modulePoseCallback(data) {
      let roll = Math.atan2(
        2 *
          (data.slam_orientation_w * data.slam_orientation_x +
            data.slam_orientation_y * data.slam_orientation_z),
        1 -
          2 *
            (data.slam_orientation_x * data.slam_orientation_x +
              data.slam_orientation_y * data.slam_orientation_y)
      );
      let pitch = Math.asin(
        Math.max(
          -1.0,
          Math.min(
            1.0,
            2 *
              (data.slam_orientation_w * data.slam_orientation_y -
                data.slam_orientation_z * data.slam_orientation_x)
          )
        )
      );
      let yaw = Math.atan2(
        2 *
          (data.slam_orientation_w * data.slam_orientation_z +
            data.slam_orientation_x * data.slam_orientation_y),
        1 -
          2 *
            (data.slam_orientation_y * data.slam_orientation_y +
              data.slam_orientation_z * data.slam_orientation_z)
      );
      data.slam_roll = roll;
      data.slam_pitch = pitch;
      data.slam_yaw = yaw;
      for (let i = 0; i < 5; i++) {
        if (
          this.droneControl[i] &&
          this.droneData[i].state.djiConnection !== undefined
        ) {
          if (this.droneData[i].state.djiConnection) {
            this.droneControl[i].modulePose(JSON.stringify(data));
          }
        }
      }
    },
    moduleStateErrorCallback(data) {
      this.statusText.push({
        severity: 0,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleDataCallback(data) {
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleDataErrorCallback(data) {
      this.statusText.push({
        severity: 3,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleSlamWarningCallback(data) {
      this.statusText.push({
        severity: 4,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleLoalizationStateCallback(data) {
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    moduleLoalizationWarningCallback(data) {
      this.statusText.push({
        severity: 4,
        text: `[SIRIUS] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `[SIRIUS] ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    storageCallback(data, num) {
      this.droneData[num].storage = (
        (data.used_storage / data.total_storage) *
        100
      ).toFixed(1);
    },
    djiConnectionCallback(data, num) {
      if (data.dji_connection) {
        this.droneData[num].state.djiConnection = true;
      }
    },
    djiModulePoseStopCallback(data, num) {
      if (data.dji_initpose) {
        this.droneData[num].state.djiConnection = false;
      }
    },
    getDrones() {
      this.api.getDrones(this.getDronesCallback, this.user);
    },
    getDronesCallback(data) {
      let result = data.data.result;
      this.droneList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneList.push(new Drone(result[i]));
      }
    },
    getDroneTypes() {
      this.api.getDronesType(this.getDronesTypeCallback);
    },
    getDronesTypeCallback(data) {
      let result = data.data.result;
      this.droneTypeList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneTypeList.push(new DroneType(result[i]));
      }
    },
    ftpCredentialsCallback(data) {
      this.ftpDownloadInfo = JSON.stringify(data.data.result);
    },
    cleanUp() {
      if (this.cloudViewer) {
        this.cloudViewer.dispose();
        this.cloudViewer = null;
      }
      this.waypoints = [[], [], [], [], []];
      this.groups = [[], [], [], [], []];
      if (this.webSoketHandler) {
        if (this.parsingCompletedListener)
          this.webSoketHandler.off(
            "parsingCompleted",
            this.parsingCompletedListener
          );
        if (this.setCollidedWPListener)
          this.webSoketHandler.off("setCollidedWP", this.setCollidedWPListener);
        this.webSoketHandler.programClose();
      }
      for (let i = 0; i < 5; i++) {
        if (this.droneMonitor[i]) {
          this.api.putDrone(this.droneMonitor[i].module.id, "disConnecting");
          this.droneMonitor[i].closeMqtt();
          this.droneMonitor[i] = null;
        }
        if (this.droneControl[i]) this.droneControl[i] = null;
      }
      if (this.moduleMonitor) {
        this.moduleMonitor.closeMqtt();
        this.moduleMonitor = null;
        this.api.putDrone(this.moduleMonitor.module.id, "disConnecting");
      }
      if (this.moduleControl) this.moduleControl = null;
      for (let i = 0; i < 6; i++) {
        if (this.connectionAlert[i]) {
          clearTimeout(this.connectionAlert[i]);
          this.connectionAlert[i] = null;
        }
      }
      if (this.importantMessage) {
        clearTimeout(this.importantMessage);
        this.importantMessage = null;
      }
    },
    handleLocalizationHeight(event, type) {
      let value = parseFloat(parseFloat(event.target.value).toFixed(2));
      if (event.target.value) {
        switch (type) {
          case "min":
            if (value > this.localization_height.limitMax) {
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.unableToEnterGreaterNumber", {
                  limit: this.localization_height.limitMax,
                })}`
              );
              this.localization_height.min = this.localization_height.limitMax;
              this.localization_height.max = this.localization_height.limitMax;
            } else if (value < this.localization_height.limitMin) {
              this.localization_height.min = this.localization_height.limitMin;
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.unableToEnterLowerNumber", {
                  limit: this.localization_height.limitMin,
                })}`
              );
            } else {
              if (value > this.localization_height.max) {
                this.localization_height.min = value;
                this.localization_height.max = value;
              } else this.localization_height.min = value;
            }
            break;
          case "max":
            if (value > this.localization_height.limitMax) {
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.unableToEnterGreaterNumber", {
                  limit: this.localization_height.limitMax,
                })}`
              );
              this.localization_height.max = this.localization_height.limitMax;
            } else if (value < this.localization_height.limitMin) {
              this.localization_height.max = this.localization_height.limitMin;
              this.localization_height.min = this.localization_height.limitMin;
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.unableToEnterLowerNumber", {
                  limit: this.localization_height.limitMin,
                })}`
              );
            } else {
              if (value < this.localization_height.min) {
                this.localization_height.min = value;
                this.localization_height.max = value;
              } else this.localization_height.max = value;
            }
            break;
          default:
            break;
        }
        this.cloudViewer.setHeight(this.cloudViewer.heightLimitMax + 1);
        this.cloudViewer.setHeightRange(
          this.localization_height.min,
          this.localization_height.max
        );
      }
      event.target.value = null;
    },
    /**
     * 핸디 촬영 모드 변경 -> 상태는 무조건 off
     * singleShot/intervalShot + off publish 보내기
     */
    handleChangeHandyShootingMode(mode) {
      if (mode === "start") {
        // 촬영 모드 킨 상태면 -> 싱글샷 off publish하고 일단 handyShootingMode도 싱글샷으로 변경
        this.handyShootingMode = "singleShot";
        this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
          "singleShot",
          "off"
        );
        return;
      }

      this.handyShootingMode = mode;
      this.handyIntervalShootingOn = false; // 인터벌 촬영 일단 중지시킴

      if (mode === "singleShot") {
        this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
          "intervalShot",
          "off"
        );
        this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
          "singleShot",
          "off"
        );
      } else {
        // 연속 촬영 선택시에는 무조건 상태는 off로 만든다.
        this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
          mode,
          "off"
        );
      }
    },
    /**
     * 핸디 촬영 상태 설정하기
     * singleShot/intervalShot + on/off publish 보내기
     */
    handleShootingStatus(status) {
      let vid = document.getElementById("camera");

      console.log("컨펌 취소했을 때 찍히는 코드일걸 아마");
      vid.addEventListener("play", function () {
        console.log("The video has started to play");
      });

      vid.addEventListener("pause", function () {
        console.log("The video has paused");
      });

      if (this.handyShootingMode === "singleShot") {
        if (status === "shootingStart") {
          this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
            this.handyShootingMode,
            "on"
          );
        }
      } else {
        if (status === "shootingStart") {
          this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
            this.handyShootingMode,
            "on"
          );
        } else {
          this.droneControl[this.moduleTypeIndex].sendHandyShootingTypeStatus(
            this.handyShootingMode,
            "off"
          );
        }
      }
    },
    /**
     * 핸디 주/야 설정
     */
    handleSendHandyDayNightTime(time) {
      if (
        this.handyShootingMode === null ||
        this.handyShootingMode === undefined ||
        this.handyShootingMode === ""
      ) {
        // 핸디 촬영 모드 선택 안되었을 때
        return this.$store.commit(
          "openAlert",
          this.$t("droneAlert.pleaseSelectShootingModeOfHandy")
        );
      }

      this.handyDayNightTime = time;

      if (this.handyDayNightTime === "start") {
        // 주야간 모드를 막 켠 상태이면 -> 주간모드 publish하고 일단 handyDayNightTime 주간모드로 변경
        this.handyDayNightTime = "dayTime";
        this.droneControl[this.moduleTypeIndex].sendHandyDayNightTime(400);
        return;
      }
      if (this.handyDayNightTime === "dayTime") {
        this.droneControl[this.moduleTypeIndex].sendHandyDayNightTime(400);
      } else {
        this.droneControl[this.moduleTypeIndex].sendHandyDayNightTime(800);
      }
    },
    /**
     * 조이스틱 nipple.js 리턴 객체값 변수에 저장하고 publish 보내기
     */
    handleJoyStickReturnValue(data) {
      // joyStick을 움직였을 때에만 동작함
      if (this.droneControl[this.moduleTypeIndex]) {
        // return value 일단 변수에 저장
        this.joyStickReturnValue = data;

        if (
          this.joyStickReturnValue.distance ===
            this.joyStickCustomOption.size / 2 &&
          this.isJoyStickTouchActive
        ) {
          // 반지름만큼 움직였고, 터치 상태일 때
          this.handleUpdateJoyStickPositionWhenTouched();
        }

        if (
          this.joyStickReturnValue.distance !==
          this.joyStickCustomOption.size / 2
        ) {
          if (this.joyStickInterval) {
            clearInterval(this.joyStickInterval);
            this.joyStickInterval = null;
          }

          this.handyGimbalYaw +=
            this.joyStickReturnValue.vector.x * this.joyStickSensitivity;

          this.handyGimbalPitch +=
            this.joyStickReturnValue.vector.y * this.joyStickSensitivity;

          if (this.handyGimbalYaw > this.yawMax) {
            // yaw max 값 보다 커?
            this.handyGimbalYaw = this.yawMax;
          }

          if (this.handyGimbalYaw < this.yawMin) {
            // yaw min 값 보다 작아?
            this.handyGimbalYaw = this.yawMin;
          }

          if (this.handyGimbalPitch > this.pitchMax) {
            // pitchs max 값 보다 커?
            this.handyGimbalPitch = this.pitchMax;
          }

          if (this.handyGimbalPitch < this.pitchMin) {
            // pitchs min 값 보다 작아?
            this.handyGimbalPitch = this.pitchMin;
          }

          // vector값을 기존 값에 계속 더한 뒤 publish
          this.droneControl[this.moduleTypeIndex].sendHandyGimbalDegree(
            this.handyGimbalYaw,
            this.handyGimbalPitch
          );
          console.log(this.joyStickReturnValue);
        }
        return;
      }
      return this.$store.commit(
        "openAlert",
        this.$t("droneAlert.pleaseSetDroneTypeAndId")
      );
    },
    handleChangeIntervalShootingState(boolean) {
      this.handyIntervalShootingOn = boolean;
    },
    /**
     * 조이스틱 터치 끝났을 때
     */
    handleJoyStickEndMethod() {
      this.isJoyStickTouchActive = false;
      if (this.joyStickInterval) {
        clearInterval(this.joyStickInterval);
        this.joyStickInterval = null;
      }
    },
    handleJoyStickStartMethod() {
      this.isJoyStickTouchActive = true;
    },
    /**
     * 조이 스틱이 반지름 만큼 움직였을 경우 함수
     *
     */
    handleUpdateJoyStickPositionWhenTouched() {
      // 기존 타이머가 있으면 먼저 정리
      if (this.joyStickInterval) {
        clearInterval(this.joyStickInterval);
        this.joyStickInterval = null;
      }
      // 조이스틱 리턴값의 distance가 반지름과 같고 터치 상태라면?
      this.joyStickInterval = setInterval(() => {
        if (this.joyStickDir === "all") {
          this.handyGimbalYaw +=
            Math.cos((this.joyStickReturnValue.angle.degree * Math.PI) / 180) *
            this.joyStickReturnValue.force *
            this.joyStickSensitivity;

          this.handyGimbalPitch +=
            Math.sin((this.joyStickReturnValue.angle.degree * Math.PI) / 180) *
            this.joyStickReturnValue.force *
            this.joyStickSensitivity;
        } else if (this.joyStickDir === "yaw") {
          this.handyGimbalYaw +=
            Math.cos((this.joyStickReturnValue.angle.degree * Math.PI) / 180) *
            this.joyStickReturnValue.force *
            this.joyStickSensitivity;
        } else if (this.joyStickDir === "pitch") {
          this.handyGimbalPitch +=
            Math.sin((this.joyStickReturnValue.angle.degree * Math.PI) / 180) *
            this.joyStickReturnValue.force *
            this.joyStickSensitivity;
        }

        if (this.handyGimbalYaw > this.yawMax) {
          // yaw max 값 보다 커?
          this.handyGimbalYaw = this.yawMax;
        }

        if (this.handyGimbalYaw < this.yawMin) {
          // yaw min 값 보다 작아?
          this.handyGimbalYaw = this.yawMin;
        }

        if (this.handyGimbalPitch > this.pitchMax) {
          // pitchs max 값 보다 커?
          this.handyGimbalPitch = this.pitchMax;
        }

        if (this.handyGimbalPitch < this.pitchMin) {
          // pitchs min 값 보다 작아?
          this.handyGimbalPitch = this.pitchMin;
        }

        // vector값을 기존 값에 계속 더한 뒤 publish
        this.droneControl[this.moduleTypeIndex].sendHandyGimbalDegree(
          this.handyGimbalYaw,
          this.handyGimbalPitch
        );
      }, 10);
    },
    /**
     * joystick 위치 0으로 리셋하기
     */
    handleResetJoyStickData() {
      if (this.droneControl[this.moduleTypeIndex]) {
        // Gimbal 값을 초기화
        this.handyGimbalYaw = 0; // 정면으로 Yaw 설정
        this.handyGimbalPitch = 0; // 정면으로 Pitch 설정

        // 초기화 값을 publish
        this.droneControl[this.moduleTypeIndex].sendHandyGimbalDegree(
          this.handyGimbalYaw,
          this.handyGimbalPitch
        );
        return;
      }
      return this.$store.commit(
        "openAlert",
        this.$t("droneAlert.pleaseSetDroneTypeAndId")
      );
    },
    /**
     * 조이스틱 방향 변경
     */
    handleChangeJoyStickDir() {
      if (this.joyStickDir === "all") {
        this.joyStickDir = "yaw";
      } else if (this.joyStickDir === "yaw") {
        this.joyStickDir = "pitch";
      } else {
        this.joyStickDir = "all";
      }
    },
    /**
     * 감도 직접 정할 수 있도록
     */
    handleChangeInputValue(sensitivity) {
      if (this.droneControl[this.moduleTypeIndex]) {
        this.joyStickSensitivity = sensitivity;
        return;
      }
      return this.$store.commit(
        "openAlert",
        this.$t("droneAlert.pleaseSetDroneTypeAndId")
      );
    },
    /**
     * 1. 장치 리스트를 가져오는 함수
     */
    fetchVideoDeviceList() {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true }) // 권한 요청
        .then(() => navigator.mediaDevices.enumerateDevices()) // 장치 목록 가져오기
        .then((devices) => {
          this.videoDeviceList = devices.filter(
            (device) => device.kind === "videoinput"
          );

          if (this.videoDeviceList.length <= 0) {
            this.hasVideoSignal = false;
          } else {
            console.log("비디오 장치 목록:", this.videoDeviceList);
          }
        })
        .catch((error) => {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.ErrorGetDeviceList")}: ${error}`
          );
          console.error("장치 리스트 가져오기 오류:", error);
        });
    },

    /**
     * 2. 장치를 선택하는 함수
     */
    selectVideoDevice(deviceEl) {
      this.selectedVideoInputElement = deviceEl;

      if (!this.selectedVideoInputElement) {
        this.hasVideoSignal = false;
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.SelectedDeviceNotFound")}`
        );
        console.error("선택한 장치를 찾을 수 없습니다.");
        return;
      }

      this.connectVideoStream();
      this.isVideoSelectModalOpen = false;

      console.log("선택된 비디오 장치:", this.selectedVideoInputElement);
    },
    /**
     * 3. 선택된 장치로 비디오 스트림 연결
     */
    connectVideoStream() {
      if (!this.selectedVideoInputElement) {
        this.hasVideoSignal = false;
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.NoVideoDevicesSelected")}`
        );
        console.error("선택된 비디오 장치가 없습니다.");
        return;
      }

      navigator.mediaDevices
        .getUserMedia({
          video: { deviceId: this.selectedVideoInputElement.deviceId },
        })
        .then((stream) => {
          const videoElement = document.getElementById("camera");
          videoElement.srcObject = stream; // 비디오 요소에 스트림 연결
          this.hasVideoSignal = true;
          console.log("비디오 스트림 연결 성공");
        })
        .catch((error) => {
          this.hasVideoSignal = false;
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.ErrorConnectingVideoStream")}: ${error}`
          );
          console.error("비디오 스트림 연결 중 오류 발생:", error);
        });
    },
    /**
     * 4. 카메라 연결 해제
     */
    handleDisconnectLiveVideo() {
      const videoElement = document.getElementById("camera");
      const stream = videoElement.srcObject;

      if (!this.selectedVideoInputElement) {
        this.hasVideoSignal = false;
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.NoDeviceToRelease")}`
        );
        console.error("해제할 비디오 장치가 없습니다.");
        return;
      }

      if (stream) {
        // 스트림의 모든 트랙을 정지
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());

        // 비디오 요소의 스트림 연결 해제
        videoElement.srcObject = null;

        this.hasVideoSignal = false;
        console.log("비디오 스트림 연결 해제 성공");
      } else {
        this.hasVideoSignal = false;
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.NoDeviceToRelease")}`
        );
        console.error("해제할 비디오 장치가 없습니다.");
        return;
      }
    },
    /**
     * 비디오 사이즈 키워주기
     */
    makeVideoBigger() {
      this.isVideoWideMode = !this.isVideoWideMode;
    },
    /**
     * 카메라 변경 모달 띄우기
     */
    handleToggleChangeCameraModal(boolean) {
      this.isVideoSelectModalOpen = boolean;
      this.fetchVideoDeviceList(); // 연결된 장치 리스트를 뽑는다.
    },
    handleShowLiveVideoSelectModal() {},
    handleHideLiveVideoSelectModal() {},
  },
  created() {
    this.$store.dispatch("checkDeviceType");
  },
  mounted() {
    let ref = this.isMobile
      ? this.$refs.cloud_viewer_mobile_2d
      : this.$refs.cloud_viewer_2d;
    this.cloudViewer = markRaw(
      new PCReportViewer(
        ref,
        this.$refs.cloud_viewer_3d,
        this.pointCloudParams,
        this.$refs.mainLoading,
        this.isMobile,
        this.waypoints
      )
    );

    this.webSoketHandler = markRaw(
      new WebSoketHandler(
        this.apiIp,
        this.apiPort,
        this.user,
        this.waypoints,
        this.groups
      )
    );
    this.parsingCompletedListener = () => {
      this.messageParsingFinished();
      this.$store.commit("setIsLoading", false);
    };
    this.webSoketHandler.on("parsingCompleted", this.parsingCompletedListener);
    this.webSoketHandler.on("waypointNull", () => {
      this.$store.commit("setIsLoading", false);
      this.$store.commit(
        "openAlert",
        this.$t("droneAlert.failToMakeAutomaticPathMakeItClear")
      );
    });
    this.setCollidedWPListener = () => {
      this.setCollidedWaypoints();
      this.$store.commit("setIsLoading", false);
    };
    this.webSoketHandler.on("setCollidedWP", this.setCollidedWPListener);
    this.webSoketHandler.on("noCollision", () => {
      this.$store.commit("setIsLoading", false);
      this.$store.commit("openAlert", this.$t("droneAlert.noCrashedWayPoint"));
    });
    this.groupHandler = new GroupHandler(this.waypoints, this.groups);
    this.api.putUser({ taskStatus: "inspecting" }, this.user.id);
    this.getSite();
    this.api.startFittingProgram(
      this.$route.params.facilityId,
      this.$route.params.mapId,
      this.apiPort,
      this.user.id
    );
    this.getDroneTypes();
    this.getDrones();
    this.getInspectionMissions();
    this.api.ftpCredentials(
      this.ftpCredentialsCallback,
      this.$route.params.facilityId,
      this.$route.params.mapId
    );
    window.addEventListener("beforeunload", () => {
      for (let i = 0; i < 5; i++) {
        if (this.droneMonitor[i])
          this.api.putDrone(this.droneMonitor[i].module.id, "disConnecting");
      }
    });
  },
  beforeUnmount() {
    this.cleanUp();
  },
};
</script>

<style lang="scss">
.main {
  display: flex;
  align-items: flex-start;
}

.cloud_viewer_3d {
  position: relative;
  width: 50%;
  height: 100%;
}

.cloud_viewer_2d {
  position: relative;
  width: 50%;
  height: 100%;
}

.inspection_view_label {
  position: fixed;
  bottom: 10px;
  right: 20px;
  height: 30px;
  width: auto;
  padding: 5px;
  @include flexbox;
  font-size: 1.6rem;
}

.inspection_drone_selection_container {
  position: fixed;
  top: 80px;
  left: calc(100% / 2 + 10px);
  height: 30px;
  width: 110px;
  background-color: rgb(23, 23, 23);
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  cursor: pointer;
}

.inspection_selected_drone {
  margin-left: 10px;
  color: $main_white;
  font-size: 1.6rem;
}

.isnpection_select_drone_image {
  width: 30px;
  height: 30px;
  margin-left: auto;
  background-image: url("@/../public/images/chevronDown.svg");
  background-size: 20px, 20px;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: transparent; */
}

.isnpection_select_drone_image:hover {
  background-image: url("@/../public/images/chevronDown_hover.svg");
}
.inspection_drone_localization_apply_button {
  @include flexbox;
  position: fixed;
  top: 80px;
  left: calc(100% / 2 + 130px);
  height: 30px;
  width: 80px;
  background-color: rgb(23, 23, 23);
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  margin-left: 10px;
  color: $main_white;
  font-size: 1.6rem;
}
.inspection_drone_localization_apply_button:hover {
  background: $main_color;
  color: black;
}
.inspection_select_drone_modal {
  position: fixed;
  top: 120px;
  left: calc(100% / 2 + 10px);
  height: 150px;
  width: 110px;
  background-color: rgb(23, 23, 23);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
}

.inspection_drone_item {
  height: 30px;
  width: 90px;
  border-bottom: 1px solid rgba(1, 250, 254, 0.5);
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 1.6rem;
  color: $main_white;
}

.inspection_drone_item:hover {
  color: rgba(1, 250, 254, 0.5);
}

.last {
  border-bottom: 0px solid rgba(1, 250, 254, 0.5);
}

#handy_controller {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 300px;
  @include flexbox(center, center, row);
  gap: 600px;

  &.wide {
    gap: 700px;

    #VideoBoxContainer {
      max-width: 600px;
    }
  }
}

#VideoBoxContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 400px;
  width: 100%;
  height: auto;
  background: gray;

  .video_box {
    position: relative;
    padding-top: 56.25%;
    width: 100%;

    .signal_text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px 28px;
      background: $main_black;
      color: $main_white;
      font-size: 1.6rem;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .handyLiveVideoCamera_select_button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

// joystick custom css
.joystick_wrap {
  position: relative;
}

#joystick_container {
  .front {
    background: url("@/../public/images/joystick_front.svg") no-repeat center !important;
    background-size: contain !important;
    opacity: 1 !important;
  }

  &.all {
    .back {
      background: url("@/../public/images/joystick_back.svg") no-repeat center !important;
      background-size: contain !important;
      opacity: 1 !important;
    }
  }
  &.yaw {
    .back {
      background: url("@/../public/images/joystick_back_pink.svg") no-repeat
        center !important;
      background-size: contain !important;
      opacity: 1 !important;
    }
  }
  &.pitch {
    .back {
      background: url("@/../public/images/joystick_back_yellow.svg") no-repeat
        center !important;
      background-size: contain !important;
      opacity: 1 !important;
    }
  }
}

// range selector
.rangeSelector {
  width: 100%;
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
