<template>
  <div class="report_detail" v-show="reportDetails">
    <div class="report_detail_img_wrap">
      <div class="report_detail_canvas_wrap" ref="report_detail_canvas_wrap">
        <canvas
          class="report_detail_img"
          ref="report_detail_img"
          v-show="imageState"
        ></canvas>
        <canvas
          class="report_detail_result_img"
          ref="report_detail_result_img"
          v-show="!imageState"
        ></canvas>
      </div>
      <div class="report_detail_edit button" @click="editClicked"></div>
      <div
        class="report_detail_transform button"
        @click="$emit('changeImageState')"
      ></div>
      <div class="report_detail_button_wrap" v-if="photoList">
        <div
          class="report_detail_button_first button"
          @click="clickPhotoChangeButton('first')"
        ></div>
        <div
          class="report_detail_button_prior button"
          @click="clickPhotoChangeButton('prior')"
        ></div>
        <div class="report_detail_page" v-if="selectedPhoto">
          <input
            class="report_detail_page_current input"
            :placeholder="getPhotoNumber"
            @blur="changePhotoNumber"
          />
          /
          <div class="report_detail_page_total">{{ photoList.length }}</div>
        </div>
        <div
          class="report_detail_button_next button"
          @click="clickPhotoChangeButton('next')"
        ></div>
        <div
          class="report_detail_button_last button"
          @click="clickPhotoChangeButton('last')"
        ></div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Panzoom from "@panzoom/panzoom";
import { mapState } from "vuex";

export default {
  emits: ["openReportEditor", "clickPhoto", "changeImageState"],
  computed: {
    ...mapState(["isMobile"]),
    getPhotoNumber() {
      let index = this.photoList.findIndex(
        (photo) => photo.photo.id == this.selectedPhoto.photo.id
      );
      return `${index + 1}`;
    },
  },
  props: {
    reportDetails: Boolean,
    imageState: Boolean,
    photoList: Array,
    selectedPhoto: Object,
  },
  watch: {
    selectedPhoto() {
      if (this.panzoom) {
        this.panzoom.reset({ animate: false });
      }
    },
  },
  mounted() {
    this.panzoom = new Panzoom(this.$refs.report_detail_canvas_wrap, {
      cursor: "default",
      contain: "outside",
      minScale: 1,
      maxScale: 20,
    });
    this.$refs.report_detail_canvas_wrap.addEventListener(
      "wheel",
      this.panzoom.zoomWithWheel
    );
  },
  methods: {
    editClicked() {
      if (this.isMobile) {
        this.$store.commit(
          "openAlert",
          this.$t("albumAlert.unabledToUseEditModeOnMobileRetryOnWeb")
        );
      } else {
        this.$emit("openReportEditor");
      }
    },
    clickPhotoChangeButton(button) {
      let index = this.photoList.findIndex(
        (photo) => photo.id == this.selectedPhoto.id
      );
      let number;
      switch (button) {
        case "first":
          number = 0;
          break;
        case "prior":
          number = index - 1;
          break;
        case "next":
          number = index + 1;
          break;
        case "last":
          number = this.photoList.length - 1;
          break;
        default:
          break;
      }
      if (this.photoList[number]) {
        this.$emit("clickPhoto", this.photoList[number]);
      } else {
        this.$store.commit("openAlert", this.$t("albumAlert.noPrevNextPhoto"));
      }
    },
    changePhotoNumber(event) {
      if (
        0 < event.target.value &&
        event.target.value <= this.photoList.length
      ) {
        this.$emit("clickPhoto", this.photoList[event.target.value - 1]);
      } else if (!event.target.value) {
        //Do nothing
      } else {
        this.$store.commit(
          "openAlert",
          `${this.$t("droneAlert.onlyAbleToEnterBetweenStartEndNumber", {
            startNumber: 1,
            endNumber: this.photoList.length,
          })}`
        );
      }
      event.target.value = null;
    },
  },
};
</script>

<style lang="scss">
.report_detail {
  position: fixed;
  right: 0px;
  top: 70px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 40%;
  height: calc(100% - 70px);
  background: rgba(23, 23, 23);
  max-width: 520px;
}
.report_detail_img_wrap {
  position: relative;
  width: 100%;
  height: calc((100% - 200px) / 2);
  border-bottom: 1px solid rgba(1, 250, 254, 0.2);
}
.report_detail_canvas_wrap {
  width: 100%;
  height: 100%;
}
.report_detail_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.report_detail_result_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.report_detail_edit {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/edit.svg");
  background-size: 20px 20px;
}
.report_detail_edit:hover {
  background-image: url("@/../public/images/edit_hover.svg");
  background-size: 20px 20px;
}
.report_detail_transform {
  position: absolute;
  top: 10px;
  right: 50px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/toggle.svg");
  background-size: 20px 20px;
  z-index: 11;
}
.report_detail_transform:hover {
  background-image: url("@/../public/images/toggle_hover.svg");
  background-size: 20px 20px;
}
.report_detail_button_wrap {
  position: absolute;
  bottom: 10px;
  left: 0px;
  @include flexbox;
  width: 100%;
  height: 30px;
  z-index: 11;
}
.report_detail_button_first {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/leftDoubleArrow.svg");
  background-size: 20px 20px;
  margin-right: 5px;
}
.report_detail_button_first:hover {
  background-image: url("@/../public/images/leftDoubleArrow_hover.svg");
  background-size: 20px 20px;
}
.report_detail_button_prior {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/leftArrow.svg");
  background-size: 20px 20px;
}
.report_detail_button_prior:hover {
  background-image: url("@/../public/images/leftArrow_hover.svg");
  background-size: 20px 20px;
}
.report_detail_page {
  @include flexbox;
  width: 130px;
  height: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  color: $main_white;
}
.report_detail_page_current {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45px;
  height: 30px;
  margin-right: 10px;
}
.report_detail_page_current::placeholder {
  text-align: center;
}
.report_detail_page_total {
  @include flexbox;
  width: 45px;
  height: 30px;
}
.report_detail_button_next {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/rightArrow.svg");
  background-size: 20px 20px;
}
.report_detail_button_next:hover {
  background-image: url("@/../public/images/rightArrow_hover.svg");
  background-size: 20px 20px;
}
.report_detail_button_last {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/rightDoubleArrow.svg");
  background-size: 20px 20px;
  margin-left: 5px;
}
.report_detail_button_last:hover {
  background-image: url("@/../public/images/rightDoubleArrow_hover.svg");
  background-size: 20px 20px;
}
</style>
