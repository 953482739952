<template>
  <div class="important_message korean button">{{ importantMessage }}</div>
</template>

<script>
export default {
  props: {
    importantMessage: String,
  },
};
</script>

<style lang="scss">
.important_message {
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 250px);
  width: 500px;
  height: 50px;
  z-index: 90;
  font-size: 1.4rem;
  padding: 0px 10px 0px 10px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.7;
  white-space: pre-line
}
.important_message:hover {
  background-color: #171717;
  color: rgba(1, 250, 254, 1);
}
</style>
