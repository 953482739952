export default class CollisionInfo{
  constructor(){
    this.drone_num = null;
    this.stop = false;
    this.restart = false;
    this.x = 0;
    this.y = 0;
    this.z = 0;
    this.target_info = {
      drone_num : null,
      stop : false,
      x : 0,
      y : 0,
      z : 0
    };
  }
}