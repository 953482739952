<template>
  <div id="form_content_wrap" class="userFormContent">
    <form class="form_wrap">
      <div class="content_area custom_scrollbar">
        <div class="profile">
          <div
            v-if="editMode === 'myInfo'"
            class="profile_image_box"
            :class="{
              no_image:
                (!uploadedImageUrl && !user.profileImageUrl) || isDeleted,
            }"
            :style="[
              (uploadedImageUrl && !isDeleted) ||
              (user.profileImageUrl && !isDeleted)
                ? {
                    backgroundImage: `url(${
                      uploadedImageUrl || user.profileImageUrl
                    })`,
                  }
                : null,
            ]"
          >
            <input
              type="file"
              id="profile_image"
              accept=".jpg, .jpeg, .png"
              @change="uploadFile($event)"
            />
            <label for="profile_image"></label>
            <div class="camera_icon"></div>
          </div>
          <div
            v-else
            class="profile_image_box"
            :class="{
              no_image:
                (!uploadedImageUrl && !selectedUserInfo.profileImageUrl) ||
                isDeleted,
            }"
            :style="[
              (uploadedImageUrl && !isDeleted) ||
              (selectedUserInfo.profileImageUrl && !isDeleted)
                ? {
                    backgroundImage: `url(${
                      uploadedImageUrl || selectedUserInfo.profileImageUrl
                    })`,
                  }
                : null,
            ]"
          >
            <input
              type="file"
              id="profile_image"
              accept=".jpg, .jpeg, .png"
              @change="uploadFile($event)"
            />
            <label for="profile_image"></label>
            <div class="camera_icon"></div>
          </div>
        </div>
        <!-- 이미 등록된 이미지가 있거나, 새로 업로드한 이미지가 있고, 삭제 상태가 아닐 때 때에만 활성화되도록 -->
        <div class="delete_area">
          <button
            type="button"
            :disabled="
              (!selectedUserInfo.profileImageUrl && isDeleted) ||
              (!selectedUserInfo.profileImageUrl && !uploadedImageUrl) ||
              isDeleted
            "
            @click="removeProfileImage"
          >
            {{ $t("button.deleteProfileImage") }}
          </button>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="">{{ $t("term.fullName") }}</label>
            <div class="input_container focus">
              <input
                class=""
                id="name"
                ref="name"
                type="text"
                :placeholder="$t('placeholder.inputName')"
                v-model="defaultUserFullName"
                @input="inputCheckAndRemove($event, 10, 'name')"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="">{{ $t("term.admin") }}</label>

            <DefaultSelectBox
              v-if="
                user.role === 'admin' &&
                user.userLoginId !== this.selectedUserInfo.userLoginId
              "
              :className="`focus full`"
              :isOptionOpen="isOptionOpen"
              :defaultName="selectedUserInfo.role"
              :selectedName="clickedOptionValue?.name"
              @update:isOptionOpen="isOptionOpen = $event"
              @controlOptionToggle="controlOptionToggle"
            >
              <ul>
                <li
                  v-for="(item, index) in adminArray"
                  :key="item + index"
                  @click="handleClickOption(item)"
                  :class="
                    item.name === this.clickedOptionValue?.name ? 'active' : ''
                  "
                >
                  <button class="option_text" type="button">
                    {{ item.name }}
                  </button>
                </li>
              </ul>
            </DefaultSelectBox>

            <div class="input_container" v-else>
              <input
                class=""
                ref="userRole"
                id="userRole"
                type="text"
                :placeholder="this.clickedOptionValue?.name"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row_input_container">
          <div class="row_input_item">
            <label class="">{{ $t("term.department") }}</label>
            <div class="input_container focus">
              <input
                class=""
                ref="field"
                id="field"
                type="text"
                :placeholder="$t('placeholder.inputDepartment')"
                autocomplete="off"
                v-model="defaultUserFieldValue"
              />
            </div>
          </div>
          <div class="row_input_item">
            <label class="">{{ $t("term.rank") }}</label>
            <div class="input_container focus">
              <input
                class=""
                ref="position"
                id="position"
                type="text"
                :placeholder="$t('placeholder.inputPosition')"
                autocomplete="off"
                v-model="defaultUserPositionValue"
              />
            </div>
          </div>
        </div>
        <p class="gray_text">{{ `*${$t("message.inputDepartmentMethod")}` }}</p>
        <label class="">{{ $t("term.id") }}</label>
        <div class="input_container">
          <input
            class=""
            ref="userId"
            id="userId"
            type="text"
            v-model="defaultUserIdValue"
            :placeholder="$t('placeholder.inputId')"
            @input="inputCheckAllAndRemove($event, 10, 'userId')"
          />
        </div>
        <label class="">{{ $t("term.newPassword") }}</label>
        <div class="input_container focus">
          <input
            class=""
            type="password"
            id="password"
            ref="password"
            :placeholder="$t('placeholder.inputNewPassword')"
            autocomplete="off"
          />
          <button
            class="eye_button"
            :class="isPasswordShow ? 'on' : ''"
            type="button"
            @click="showPassword($event, 'password')"
          ></button>
        </div>
        <label class="">{{ $t("term.contact") }}</label>
        <div class="input_container focus">
          <input
            class=""
            ref="contact"
            id="contact"
            type="text"
            :placeholder="$t('placeholder.inputOnlyNumber')"
            autocomplete="off"
            v-model="defaultUserContactValue"
            @input="onInputChange"
          />
        </div>
        <label class="">{{ $t("term.manageFacility") }}</label>
        <div class="facility_box">
          <template
            v-if="
              selectedUserInfo.facility && selectedUserInfo.facility.length > 0
            "
          >
            <span
              v-for="(facility, index) in selectedUserInfo.facility"
              :key="facility + index"
              class="state_badge gray white_text"
            >
              {{ facility.facilityName }}
            </span>
          </template>
          <template v-else>
            <p>{{ $t("message.NoInchargeFacilities") }}</p>
          </template>
        </div>
        <!-- 선택된 아이디와 로그인된 사용자의 아이디가 일치하지 않을때 보여짐 (본인의 경우 삭제 불가능) -->
        <div class="delete_area" v-if="user.id !== selectedUserInfo.id">
          <button type="button" class="red_text" @click="removeUser">
            {{ $t("button.deleteUser") }}
          </button>
        </div>
      </div>
      <div class="button_box">
        <button @click="submitEdit" class="default_bg_button" type="button">
          {{ $t("button.edit") }}
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          {{ $t("button.cancel") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { changeRoleToKorean } from "@/shared/util/changeRoleToKorean";
import { changeSiblingInputType } from "@/shared/util/changeSiblingInputType";
// import { checkAuthorizeLimit } from "@/shared/util/checkAuthorizeLimit";
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { filterNonNumeric } from "@/shared/util/filterNonNumeric";
import { getFacilityNames } from "@/shared/util/getFacilityNames";
import { mapState } from "vuex";
import CryptoJS from "crypto-js";
import { changeRoleToLanguage } from "@/shared/util/changeRoleToLanguage";
import { hasInvalidSpecialChars } from "@/shared/util/hasInvalidSpecialChars";
import { removeInvalidSpecialChars } from "@/shared/util/removeInvalidSpecialChars";
import { hasSpecialCharacter } from "@/shared/util/hasSpecialCharacter";
import { removeEverySpecialChars } from "@/shared/util/removeEverySpecialChars";
// import { filterValidValues } from "@/shared/util/filterValidValues";

export default {
  name: "UserEditFormContent",
  emits: ["handleRemoveUser", "handleEditUser", "handleRemoveProfileImage"],
  computed: {
    ...mapState(["user"]),
  },
  components: { DefaultSelectBox },
  props: {
    uploadedImageUrl: {
      type: [String, null],
      required: false,
    },
    isDeleted: {
      type: Boolean,
      required: false,
    },
    selectedUserInfo: {
      type: Object,
      required: false,
    },
    userListArray: {
      type: Array,
      required: false,
    },
    editMode: {
      type: String,
      required: false,
      default: "myInfo",
    },
  },
  data() {
    return {
      defaultUserFullName: this.selectedUserInfo.fullName,
      defaultUserIdValue: this.selectedUserInfo.userLoginId,
      defaultUserContactValue: this.selectedUserInfo.contact,
      defaultUserFieldValue: this.selectedUserInfo.affiliation.departmentName,
      defaultUserPositionValue: this.selectedUserInfo.affiliation.rankName,
      clickedOptionValue: {
        id: this.selectedUserInfo.role,
        name: changeRoleToLanguage(this.selectedUserInfo.role),
      },
      isPasswordShow: false,
      adminArray: [
        { id: "normal", name: this.$t("term.normal") },
        { id: "manager", name: this.$t("term.manager") },
      ],
      isOptionOpen: false,
      imageFileInfo: null,
    };
  },
  watch: {
    "$i18n.locale"() {
      this.updateAdminOptions();
    },
  },
  methods: {
    getFacilityNames,
    changeRoleToKorean,
    updateAdminOptions() {
      this.adminArray = [
        { id: "normal", name: this.$t("term.normal") },
        { id: "manager", name: this.$t("term.manager") },
      ];
    },
    /**
     * 모든 특수문자 체크해서 삭제 + 공백제거 (id 입력할 때만 사용)
     */
    inputCheckAllAndRemove(event, limit, inputId) {
      if (hasSpecialCharacter(event)) {
        event.target.value = removeEverySpecialChars(event);
        if (inputId === "userId") {
          // userId 값을 업데이트 해주어야 함.
          this.defaultUserIdValue = event.target.value;
        }

        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.somethingCannotContainSpecialCharacter", {
            something: "유저 아이디",
          })}\n${this.$t("otherAlert.specialCharacterNotAllowedRemoved")}`
        );
      }

      if (checkInputLimit(event, limit)) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    /**
     * 특수문자와 공백을 체크해서 삭제 + 글자수 제한
     */
    inputCheckAndRemove(event, limit, inputId) {
      if (hasInvalidSpecialChars(event)) {
        event.target.value = removeInvalidSpecialChars(event); // 입력 필드에 수정된 값 반영

        if (inputId === "name") {
          // user fullName 업데이트 해줘야함.
          this.defaultUserFullName = event.target.value;
        }

        this.$store.commit(
          "openAlert",
          `${this.$t(
            "otherAlert.specialCharacterSpacingNotAllowedExceptSomething",
            {
              character: "_ - ,",
            }
          )}\n${this.$t("otherAlert.specialCharacterNotAllowedRemoved")}`
        );
      }

      if (checkInputLimit(event, limit)) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlOptionToggle() {
      this.isOptionOpen = !this.isOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickOption(value) {
      this.isOptionOpen = false;
      this.clickedOptionValue = value;
    },
    /**
     * admin이 아니라면 변경 불가 알럿 기능
     */
    isNotAllowed() {
      if (this.selectedUserInfo.role !== "admin") {
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.unabledToChangeItem")}\n${this.$t(
            "otherAlert.pleaseContactAdministrator"
          )}`
        );
      }
    },
    inputCheck(event, limit) {
      if (checkInputLimit(event, limit)) {
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    showPassword(event, target) {
      if (target === "password") {
        this.isPasswordShow = !this.isPasswordShow;
      } else {
        this.isPasswordConfirmShow = !this.isPasswordConfirmShow;
      }
      changeSiblingInputType(event);
    },
    /**
     * 파일 업로드
     */
    uploadFile(event) {
      const inputElement = event.target;

      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        const basename = file.name.replace(/\.[^/.]+$/, "");

        // 수정된 특수 문자 정규 표현식
        let regExp = /[`~!@#$%^&*|\\'";:/?()]/gi;

        // 특수 문자가 포함되어 있는지 체크
        if (regExp.test(basename)) {
          return this.$store.commit(
            "openAlert",
            `${this.$t(
              "otherAlert.pleaseRemoveSpecialCharacterAndUploadImageFile"
            )}`
          );
        }

        this.imageFileInfo = file;
        this.$emit("handleUploadFile", { file, inputElement });
      }
    },
    /**
     * 사용자 정보 수정 기능
     */
    submitEdit() {
      let userName = this.$refs.name.value;
      let userRole =
        this.clickedOptionValue === null ? null : this.clickedOptionValue.id;
      let userId = this.$refs.userId.value;
      let userPw = this.$refs.password.value;
      let userContact = this.$refs.contact.value;
      let userField = this.$refs.field.value;
      let userPosition = this.$refs.position.value;

      /**
       * 사용자 정보 수정할때는 이름/권한/부서/직급/아이디가 필수라기 보다 값이 비워져 있으면 안되니 그에 맞는 알럿을 띄워준다.
       */

      if (!userName || !userId || !userField || !userPosition) {
        if (!userName) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.valueCannotBeEmpty", {
              name: this.$t("term.fullName"),
            })}`
          );
        } else if (!userId) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.valueCannotBeEmpty", {
              name: this.$t("term.id"),
            })}`
          );
        } else if (!userField) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.valueCannotBeEmpty", {
              name: this.$t("term.department"),
            })}`
          );
        } else if (!userPosition) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.valueCannotBeEmpty", {
              name: this.$t("term.rank"),
            })}`
          );
        }
      }

      const userInfo = {
        adminId: this.user.id,
        userLoginId: userId ? userId : null,
        userPassword: userPw ? CryptoJS.SHA256(userPw).toString() : null,
        fullName: userName ? userName : null,
        role: userRole ? userRole : null,
        contact: userContact ? userContact : null,
        affiliation: {
          id:
            this.editMode === "myInfo"
              ? this.user.affiliation.id
              : this.selectedUserInfo.affiliation.id,
          departmentName: userField ? userField : null,
          rankName: userPosition ? userPosition : null,
        },
      };

      this.$emit("handleEditUser", userInfo);
    },
    /**
     * 사용자 정보 삭제 기능
     */
    removeUser() {
      const isConfirmed = confirm(
        `${this.$t("otherAlert.areYouSureToDeleteUser", {
          fullName: this.selectedUserInfo.fullName,
        })}`
      );

      if (isConfirmed) {
        this.$emit("handleRemoveUser", this.selectedUserInfo);
      }
    },
    /**
     * 프로필 이미지 삭제
     */
    removeProfileImage() {
      this.$emit("handleRemoveProfileImage");
    },
    onInputChange(event) {
      // 숫자가 아닌 입력값을 제외시킨다.
      this.defaultUserContactValue = filterNonNumeric(event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.userFormContent {
  label {
    position: relative;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 12px;
    display: inline-block;
    margin-top: 18px;

    & ~ label {
      margin-top: 18px;
    }
  }

  .delete_area {
    @include flexbox(center, flex-end, row);
    margin: 12px 0;

    button {
      text-decoration: underline;
      color: $main_white;

      &:disabled {
        color: $gray_6e6e82;
      }

      &.red_text {
        color: $red_ff7070;
      }
    }
  }

  p.gray_text {
    font-size: 1.25rem;
    margin-top: 12px;
    line-height: 1.4;
  }

  .facility_box {
    @include flexbox(center, flex-start, row);
    gap: 6px;
    flex-wrap: wrap;
    font-size: 1.4rem;

    span {
      cursor: default;
    }

    p {
      font-size: 1.4rem;
      color: $gray_a1a1a1;
    }
  }
}
</style>
