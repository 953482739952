<template>
  <section class="user_area">
    <div class="user_info_group">
      <span>{{ $t("term.userCount", { total: total }) }}</span>
      <button
        type="button"
        class="default_border_hover_button"
        @click="$emit('handleOpenUserAddForm')"
      >
        {{ $t("button.addUser") }}
      </button>
    </div>
    <slot></slot>
  </section>
</template>
<script>
export default {
  name: "UserListManager",
  emits: ["handleOpenUserAddForm"],
  props: {
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.user_area {
  padding-bottom: 120px;
}

.user_info_group {
  @include flexbox(center, space-between, row);
  margin-bottom: 24px;
  margin-top: 38px;

  .default_border_hover_button {
    @include mobile {
      font-size: 1.2rem;
    }
  }

  span {
    font-size: 1.2rem;
    color: $gray_a1a1a1;
  }
}
</style>
