<template>
  <div id="form_content_wrap">
    <form action="" v-if="mode === 'add'">
      <div class="content_area custom_scrollbar">
        <label class="required">{{ $t("term.facilityName") }}</label>
        <div class="input_container focus">
          <input
            class=""
            ref="name"
            type="text"
            :placeholder="$t('term.facilityName')"
            autocomplete="off"
            @input="removeFirstSpecialCharacter($event)"
            @blur="inputCheckAndRemove($event, 10)"
          />
        </div>
        <label class="required">{{ $t("term.facilityType") }}</label>
        <DefaultSelectBox
          :className="`focus full`"
          :isOptionOpen="isFacilityTypeOptionOpen"
          :defaultName="$t('placeholder.selectFacilityType')"
          :selectedName="clickedTypeValue?.name"
          @update:isOptionOpen="isFacilityTypeOptionOpen = $event"
          @controlOptionToggle="controlTypeOptionToggle"
        >
          <ul>
            <li
              v-for="type in facilityTypeList"
              :key="type.name + type.id"
              :id="type.name + type.id"
              @click="handleClickTypeOption(type)"
              :class="type.name === this.clickedTypeValue?.name ? 'active' : ''"
            >
              <button class="option_text" type="button">{{ type.name }}</button>
            </li>
          </ul></DefaultSelectBox
        >
        <template v-if="$i18n.locale === 'KO'">
          <label class="required">{{ $t("term.facilityLocation") }}</label>
          <div class="input_flex_group">
            <div class="input_container">
              <input
                class=""
                autocomplete="off"
                id="postcode"
                ref="location"
                type="text"
                :placeholder="$t('placeholder.clickAddressSearchButton')"
                :value="location !== '' ? location : ''"
                readonly
              />
            </div>
            <button
              type="button"
              @click="handleClickOpenPost"
              class="default_border_hover_button find_address_button"
            >
              {{ $t("button.searchAddress") }}
            </button>
          </div>
          <div class="post_code_box" v-if="isPostSearchOpen">
            <VueDaumPostcode @complete="handleAddress" /></div
        ></template>
        <label>{{ $t("term.facilityDescription") }}</label>
        <div class="input_container focus">
          <textarea
            class=""
            ref="description"
            type="text"
            :placeholder="$t('placeholder.inputFacilityDescription')"
            @input="inputCheck($event, 300)"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="button_box">
        <button @click="submitAdd" class="default_bg_button" type="button">
          {{ $t("button.add") }}
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          {{ $t("button.cancel") }}
        </button>
      </div>
    </form>
    <form action="" v-if="mode === 'edit' && this.selectedFacility">
      <div class="content_area custom_scrollbar">
        <label>{{ $t("term.facilityName") }}</label>
        <div class="input_container focus">
          <input
            class=""
            ref="name"
            type="text"
            :placeholder="this.selectedFacility.name"
            @input="removeFirstSpecialCharacter($event)"
            @blur="inputCheckAndRemove($event, 10)"
          />
        </div>
        <label>{{ $t("term.worker") }}</label>
        <DefaultSelectBox
          :className="`focus full`"
          :isOptionOpen="isFacilityManagerOptionOpen"
          :defaultName="
            this.selectedFacility.fullName
              ? this.selectedFacility.fullName
              : $t('placeholder.selectWorker')
          "
          :selectedName="this.clickedManagerValue?.fullName"
          @update:isOptionOpen="isFacilityManagerOptionOpen = $event"
          @controlOptionToggle="controlManagerOptionToggle"
        >
          <ul>
            <li
              v-for="user in userListArray"
              :key="user.id"
              @click="handleClickManagerOption(user.fullName, user.id)"
              :class="
                user.fullName === this.clickedManagerValue?.fullName
                  ? 'active'
                  : ''
              "
              :ref="
                user.fullName === this.clickedManagerValue?.fullName
                  ? 'activeManager'
                  : ''
              "
            >
              <button class="option_text" type="button">
                {{ user.fullName }}
              </button>
            </li>
          </ul></DefaultSelectBox
        >
        <label>{{ $t("term.facilityType") }}</label>
        <DefaultSelectBox
          :className="`focus full`"
          :isOptionOpen="isFacilityTypeOptionOpen"
          :defaultName="
            changeFacilityTypeToLanguage(this.selectedFacility.typeName)
          "
          :selectedName="clickedTypeValue?.name"
          @update:isOptionOpen="isFacilityTypeOptionOpen = $event"
          @controlOptionToggle="controlTypeOptionToggle"
        >
          <ul>
            <li
              v-for="type in facilityTypeList"
              :key="type.name + type.id"
              :id="type.name + type.id"
              @click="handleClickTypeOption(type)"
              :class="type.name === this.clickedTypeValue?.name ? 'active' : ''"
            >
              <button class="option_text" type="button">{{ type.name }}</button>
            </li>
          </ul></DefaultSelectBox
        >
        <template v-if="$i18n.locale === 'KO'">
          <label>{{ $t("term.facilityLocation") }}</label>
          <div class="input_flex_group">
            <div class="input_container">
              <input
                class=""
                id="postcode"
                ref="location"
                type="text"
                :placeholder="this.selectedFacility.location"
                :value="location !== '' ? location : ''"
                readonly
              />
            </div>
            <button
              type="button"
              @click="handleClickOpenPost"
              class="default_border_hover_button find_address_button"
            >
              {{ $t("button.searchAddress") }}
            </button>
          </div>
          <div class="post_code_box" v-if="isPostSearchOpen">
            <VueDaumPostcode @complete="handleAddress" /></div
        ></template>
        <label>{{ $t("term.facilityDescription") }}</label>
        <div class="input_container focus">
          <textarea
            class=""
            ref="description"
            type="text"
            autocomplete="off"
            :placeholder="this.selectedFacility.description"
            @input="inputCheck($event, 300)"
          />
        </div>
      </div>
      <div class="button_box">
        <button @click="submitEdit" class="default_bg_button" type="button">
          {{ $t("button.edit") }}
        </button>
        <button
          @click="$emit('handleCloseCustomFrame')"
          class="default_border_button"
          type="button"
        >
          {{ $t("button.cancel") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { getCurrentTime } from "@/shared/util/getCurrentTime";
import { mapState } from "vuex";
import { VueDaumPostcode } from "vue-daum-postcode";
import { getTextAfterParenthesis } from "@/shared/util/getTextAfterParenthesis";
import { checkInputLimit } from "@/shared/util/checkInputLimit";
import { hasInvalidSpecialChars } from "@/shared/util/hasInvalidSpecialChars";
import { removeInvalidSpecialChars } from "@/shared/util/removeInvalidSpecialChars";
import { isFirstLetterSpecialCharacter } from "@/shared/util/isFirstLetterSpecialCharacter";
import { removeEverySpecialChars } from "@/shared/util/removeEverySpecialChars";
import { changeFacilityTypeToLanguage } from "@/shared/util/changeFacilityTypeToLanguage";

export default {
  name: "FacilityFormContent",
  computed: {
    ...mapState(["user", "selectedFacility", "isLoading"]),
  },
  emits: ["handleCloseCustomFrame", "handleAddFacility", "handleEditFacility"],
  components: {
    DefaultSelectBox,
    VueDaumPostcode,
  },
  props: {
    facilityTypeList: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    userListArray: {
      type: Array,
      required: true,
    },
  },
  watch: {
    // Dropdown이 열릴 때 포커스 처리
    isFacilityManagerOptionOpen(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const activeElement = this.$refs.activeManager;
          if (activeElement && activeElement[0]) {
            activeElement[0].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        });
      }
    },
  },
  data() {
    return {
      isFacilityTypeOptionOpen: false,
      isFacilityManagerOptionOpen: false,
      clickedTypeValue: null,
      clickedManagerValue: {
        fullName: this.selectedFacility?.fullName,
        userId: this.selectedFacility?.userId,
      },
      isPostSearchOpen: false,
      location: "",
    };
  },
  methods: {
    changeFacilityTypeToLanguage,
    getTextAfterParenthesis,
    /**
     * 글자수 제한
     */
    inputCheck(event, limit) {
      if (checkInputLimit(event, limit)) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    /**
     * input에 값 입력시 첫글자에 특수문자가 올 경우 삭제해준다.
     */
    removeFirstSpecialCharacter(event) {
      if (isFirstLetterSpecialCharacter(event)) {
        event.target.value = removeEverySpecialChars(event); // 입력 필드에 수정된 값 반영

        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unableToUseSpecialCharacterForFirstLetter")
        );
      }
    },
    /**
     * 특수문자와 공백을 체크해서 삭제 + 글자수 제한
     */
    inputCheckAndRemove(event, limit) {
      if (hasInvalidSpecialChars(event)) {
        event.target.value = removeInvalidSpecialChars(event); // 입력 필드에 수정된 값 반영

        this.$store.commit(
          "openAlert",
          `${this.$t(
            "otherAlert.specialCharacterSpacingNotAllowedExceptSomething",
            {
              character: "_ - ,",
            }
          )}\n${this.$t("otherAlert.specialCharacterNotAllowedRemoved")}`
        );
      }

      if (checkInputLimit(event, limit)) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
      }
    },
    /**
     * 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlManagerOptionToggle() {
      this.isFacilityManagerOptionOpen = !this.isFacilityManagerOptionOpen;
    },
    controlTypeOptionToggle() {
      this.isFacilityTypeOptionOpen = !this.isFacilityTypeOptionOpen;
    },
    /**
     * option 클릭 동작
     */
    handleClickManagerOption(fullName, userId) {
      this.isFacilityManagerOptionOpen = false;
      this.clickedManagerValue = { fullName, userId };
    },
    handleClickTypeOption(value) {
      this.isFacilityTypeOptionOpen = false;
      this.clickedTypeValue = value;
    },
    /**
     * 시설물 등록 동작
     */
    submitAdd() {
      let name = this.$refs.name.value,
        description = this.$refs.description.value;
      if (this.$i18n.locale === "KO") {
        let location = this.$refs.location.value;

        const data = {
          userId: this.user.id,
          name: name,
          location: location,
          description: description,
          typeName: this.clickedTypeValue ? this.clickedTypeValue.id : null,
          createdDatetime: getCurrentTime(),
        };

        if (
          data.userId === "" ||
          data.name === "" ||
          data.location === "" ||
          data.typeName === null ||
          data.createdDatetime === ""
        ) {
          if (!data.name) {
            return this.$store.commit(
              "openAlert",
              `${this.$t("otherAlert.nameIsRequiredEntry", {
                name: this.$t("term.facilityName"),
              })}`
            );
          } else if (!data.typeName || data.typeName === null) {
            return this.$store.commit(
              "openAlert",
              `${this.$t("otherAlert.nameIsRequiredEntry", {
                name: this.$t("term.facilityType"),
              })}`
            );
          } else if (!data.location) {
            return this.$store.commit(
              "openAlert",
              `${this.$t("otherAlert.nameIsRequiredEntry", {
                name: this.$t("term.facilityLocation"),
              })}`
            );
          }
          return this.$store.commit(
            "openAlert",
            this.$t("otherAlert.enterEveryRequiredInput")
          );
        }

        return this.$emit("handleAddFacility", data);
      }

      const data = {
        userId: this.user.id,
        name: name,
        description: description,
        typeName: this.clickedTypeValue.id,
        createdDatetime: getCurrentTime(),
      };

      if (
        data.userId === "" ||
        data.name === "" ||
        data.typeName === null ||
        data.createdDatetime === ""
      ) {
        if (!data.name) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.facilityName"),
            })}`
          );
        } else if (!data.typeName || data.typeName === null) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.nameIsRequiredEntry", {
              name: this.$t("term.facilityType"),
            })}`
          );
        }

        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.enterEveryRequiredInput")
        );
      }

      return this.$emit("handleAddFacility", data);
    },
    /**
     * 주소 입력창 띄우기
     */
    handleClickOpenPost() {
      this.isPostSearchOpen = !this.isPostSearchOpen;
    },
    /**
     * 주소 입력 동작
     */
    handleAddress(data) {
      try {
        let jibunAddress = data.jibunAddress;
        let roadAddress = data.roadAddress;
        let zonecode = data.zonecode;

        let fullAddress = jibunAddress ? jibunAddress : roadAddress;

        if (zonecode !== "" || zonecode !== undefined) {
          fullAddress += ` (${zonecode})`;
        }

        this.location = fullAddress;
      } finally {
        this.isPostSearchOpen = false;
      }
    },
    /**
     * 시설물 수정 동작
     */
    submitEdit() {
      if (
        this.$refs.name.value === "" &&
        this.location === "" &&
        this.clickedTypeValue === null &&
        this.$refs.description.value === "" &&
        (this.clickedManagerValue.fullName === null ||
          this.clickedManagerValue.userId === null)
      ) {
        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.enterNewValueOrClickCancel")
        );
      }

      let customLocation = this.location
        ? this.location
        : this.selectedFacility.location;

      let name = this.$refs.name.value
          ? this.$refs.name.value
          : this.selectedFacility.name,
        location = customLocation,
        // extraLocation = addExtraLocation,
        description = this.$refs.description.value
          ? this.$refs.description.value
          : this.selectedFacility.description,
        typeName = this.clickedTypeValue
          ? this.clickedTypeValue.id
          : this.selectedFacility.typeName,
        createdDatetime = this.selectedFacility.createdDatetime,
        userId = this.clickedManagerValue?.userId
          ? this.clickedManagerValue.userId
          : this.selectedFacility.userId;

      const data = {
        userId: userId,
        name: name,
        location: location,
        description: description,
        typeName: typeName,
        createdDatetime: createdDatetime,
        wrDatetime: null,
      };

      this.$emit("handleEditFacility", data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
