/**
 * 입력된 값에서 허용되지 않는 특수문자와 공백이 포함되어 있는지 확인한다.
 * 허용되지 않는 특수 문자: ~ ` ! @ # $ % ^ & * ( ) + = [ ] { } | . < > \ ? / * :
 * @param {*} event
 * @returns {boolean} 허용되지 않은 특수문자 또는 공백이 포함되어 있으면 true, 그렇지 않으면 false
 */
export const hasInvalidSpecialChars = (event) => {
  const targetValue = event.target.value;

  // 허용되지 않는 특수문자: ~ ` ! @ # $ % ^ & * ( ) + = [ ] { } | . < > \ ? / * :
  const invalidCharPattern = /[~`!@#$%^&*()+=\\[\]{}|.<>?/\\\s:]/g;

  // 허용되지 않는 특수문자 또는 공백이 포함되어 있는지 확인
  // 포함되어 있으면 true, 아니라면 false
  return invalidCharPattern.test(targetValue);
};
