import { i18n } from "@/main";

const getFacilityTypeList = () => [
  { name: i18n.global.t("facilityType.bridge"), id: "bridge" },
  { name: i18n.global.t("facilityType.structure"), id: "structure" },
  { name: i18n.global.t("facilityType.incline"), id: "incline" },
  { name: i18n.global.t("facilityType.tunnel"), id: "tunnel" },
  { name: i18n.global.t("facilityType.other"), id: "other" },
];

export default getFacilityTypeList;
