<template>
  <div class="no_result">
    <div class="icon_box"></div>
    <h3>{{ $t("message.noSearchResult") }}</h3>
    <p>{{ $t("message.checkKeywordOneMoreTime") }}</p>
  </div>
</template>
<script>
export default {
  name: "NoSearchResult",
};
</script>
<style lang="scss" scoped>
.no_result {
  @include flexbox(center, center, column);
  text-align: center;
  padding: 30px 0;

  .icon_box {
    width: 40px;
    height: 40px;
    background: url("@/../public/images/message_exclamation.svg") no-repeat
      center;
    background-size: contain;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    font-size: 1.4rem;
    margin-top: 8px;
  }
}
</style>
