/**
 * input의 value length가 limit보다 크면 알럿을 띄우고 글자수를 자른다.
 * @param {*} event
 * @param {number} limit
 */
export const checkInputLimit = (event, limit) => {
  if (event.target.value.length > limit) {
    event.target.value = event.target.value.substr(0, limit);
    return true;
  }
  return false;
};
