<template>
  <ul class="facility_list" v-if="facilities">
    <li
      v-for="facility in facilities[this.currentPage - 1]"
      :id="facility?.id"
      :key="facility?.id"
      class="facility"
      @click="clickFacility(facility)"
    >
      <div class="info_area">
        <div class="title_box">
          <div class="name_box">
            <h5>
              {{ facility?.name }}
              {{ "(" + changeFacilityTypeToLanguage(facility.typeName) + ")" }}
            </h5>
            <div class="func_box" :class="isTouchDevice ? 'touch_device' : ''">
              <button
                type="button"
                class="remove"
                @click="removeFacility($event, facility.id, facility.name)"
              >
                {{ $t("button.delete") }}
              </button>
              <div class="bar"></div>
              <button
                type="button"
                class="edit"
                @click="editFacility($event, facility)"
              >
                {{ $t("button.edit") }}
              </button>
            </div>
          </div>
          <div
            v-if="facility.facilityMapCount === 0"
            class="state_badge yellow"
          >
            {{ $t("state.mappingNeeded") }}
          </div>
          <div
            v-else-if="
              facility.facilityMapCount > 0 && facility.albumCount === 0
            "
            class="state_badge orange"
          >
            {{ $t("state.InspectionNeeded") }}
          </div>
          <template v-else>
            <div
              v-if="getAlbumState(facility.albums) === 'Waiting'"
              class="state_badge pink"
            >
              {{ $t("state.analyzeNeeded") }}
            </div>
            <div
              v-else-if="getAlbumState(facility.albums) === 'Failed'"
              class="state_badge red"
            >
              {{ $t("state.analyzeError") }}
            </div>
            <div
              v-else-if="getAlbumState(facility.albums) === 'Running'"
              class="state_badge green"
            >
              {{ $t("state.analyzing") }}
            </div>
            <div
              v-else-if="
                facility.recognitionCount > 0 &&
                getAlbumState(facility.albums) === 'Completed'
              "
              class="state_badge blue"
            >
              {{ $t("state.analyzeDone") }}
            </div>
          </template>
        </div>
        <div class="desc">
          {{ facility?.description ? facility.description : `-` }}
        </div>
        <div class="small_info_list">
          <div class="info_box">
            <p>{{ $t("term.createdDate") }}</p>

            <span v-if="facility?.createdDatetime !== null">{{
              formatDateTime(facility?.createdDatetime)
            }}</span>
            <span v-else>-</span>
          </div>
          <template v-if="$i18n.locale === 'KO'">
            <div class="info_box">
              <p>{{ $t("term.location") }}</p>
              <span v-if="facility?.location">{{ facility?.location }}</span>
              <span v-else>-</span>
            </div></template
          >
          <div class="info_box">
            <p>{{ $t("term.worker") }}</p>
            <span v-if="facility.fullName">{{ facility.fullName }}</span>
            <span v-else>-</span>
          </div>
          <div class="info_box">
            <p>{{ $t("term.mapping") }}</p>
            <span>{{
              $t("term.countGun", { count: facility.facilityMapCount })
            }}</span>
          </div>
          <div class="info_box">
            <p>{{ $t("term.shooting") }}</p>
            <span>{{
              $t("term.countGun", { count: facility.albumCount })
            }}</span>
          </div>
          <div class="info_box">
            <p>{{ $t("term.analyze") }}</p>
            <span v-if="facility.albums === null">0/0</span>
            <span v-else
              >{{ facility.recognitionCount }}/{{
                facility.albums.length
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="divided_line"></div>
      <div class="desc_area">
        <p
          v-if="facility.facilityMapCount === 0"
          v-html="$t('state.mappingNeededText')"
        ></p>
        <p
          v-else-if="
            facility.facilityMapCount > 0 &&
            facility.albumCount === 0 &&
            facility.recognitionCount === 0
          "
        >{{$t('state.inspectionNeededText')}}</p>
        <!-- <template
          v-else-if="
            facility.facilityMapCount  > 0 &&
            facility.albumCount > 0 &&
            facility.recognitionCount === 0
          "
        >
          <p v-if="facility.albums?.albumsInfo === null">
            분석을 기다리는 앨범이 있어요.\nAI 분석으로 결함을 찾아보세요.
          </p>
        </template> -->
        <template v-else>
          <p
            v-if="getAlbumState(facility.albums) === 'Waiting'"
            v-html="$t('state.analyzeNeededText')"
          ></p>
          <p
            v-else-if="getAlbumState(facility.albums) === 'Failed'"
            v-html="$t('state.analyzeErrorText')"
          ></p>
          <p
            v-else-if="getAlbumState(facility.albums) === 'Running'"
            v-html="$t('state.analyzingText')"
          ></p>
          <p
            v-else-if="
              facility.recognitionCount > 0 &&
              getAlbumState(facility.albums) === 'Completed'
            "
            v-html="$t('state.analyzeDoneText')"
          ></p>
        </template>
        <div class="icon_box"></div>
      </div>
    </li>
  </ul>
</template>
<script>
import { changeFacilityTypeToLanguage } from "@/shared/util/changeFacilityTypeToLanguage";
import { filterEmptyMapUrl } from "@/shared/util/filterEmptyMapUrl";
import { formatDateTime } from "@/shared/util/formatDateTime";
import { getAlbumState } from "@/shared/util/getAlbumState";
import { mapState } from "vuex";

export default {
  name: "FacilityList",
  emits: ["handleClickFacility", "handleRemoveFacility", "handleOpenEditForm"],
  computed: {
    isTouchDevice() {
      return this.$store.getters.isTouchDevice;
    },
    ...mapState(["facilityFilterValue", "user"]),
  },
  props: {
    facilities: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { emptyMapUrl: null };
  },
  methods: {
    changeFacilityTypeToLanguage,
    filterEmptyMapUrl,
    getAlbumState,
    formatDateTime,
    clickFacility(facility) {
      this.$store.commit("setSelectedFacility", facility);
      this.$emit("handleClickFacility", facility);
    },
    removeFacility(event, facilityId, facilityName) {
      event.stopPropagation();
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.$emit("handleRemoveFacility", { facilityId, facilityName });
      }
    },
    editFacility(event, facility) {
      event.stopPropagation();
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.$store.commit("setSelectedFacility", facility);
        this.$emit("handleOpenEditForm");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.facility_list {
  margin-top: 20px;
}
.facility {
  @include flexbox(stretch, space-between, row);
  gap: 30px;
  padding: 16px 30px;
  background: $black_252530;
  border-radius: 12px;
  transition: transform 0.12s;
  cursor: pointer;

  @include mobile {
    @include flexbox(flex-start, flex-start, column);
    gap: 16px;
    padding: 20px;
  }

  @include hoverPointer {
    &:hover {
      transform: scale(1.01);

      .info_area {
        .title_box {
          .name_box {
            .func_box {
              .bar,
              button {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  & ~ .facility {
    margin-top: 10px;
  }

  .divided_line {
    width: 1px;
    height: auto;
    background: $black_353542;

    @include mobile {
      width: 100%;
      height: 1px;
    }
  }

  .info_area {
    width: calc(70% - 1px);

    @include mobile {
      width: 100%;
    }

    .title_box {
      @include flexbox(center, space-between, row);
      margin-bottom: 6px;

      @include mobile {
        @include flexbox(flex-start, flex-start, column-reverse);
        gap: 16px;
      }

      .name_box {
        @include flexbox(center, flex-start, row);
        gap: 12px;

        .func_box {
          @include flexbox(center, flex-start, row);
          gap: 6px;

          &.touch_device {
            button {
              opacity: 1;
              visibility: visible;
            }
          }

          .bar {
            opacity: 0;
            visibility: hidden;
            display: inline-block;
            width: 1px;
            height: 11px;
            background: $gray_9fa6b2;
          }

          button {
            opacity: 0;
            visibility: hidden;
            font-size: 1.4rem;
            color: $gray_9fa6b2;

            @include hoverPointer {
              &:hover {
                font-weight: 700;
                text-decoration: underline;
              }
            }
          }

          .edit {
            position: relative;
          }
        }
      }

      h5 {
        font-size: 2rem;
        font-weight: 700;

        @include mobile {
          font-size: 1.8rem;
        }
      }
    }

    .desc {
      font-size: 1.4rem;
      margin-bottom: 14px;
    }

    .small_info_list {
      @include flexbox(center, flex-start, row);
      flex-wrap: wrap;
      gap: 12px;

      .info_box {
        @include flexbox(center, flex-start, row);
        gap: 4px;
        font-size: 1.2rem;

        p {
          color: $gray_6e6e82;
        }

        span {
          color: $gray_9fa6b2;
        }
      }
    }
  }

  .desc_area {
    width: calc(30% - 1px);
    @include flexbox(center, space-between, row);

    @include mobile {
      width: 100%;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.4;
      white-space: pre-line;
    }

    .icon_box {
      width: 12px;
      height: 20px;
      background: url(@/../public/images/rightArrow_thin_white.svg) no-repeat
        center;
      background-size: contain;
    }
  }
}
</style>
