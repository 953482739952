<template>
  <button type="button" @click="clear" class="clear_button">
    {{ $t("button.reset") }}
  </button>
</template>
<script>
export default {
  name: "ClearButton",
  emits: ["handleClear"],
  methods: {
    /**
     * 초기화 버튼
     */
    clear() {
      this.$emit("handleClear");
    },
  },
};
</script>
<style lang="scss" scoped>
.clear_button {
  @include flexbox;
  gap: 4px;
  color: $main_white;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url("@/../public/images/arrow_rotate_white.svg") no-repeat
      center;
    background-size: contain;
  }
}
</style>
