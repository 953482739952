<template>
  <component :is="$route.meta.layout || 'main'">
    <div id="home_page">
      <SummaryList :totalFacility="total" :facilities="facilities" />
      <FacilityListManager
        v-if="facilities.length > 0"
        @handleOpenAddForm="handleOpenAddForm"
      >
        <DefaultSearchInput
          ref="searchEl"
          @handleKeywordSearch="handleKeywordSearch($event)"
          :placeHolder="$t('placeholder.searchFacilityName')"
        />
        <FacilityFilterGroup
          :clickedRegionValue="clickedRegionValue"
          :clickedTypeValue="clickedTypeValue"
          :searchKeyword="searchKeyword"
          :facilityTypeList="facilityTypeList"
          :regionList="regionList"
          @handleClickTypeOption="handleClickTypeOption($event)"
          @handleClickRegionOption="handleClickRegionOption($event)"
          @handleClearFilter="handleClearFilter"
        />
        <FacilitySorting
          :clickedRecentValue="clickedRecentValue"
          :sortingOption="sortingOption"
          @handleSortArray="handleSortArray($event)"
        />
        <template v-if="filteredFacilities.length > 0">
          <FacilityList
            :facilities="filteredFacilities"
            :currentPage="currentPage"
            @handleClickFacility="handleClickFacility($event)"
            @handleRemoveFacility="handleRemoveFacility($event)"
            @handleOpenEditForm="handleOpenEditForm"
          />
          <Pagination
            class="pagination_margin"
            :totalCount="filteredTotal"
            @handleClickPaginationNumber="handleClickPaginationNumber($event)"
            :initialPageOnMount="currentPage"
            :itemsPerPage="itemsPerPage"
          />
        </template>
        <template v-else>
          <NoSearchResult />
        </template>
      </FacilityListManager>
      <NoFacilitiesMessage
        v-else
        :totalFacility="total"
        @handleOpenAddForm="handleOpenAddForm"
      />
      <!-- 라우팅 모달 / 시설 추가, 수정 모달 -->
      <CustomModalFrame v-if="isModalOpen" @closeModal="handleCloseCustomFrame">
        <RouteModalContent
          v-if="modalName === 'route'"
          @handleMappingRoute="handleMappingRoute($event, mappingType)"
          @handleRouteInspection="handleRouteInspection"
          @handleSaveMapInfo="handleSaveMapInfo($event)"
          @handleRouteReport="handleRouteReport"
          @closeModal="handleCloseCustomFrame"
        />
        <FacilityFormContent
          v-if="modalName === 'formModal'"
          :mode="modalMode"
          :userListArray="userListArray"
          :facilityTypeList="facilityTypeList"
          @handleCloseCustomFrame="handleCloseCustomFrame"
          @handleAddFacility="handleAddFacility($event)"
          @handleEditFacility="handleEditFacility($event)"
        />
      </CustomModalFrame>
    </div>
  </component>
</template>
<script>
import { mapState } from "vuex";

import * as RESTAPI from "@/shared/communication/RESTAPI";

import FacilityListManager from "@/page/home/component/FacilityListManager.vue";
import SummaryList from "@/page/home/component/SummaryList.vue";
import CustomModalFrame from "@/component/modal/custom/CustomModalFrame.vue";
import FacilityFormContent from "@/page/home/component/FacilityFormContent.vue";
import RouteModalContent from "./component/RouteModalContent.vue";
import LoadingSpinner from "@/component/loading/LoadingSpinner.vue";
import FacilityList from "@/page/home/component/FacilityList.vue";
import FacilitySorting from "./component/FacilitySorting.vue";
import FacilityFilterGroup from "./component/FacilityFilterGroup.vue";
import NoFacilitiesMessage from "./component/NoFacilitiesMessage.vue";
import NoSearchResult from "@/component/no-result/NoSearchResult.vue";
import Pagination from "@/component/pagination/Pagination.vue";
import { paginateArray } from "@/shared/util/paginateArray";
import DefaultSearchInput from "@/component/input/DefaultSearchInput.vue";
import getRegionList from "@/shared/util/get-names/getRegionList";
import getFacilityTypeList from "@/shared/util/get-names/getFacilityTypeList";
import Site from "@/model/Site";
// import { countByFacilityId } from "@/shared/util/countByFacilityId";

export default {
  name: "HomePage",
  computed: {
    ...mapState(["user", "selectedFacility", "selectedFacilityMap", "api"]),
  },
  components: {
    SummaryList,
    FacilityListManager,
    FacilityList,
    FacilityFilterGroup,
    FacilitySorting,
    CustomModalFrame,
    RouteModalContent,
    FacilityFormContent,
    LoadingSpinner,
    NoFacilitiesMessage,
    NoSearchResult,
    Pagination,
    DefaultSearchInput,
  },
  data() {
    return {
      total: 0,
      filteredTotal: 0,
      facilities: [],
      filteredFacilities: [],
      userListArray: [],
      isModalOpen: false,
      modalName: "",
      modalMode: "",
      clickedRegionValue: null,
      clickedTypeValue: null,
      clickedRecentValue: this.$t("term.newest"),
      searchKeyword: "",
      selectedMap: null,
      itemsPerPage: 6,
      currentPage: 1,
      sortingOption: [this.$t("term.newest"), this.$t("term.oldest")],
      facilityTypeList: getFacilityTypeList(),
      regionList: getRegionList(),
    };
  },
  watch: {
    "$i18n.locale"() {
      this.updateSortingOptions();
      this.updateFacilityTypeList();
      this.setFacilityType();
      this.updateRegionList();
    },
  },
  methods: {
    /**
     * ?: data에 저장되어있는 용어들은 언어가 변경되어도 감지되지 않을 수 있다,,
     * ?: 어쩔 수 없이 watch를 활용해서 업데이트 시켜준다.
     */
    updateSortingOptions() {
      this.sortingOption = [this.$t("term.newest"), this.$t("term.oldest")];
      this.clickedRecentValue = this.$t("term.newest");
    },
    updateFacilityTypeList() {
      this.facilityTypeList = getFacilityTypeList();
    },
    updateRegionList() {
      this.regionList = getRegionList();
    },
    /**
     * 유저 리스트 가져오기
     */
    async setUserList() {
      const data = await RESTAPI.getUserList(this.user.affiliation.id);

      try {
        if (data.success) {
          this.userListArray = data.result;

          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    /**
     * 시실물 리스트 가져오기
     */
    async setFacilityList() {
      this.$store.commit("setIsLoading", true);
      // 유저 리스트 불러오기
      if (this.setUserList()) {
        try {
          const data = await RESTAPI.getFacilitiesList(this.user.id);

          if (data?.success) {
            // 받아온 시설물 등록
            const facilityData = data.result;

            /**
             * 맵 상태 뽑아오기 : 각각의 시설물 마다 map이 있는지 없는지 확인해서 넣어줌. 없으면 mapList = null
             */
            const mapData = await RESTAPI.getMapsByUserId(this.user.id);

            if (mapData.data.success) {
              const mapResult = mapData.data.result;
              facilityData.forEach((facility) => {
                const matchingMaps = mapResult.filter(
                  (map) => map.facilityId === facility.id
                );
                facility.mapList = matchingMaps;
              });
            }

            /**
             * albums 뽑기 : 각각의 시설물 마다 albums 있는지 없는지 확인해서 넣어줌. 없으면 albums: null
             */

            const albumResult = await RESTAPI.getAlbums(true);

            if (albumResult) {
              const albumInfo = albumResult.data.result;
              // 각 facility에 해당하는 albums를 추가
              facilityData.forEach((facility) => {
                // album.facilityId가 facility.id와 같은 album들을 찾아서 배열로 생성
                const matchingAlbums = albumInfo.filter(
                  (album) => album.facilityId === facility.id
                );

                // facility 객체에 albums라는 새로운 배열을 추가
                facility.albums = matchingAlbums;
              });
            }

            /**
             * 담당자 이름 userId와 비교해서 뽑아오기
             */

            // facilityList 를 for문 돌려서 userId와 user List의 id와 비교해서 일치하는 사람 이름을 출력한다.
            const userDataResult = this.userListArray;
            facilityData.forEach((facility) => {
              const matchedUser = userDataResult.find(
                (user) => user.id === facility.userId
              );
              facility.fullName = matchedUser ? matchedUser.fullName : null;
            });

            this.facilities = facilityData;
            this.filteredFacilities = paginateArray(
              facilityData,
              this.itemsPerPage
            );
            this.setFacilityType();

            return;
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.$store.commit("setIsLoading", false);
        }
      } else {
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.failedToRetrieveFacilityManager")}\n${this.$t(
            "otherAlert.pleaseReloadOrRetry"
          )}`
        );
      }
    },
    /**
     * 시설물 종류에 맞게 분류
     */
    // 분류하여 카운트하는 함수
    countFacilitiesByType(facilities) {
      let result = [
        { type: "bridge", count: 0 },
        { type: "structure", count: 0 },
        { type: "incline", count: 0 },
        { type: "tunnel", count: 0 },
        { type: "other", count: 0 },
      ];

      // 각 항목을 순회하면서 typeName에 맞게 카운트
      facilities.forEach((facility) => {
        const type = facility.typeName;

        // 배열에서 type에 해당하는 객체를 찾아 count를 증가시킴
        let found = result.find((item) => item.type === type);
        if (found) {
          found.count++;
        } else {
          // 해당 type이 없다면 '기타'에 추가
          result.find((item) => item.type === "other").count++;
        }
      });

      return result;
    },
    setFacilityType() {
      this.total = this.facilities.length;
      this.filteredTotal = this.total;

      this.$store.commit(
        "setFacilityTypeCounts",
        this.countFacilitiesByType(this.facilities)
      );
    },
    /**
     * 시설물 클릭 동작
     */
    handleClickFacility(facility) {
      this.$store.commit("setSelectedFacility", new Site(facility));
      this.isModalOpen = true;
      this.modalName = "route";
    },
    /**
     * 시설물 클릭시 뜨는 라우팅 모달 닫기
     */
    handleCloseCustomFrame() {
      this.isModalOpen = false;
      this.modalName = "";
      this.modalMode = "";
      this.$store.commit("setSelectedFacility", null);
    },
    /**
     * 시설물 추가(모달) 버튼 동작
     */
    async handleOpenAddForm() {
      this.isModalOpen = true;
      this.modalName = "formModal";
      this.modalMode = "add";
    },
    /**
     * 시설물 수정(모달) 버튼 동작
     */
    async handleOpenEditForm() {
      this.isModalOpen = true;
      this.modalName = "formModal";
      this.modalMode = "edit";
    },
    /**
     * 시설물 추가(생성) 버튼 동작
     */
    async handleAddFacility(data) {
      this.$store.commit("setIsLoading", true);

      const addResult = await RESTAPI.postFacility(data);

      if (addResult?.success === true) {
        this.currentPage = 1;
        this.$store.commit("setIsLoading", false);
        this.$store.commit("setSelectedFacility", null);
        this.handleCloseCustomFrame();

        // userList 를 맵 돌려서 result id값과 일치하는 이름을 반환하자.
        const matchedUser = this.userListArray.find(
          (user) => user.id === addResult.result.userId
        );

        let finalResult = addResult.result;
        finalResult.mapList = [];
        finalResult.albums = null;
        finalResult.fullName = matchedUser.fullName;

        this.facilities = [finalResult, ...this.facilities];
        this.filteredFacilities = paginateArray(
          this.facilities,
          this.itemsPerPage
        );

        this.$store.commit(
          "setFacilityTypeCounts",
          this.countFacilitiesByType(this.facilities)
        );
        this.total = this.facilities.length;
        this.filteredTotal = this.total;

        this.handleClearFilter();

        return;
      } else {
        this.$store.commit("setIsLoading", false);
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.failedToAddFacility")}\n${this.$t(
            "otherAlert.pleaseReloadOrRetry"
          )}`
        );
      }
    },
    /**
     * 시설물 검색 기능
     */
    handleKeywordSearch(keyword) {
      this.searchKeyword = keyword;
      this.handleFilterArray();
    },
    /**
     * 시설물 삭제 기능
     */
    async handleRemoveFacility(removeFacility) {
      const isConfirmed = confirm(
        this.$t("otherAlert.areYouSureToDelete", {
          name: removeFacility.facilityName,
        })
      );

      if (isConfirmed) {
        this.$store.commit("setIsLoading", true);
        const data = await RESTAPI.deleteFacility(removeFacility.facilityId);

        if (data.success) {
          this.$store.commit("setIsLoading", false);

          const deleteFacility = this.facilities.find(
            (facility) => facility.id === removeFacility.facilityId
          );

          if (deleteFacility) {
            // 삭제할 시설물이 배열에 존재하는 경우, 삭제시켜준다.
            const newFacilities = this.facilities.filter(
              (facility) => facility.id !== removeFacility.facilityId
            );
            this.facilities = newFacilities;
            this.filteredFacilities = paginateArray(
              newFacilities,
              this.itemsPerPage
            );
            this.total = newFacilities.length;
            this.filteredTotal = newFacilities.length;
            this.currentPage = 1;

            this.$store.commit(
              "setFacilityTypeCounts",
              this.countFacilitiesByType(newFacilities)
            );
            return;
          }
        }

        this.$store.commit("setIsLoading", false);
        this.total = this.facilities.length;
        this.filteredTotal = this.facilities.length;
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.failedToDeleteFacility")}\n${this.$t(
            "otherAlert.pleaseReloadOrRetry"
          )}`
        );
        return;
      }
    },
    /**
     * 시설물 수정 기능
     */
    async handleEditFacility(data) {
      let facilityId = this.selectedFacility.id;

      this.$store.commit("setIsLoading", true);
      const editResult = await RESTAPI.putFacility(facilityId, data);

      const editResultData = editResult.data;

      if (editResultData?.success === true) {
        this.$store.commit("setIsLoading", false);
        this.$store.commit("setSelectedFacility", null);
        this.handleCloseCustomFrame();
        this.setFacilityList();
        this.clickedRecentValue = this.$t("term.newest");

        return;
      } else {
        this.$store.commit("setIsLoading", false);
        return this.$store.commit(
          "openAlert",
          "openAlert",
          `${this.$t("otherAlert.failedToEditFacility")}\n${this.$t(
            "otherAlert.pleaseReloadOrRetry"
          )}`
        );
      }
    },
    /**
     * 시설물 소팅 기능
     */
    handleSortArray(value) {
      this.currentPage = 1;
      if (value === this.$t("term.newest")) {
        this.clickedRecentValue = this.$t("term.newest");

        // 평면 배열로 변환
        const flattenedArray = this.filteredFacilities.flat();

        // 최신순으로 정렬
        const desc = flattenedArray.sort((a, b) => {
          const dateA = new Date(a.createdDatetime);
          const dateB = new Date(b.createdDatetime);
          return dateB - dateA; // 최신순
        });

        // 다시 페이지 단위로 나눔
        this.filteredFacilities = paginateArray(desc, this.itemsPerPage);
      } else if (value === this.$t("term.oldest")) {
        this.clickedRecentValue = this.$t("term.oldest");

        // 평면 배열로 변환
        const flattenedArray = this.filteredFacilities.flat();

        // 오래된 순으로 정렬
        const asc = flattenedArray.sort((a, b) => {
          const dateA = new Date(a.createdDatetime);
          const dateB = new Date(b.createdDatetime);
          return dateA - dateB; // 오래된 순
        });

        // 다시 페이지 단위로 나눔
        this.filteredFacilities = paginateArray(asc, this.itemsPerPage);
      }
    },

    /**
     * 시설물 전체 필터링/소팅 기능
     * ! : 필터 값 + input 값 + 소팅 값
     */
    handleFilterArray() {
      this.currentPage = 1;
      this.clickedRecentValue = this.$t("term.newest");
      const keyword = this.searchKeyword
        ? this.searchKeyword.toLowerCase()
        : ""; // 검색어가 빈 문자열이면 빈 문자열 유지
      const typeName = this.clickedTypeValue ? this.clickedTypeValue.id : null; // typeName이 null일 수 있음
      const regionName = this.clickedRegionValue
        ? this.clickedRegionValue
        : null; // regionName이 null일 수 있음

      // 원본 데이터를 사용하여 필터링 (this.facilities)
      const filteredArray = this.facilities.filter((facility) => {
        // 조건이 null이거나 빈 문자열이면 해당 조건을 무시
        const matchesName = keyword
          ? facility.name && facility.name.toLowerCase().includes(keyword)
          : true; // keyword가 비어있으면 무시
        const matchesType = typeName ? facility.typeName === typeName : true; // typeName이 null이면 무시
        const matchesRegion = regionName
          ? facility.location && facility.location.includes(regionName)
          : true; // regionName이 null이면 무시

        // 모든 조건 설정 되어야하지만, null이나 빈 문자열은 무시함
        return matchesName && matchesType && matchesRegion;
      });

      // 필터된 배열을 잘라서 this.filteredFacilities에 넣어줌
      this.filteredFacilities = paginateArray(filteredArray, this.itemsPerPage);
      this.filteredTotal = filteredArray.length;
    },

    /**
     * 시설물 타입 옵션 선택 기능
     */
    handleClickTypeOption(value) {
      this.clickedTypeValue = value;
      this.handleFilterArray();
    },
    /**
     * 시설물 지역 옵션 선택 기능
     */
    handleClickRegionOption(value) {
      this.clickedRegionValue = value;
      this.handleFilterArray();
    },
    /**
     * 시설물 필터링 초기화
     */
    handleClearFilter() {
      this.searchKeyword = "";
      this.clickedTypeValue = null;
      this.clickedRegionValue = null;
      this.clickedRecentValue = this.$t("term.newest");
      this.filteredFacilities = paginateArray(
        this.facilities,
        this.itemsPerPage
      );
      this.total = this.facilities.length;
      this.filteredTotal = this.facilities.length;

      const searchArea = this.$refs.searchEl?.$el;
      if (searchArea) {
        const specificInput = searchArea.querySelector("#searchInput");
        if (specificInput) {
          specificInput.value = "";
        }
      } else {
        return;
      }
    },
    /**
     * 매핑 라우트 클릭
     */
    async handleMappingRoute(mappingType) {
      const facilityId = this.selectedFacility.id;

      try {
        this.$store.commit("setIsLoading", true);
        if (mappingType === "first") {
          // 아예 매핑 생성 자체가 된 적 없는 상태일 때
          const map = await RESTAPI.postMap(facilityId);
          const result = map.data.result;
          this.$router.push({
            name: "mapping",
            params: { facilityId: facilityId, mapId: result.id },
          });
        } else if (mappingType === "exist" || mappingType === "new") {
          let emptyMap = this.selectedFacility.mapList.find(
            (map) => map.url == ""
          );

          if (emptyMap) {
            this.$router.push({
              name: "mapping",
              params: {
                facilityId: facilityId,
                mapId: emptyMap.id,
              },
            });
          } else {
            const map = await RESTAPI.postMap(facilityId);
            const result = map.data.result;
            this.$router.push({
              name: "mapping",
              params: { facilityId: facilityId, mapId: result.id },
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 선택한 맵 정보 저장하는 기능
     */
    handleSaveMapInfo(map) {
      this.selectedMap = map;
      this.$store.commit("setSelectedFacilityMap", map);
    },
    /**
     * 라우트에서 선택한 맵 id를 저장해서 인스펙션 페이지로 이동 시키기
     */
    handleRouteInspection() {
      if (this.selectedFacilityMap === null) {
        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.pleaseSelectMap")
        );
      }
      this.$router.push({
        name: "inspection",
        params: {
          facilityId: this.selectedFacility.id,
          mapId: this.selectedFacilityMap.id,
        },
      });
    },
    /**
     * 라우트에서 레포트 페이지로 이동 시키기
     */
    handleRouteReport() {
      this.$router.push({
        name: "report",
        params: { facilityId: this.selectedFacility.id },
      });
    },
    /**
     * 페이지 네이션 숫자 클릭 동작
     */
    handleClickPaginationNumber(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  mounted() {
    this.setFacilityList();
    this.api.putUser({ taskStatus: "waiting" }, this.user.id);
  },
};
</script>
<style lang="scss">
#home_page {
  padding-top: 72px;
  padding-bottom: 80px;
  background: $main_black;
  min-height: 100vh;

  section {
    @include maxInner(1280px);
    color: $main_white;
    margin-bottom: 80px;
  }

  .pagination_margin {
    margin-top: 50px;
  }
}
</style>
