<template>
  <component :is="$route.meta.layout || 'main'">
    <div class="main korean">
      <MainLoadingVue ref="mainLoading" />
      <!-- <div ref="pcd_viewer_3d" class="pcd_viewer_3d" @mousemove="handleMouseMove" @mousedown="handleMouseDown"> -->
      <div
        ref="pcd_viewer_3d"
        class="pcd_viewer_3d"
        @mousedown="handleMouseDown"
      >
        <!-- <div ref="magnifier" class="magnifier"></div> -->
      </div>
      <PCMeasureInfoVue
        :measureMethod="measureMethod"
        @changeMethod="handleMesureMethod"
        @deleteMeasure="deleteMeasure"
      ></PCMeasureInfoVue></div
  ></component>
</template>

<script>
import { mapState } from "vuex";

import PCManager from "@/module/Measure/PCManager_3D.js";

import MainLoadingVue from "../Common/MainLoading.vue";
import PCMeasureInfoVue from "./PCMeasureInfo.vue";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";

export default {
  data() {
    return {
      measureMethod: null,
    };
  },
  computed: {
    ...mapState([
      "user",
      "api",
      "siteList",
      "selectedFacility",
      "setSelectedMap",
    ]),
  },
  components: {
    MainLoadingVue,
    PCMeasureInfoVue,
  },
  methods: {
    handleKeydown(event) {
      if (event.key === "Shift") {
        this.loader.getShiftKey(true);
      }
    },
    handleKeyUp(event) {
      if (event.key === "Shift") {
        this.loader.getShiftKey(false);
      }
    },
    handleMouseMove(event) {
      if (this.measureMethod) this.loader.onMouseMove(event);
    },
    handleMouseDown(event) {
      event.preventDefault();
      this.loader.onMouseDown(event);
    },
    getSite() {
      if (
        !this.selectedFacility ||
        this.selectedFacility.id != this.$route.params.facilityId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.facilityId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedFacility", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.facilityId);
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.selectedFacility.mapList = mapList;
        let map = mapList.find((map) => map.id == this.$route.params.mapId);
        this.$store.commit("setSelectedFacilityMap", map);
        this.loadPCD(this.selectedFacilityMap.url);
      }
    },
    loadPCD(url) {
      this.loader = new PCManager(
        this.$refs.pcd_viewer_3d,
        this.$refs.mainLoading
      );
      this.loader.loadPCD(url);
    },
    handleMesureMethod(method) {
      this.loader.removeDrawings();
      if (this.measureMethod == method) {
        this.measureMethod = null;
      } else {
        this.measureMethod = method;
        this.loader.setMeasureMethod(method);
      }
    },
    deleteMeasure() {
      this.loader.deleteMeasurements();
    },
  },
  mounted() {
    this.getSite();
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeUnmount() {
    if (this.loader) {
      this.loader.dispose();
    }
  },
};
</script>

<style>
.pcd_viewer_3d {
  position: absolute;
  top: 70px;
  left: 0px;
  width: 100%;
  height: calc(100% - 70px);
  overflow: hidden;
}
</style>
