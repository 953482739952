export default class Site {
  constructor(site) {
    this.id = null;
    this.lat = null;
    this.lon = null;
    this.name = null;
    this.thumbnailUrl = null;
    this.description = null;
    this.mapList = [];
    this.map = null;
    // jimin
    this.maps = null;
    this.albums = null;
    this.typeName = null;
    this.location = null;
    this.fullName = null;
    this.albumCount = null;
    this.recognitionCount = null;
    this.userId = null;
    if(site){
      this.id = site.id;
      this.lat = site.lat;
      this.lon = site.lon;
      this.name = site.name;
      this.thumbnailUrl = site.thumbnailUrl;
      this.description = site.description;
      this.mapList = site.mapList?site.mapList : [];
      this.map = site.map;
      // jimin
      this.maps = site.maps;
      this.albums = site.albums;
      this.typeName = site.typeName;
      this.location = site.location;
      this.fullName = site.fullName;
      this.albumCount = site.albumCount;
      this.recognitionCount = site.recognitionCount;
      this.userId = site.userId;
    }
  }
}
