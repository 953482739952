import { createWebHistory, createRouter } from "vue-router";

import DefaultLayout from "./component/layout/DefaultLayout.vue";

import HomePage from "./page/home/HomePage.vue";
import LoginPage from "./page/login/LoginPage.vue";
import UserPage from "./page/user/UserPage.vue";
import HomeMain from "./components/Home/HomeMain.vue";
import MappingMain from "./components/Mapping/MappingMain.vue";
import ReportMain from "./components/Report/ReportMain.vue";
import InspectionMain from "./components/Inspection/InspectionMain.vue";
import PatternMain from "./components/Pattern/PatternMain.vue";
import MeasureMain from "./components/Measure/MeasureMain.vue";
import PCMeasureMain2D from "./components/PCMeasure_2D/PCMeasureMain.vue";
import PCMeasureMain3D from "./components/PCMeasure_3D/PCMeasureMain.vue";
import NotFoundPage from "./page/not-found/NotFoundPage.vue";

const routes = [
  // start: jimin
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: "/user",
    name: "user",
    component: UserPage,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  // end: jimin
  {
    path: "/before-home",
    name: "beforeHome",
    component: HomeMain,
    meta: { requiresAuth: true },
  },
  {
    path: "/mapping/:facilityId/:mapId",
    name: "mapping",
    component: MappingMain,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  // {
  //   path: "/mapping-edit/:facilityId/:mapId",
  //   name: "mapping-edit",
  //   component: MappingMainEdit,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/report/:facilityId",
    name: "report",
    component: ReportMain,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: "/inspection/:facilityId/:mapId",
    name: "inspection",
    component: InspectionMain,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: "/measure/:facilityId/:mapId",
    name: "measure",
    component: MeasureMain,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: "/pc-measure-2d/:facilityId/:mapId",
    name: "pc-measure-2d",
    component: PCMeasureMain2D,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: "/pc-measure-3d/:facilityId/:mapId",
    name: "pc-measure-3d",
    component: PCMeasureMain3D,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: "/pattern/:facilityId/:mapId/:albumId/:modelId",
    name: "pattern",
    component: PatternMain,
    meta: { requiresAuth: true, layout: DefaultLayout },
  },
  // start: jimin
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundPage,
    meta: { requiresAuth: false, layout: DefaultLayout },
  },
  // end: jimin
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
