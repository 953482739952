<template>
  <ul class="user_list" v-if="listType === 'table'">
    <li class="list_head">
      <div class="profile_box divide_box">
        <p>{{ $t("term.profile") }}</p>
      </div>
      <div class="info_box divide_box">
        <p>{{ $t("term.fullName") }}&#40;{{ $t("term.rank") }}&#41;</p>
      </div>
      <div class="field_box divide_box">
        <p>{{ $t("term.department") }}</p>
      </div>
      <div class="admin_box divide_box">
        <p>{{ $t("term.admin") }}</p>
      </div>
      <div class="contact_box divide_box">
        <p>{{ $t("term.contact") }}</p>
      </div>
      <div class="facility_box divide_box">
        <p>{{ $t("term.manageFacility") }}</p>
      </div>
      <!-- <div class="login_status_box divide_box">
        <p>로그인 상태</p>
      </div> -->
      <div class="activity_status_box divide_box">
        <p>{{ $t("term.workingState") }}</p>
      </div>
    </li>
    <li
      @click="userClick(user)"
      class="user_item"
      v-for="user in userList[this.currentPage - 1]"
      :key="user?.id"
    >
      <div class="profile_box divide_box">
        <img v-if="user.profileImageUrl" :src="user.profileImageUrl" alt="" />
        <div v-else class="profile"></div>
      </div>
      <div class="info_box divide_box">
        <span>{{ user.fullName }}</span>
        <span v-if="user.affiliation?.rankName"
          >&nbsp;({{ user.affiliation.rankName }})</span
        >
      </div>
      <div class="field_box divide_box">
        <template v-if="user.affiliation?.departmentName">{{
          user.affiliation.departmentName
        }}</template>
        <template v-else>-</template>
      </div>
      <div class="admin_box divide_box">
        {{ changeRoleToLanguage(user.role) }}
      </div>
      <div class="contact_box divide_box">
        <template v-if="user.contact">{{
          makePhoneNumber(user.contact)
        }}</template>
        <template v-else>-</template>
      </div>
      <div class="facility_box divide_box">
        <template v-if="user.facility && user.facility.length > 0">
          <span>
            {{ getFacilityNames(user.facility) }}
          </span>
        </template>
        <template v-else>-</template>
      </div>
      <!-- <div class="login_status_box divide_box">
        <div v-if="user.loggedIn" class="rounded_state_badge green">
          <p>온라인</p>
        </div>
        <div v-else class="rounded_state_badge gray">
          <p>오프라인</p>
        </div>
      </div> -->
      <div class="activity_status_box divide_box">
        <!-- waiting/flying/mapping/inspecting/reporting -->
        <p
          v-if="user.loggedIn && user.taskStatus === 'waiting'"
          class="state_badge blue"
        >
          {{ $t("state.waiting") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'flying'"
          class="state_badge red"
        >
          {{ $t("state.inFlight") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'mapping'"
          class="state_badge red"
        >
          {{ $t("state.mapping") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'inspecting'"
          class="state_badge red"
        >
          {{ $t("state.shooting") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'reporting'"
          class="state_badge red"
        >
          {{ $t("state.analyzing") }}
        </p>
        <p v-else class="state_badge gray">{{ $t("state.offline") }}</p>
      </div>
    </li>
  </ul>
  <ul class="user_list card" v-else-if="'card'">
    <li
      @click="userClick(user)"
      class="user_item"
      v-for="user in userList[this.currentPage - 1]"
      :key="user?.id"
    >
      <div class="profile_box" :class="user.loggedIn ? 'online' : null">
        <img v-if="user.profileImageUrl" :src="user.profileImageUrl" alt="" />
        <div v-else class="profile"></div>
      </div>
      <div class="info_box">
        <span>{{ user.fullName }}</span>
        <span v-if="user.position">&nbsp;({{ user.position }})</span>
      </div>
      <div class="facility_box">
        <template v-if="user.facility && user.facility.length > 0">
          <span
            v-for="(facility, index) in getFacilityNames(user.facility)"
            :key="facility + index"
          >
            {{ facility }}
          </span>
        </template>
        <template v-else>-</template>
      </div>
      <div class="field_box divide_box">
        <strong>{{ $t("term.department") }}</strong>
        <span>
          <template v-if="user.field">{{ user.field }}</template>
          <template v-else>-</template>
        </span>
      </div>
      <div class="admin_box divide_box">
        <strong>{{ $t("term.admin") }}</strong
        ><span>{{ changeRoleToLanguage(user.role) }}</span>
      </div>
      <div class="contact_box divide_box">
        <strong>{{ $t("term.contact") }}</strong>
        <span>
          <template v-if="user.contact">{{
            makePhoneNumber(user.contact)
          }}</template>
          <template v-else>-</template>
        </span>
      </div>
      <div class="activity_status_box divide_box">
        <!-- waiting/flying/mapping/inspecting/reporting -->
        <p
          v-if="user.loggedIn && user.taskStatus === 'waiting'"
          class="state_badge blue"
        >
          {{ $t("state.waiting") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'flying'"
          class="state_badge red"
        >
          {{ $t("state.inFlight") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'mapping'"
          class="state_badge red"
        >
          {{ $t("state.mapping") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'inspecting'"
          class="state_badge red"
        >
          {{ $t("state.shooting") }}
        </p>
        <p
          v-else-if="user.loggedIn && user.taskStatus === 'reporting'"
          class="state_badge red"
        >
          {{ $t("state.analyzing") }}
        </p>
        <p v-else class="state_badge gray">{{ $t("state.offline") }}</p>
      </div>
    </li>
  </ul>
</template>
<script>
import { makePhoneNumber } from "@/shared/util/makePhoneNumber";
import { userListArray } from "./mock";
import { formatArrayWithCommas } from "@/shared/util/formatArrayWithCommas";
import { getFacilityNames } from "@/shared/util/getFacilityNames";
import { changeRoleToLanguage } from "@/shared/util/changeRoleToLanguage";

export default {
  name: "UserList",
  emits: ["handleOpenUserEditForm"],
  props: {
    userList: {
      type: Array,
      required: true,
    },
    listType: {
      type: String,
      required: true,
      default: "table",
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userListArray,
    };
  },
  methods: {
    changeRoleToLanguage,
    getFacilityNames,
    formatArrayWithCommas,
    makePhoneNumber,
    userClick(user) {
      this.$emit("handleOpenUserEditForm", user);
    },
  },
};
</script>
<style lang="scss" scoped>
.user_list {
  &.card {
    @include flexbox(flex-start, flex-start, row);
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;

    li {
      padding: 18px 12px;
      width: calc(100% / 2 - 8px);
      display: block;

      @include response(500px) {
        width: 100%;
      }

      &.user_item {
        padding: 16px 18px;
      }

      & ~ li {
        margin-top: 0;
      }

      .divide_box {
        @include flexbox(center, flex-start, row);
        gap: 8px;
        text-align: left;

        &.profile_box,
        &.info_box,
        &.field_box,
        &.admin_box,
        &.contact_box,
        &.facility_box,
        &.login_status_box,
        &.activity_status_box {
          width: 100%;
        }

        & ~ .divide_box {
          margin-top: 4px;
        }

        strong {
          color: $gray_9fa6b2;
          font-weight: 500;
        }
      }

      .info_box {
        margin-top: 12px;

        span {
          font-size: 1.8rem;
          font-weight: 700;
        }
      }

      .facility_box {
        margin: 12px 0 18px;
        min-height: 36px;

        span {
          line-height: 1.3;
          font-size: 1.2rem;
        }
      }

      .profile_box {
        position: relative;
        display: inline-block;

        /* &.online {
          border: 1px solid $green_05d58b;
          border-radius: 50px;
        } */

        img {
          width: 42px;
          height: 42px;
        }

        .profile {
          @include basicProfile(42px, 42px);
        }
      }

      .state_badge {
        width: 100%;
        text-align: center;
        margin-top: 12px;
        padding: 10px 0;
      }
    }
  }

  li {
    padding: 8px 30px;
    @include flexbox(center, flex-start, row);
    gap: 8px;
    color: $gray_a1a1a1;
    font-size: 1.2rem;

    & ~ li {
      margin-top: 10px;
    }

    &.user_item {
      padding: 16px 30px;
      border-radius: 12px;
      background: $black_252530;
      color: $main_white;
      font-size: 1.4rem;
      transition: transform 0.12s;
      cursor: pointer;

      @include hoverPointer {
        &:hover {
          transform: scale(1.01);
        }
      }
    }

    .divide_box {
      width: 100%;
      text-align: center;

      &.profile_box {
        width: 10%;
      }
      &.info_box {
        @include flexbox;
        flex-wrap: wrap;
        width: 15%;
      }
      &.field_box {
        width: 10%;
      }
      &.admin_box {
        width: 5%;
      }
      &.contact_box {
        width: 20%;
      }
      &.facility_box {
        width: 30%;
        line-height: 1.3;
      }
      /* &.login_status_box {
        width: 10%;
      } */
      &.activity_status_box {
        width: 10%;
      }
    }

    .profile_box {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .profile {
        @include basicProfile(32px, 32px);
        display: inline-block;
      }
    }
  }
}
</style>
