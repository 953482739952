<template>
  <div v-if="isUploadPatternModalOpen" class="report_upload_modal">
    <div class="report_upload_modal_header">
      {{ $t("term.crackPatternMapUpload") }}
    </div>
    <div
      class="report_upload_modal_x"
      v-if="!upload_process"
      @click="closeUploadPatternModal"
    ></div>
    <div class="report_upload_modal_content_wrap">
      <div class="report_upload_modal_content">
        <input
          class="report_upload_modal_file_name input"
          :placeholder="$t('placeholder.inputCrackPatternMapName')"
          ref="album_name"
          @input="checkInputLimit($event, 10)"
          :readonly="isPatternUploading"
        />
      </div>
      <div class="report_upload_modal_content">
        <label
          class="report_upload_modal_input button"
          :class="{ disabled: isPatternUploading }"
          for="report_file_input"
        >
          <!--균열망도 업로드 상태가 아닐 때에만 보여지도록-->
          {{ $t("button.selectFile") }}
          <input
            id="report_file_input"
            ref="reportPatternFileInput"
            type="file"
            @change="updateFile($event)"
            accept=".zip, .glb, .gltf"
          />
        </label>
        <div class="report_upload_modal_file_name">
          <p v-if="uploaded3DModelFile?.name">
            {{ uploaded3DModelFile?.name }}
          </p>
          <p v-else>{{ $t("placeholder.selectFile") }}</p>
          <div class="report_upload_modal_gage" ref="reportUploadGage"></div>
        </div>
        <button
          :disabled="isPatternUploading"
          class="report_upload_modal_button button"
          :class="{ disabled: isPatternUploading }"
          v-if="!upload_process"
          @click="clickUpload"
        >
          {{ $t("button.send") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { formatISO } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "ReportPatternUploadModal",
  emits: [
    "closeUploadPatternModal",
    "clickUpload3DModel",
    "handleUpdatePatternFile",
  ],
  props: {
    isUploadPatternModalOpen: Boolean,
    selectedAlbumInfo: Object,
    uploaded3DModelFile: Object,
    isPatternUploading: Boolean,
  },
  computed: {
    ...mapState(["selectedFacility", "selectedFacilityMap", "user"]),
  },
  data() {
    return {
      upload_process: false,
      file: null,
    };
  },
  methods: {
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
        event.target.value = event.target.value.substr(0, limit);
      }
    },
    closeUploadPatternModal() {
      this.$emit("closeUploadPatternModal");
      this.$refs.reportPatternFileInput.value = null;
    },
    updateFile(event) {
      if (!this.upload_process) {
        if (event.target.files.length > 0) {
          let allowedExtensions = ["zip", "glb", "gltf"];
          if (
            !allowedExtensions.includes(
              event.target.files[0].name.split(".").pop().toLowerCase()
            )
          ) {
            this.$store.commit(
              "openAlert",
              `${this.$t("albumAlert.isNotCorrectFileType")}<br>${this.$t(
                "albumAlert.pleaseSelectCorrectFileType",
                { fileTypes: "zip, glb, gltf" }
              )}`
            );
            this.$refs.reportFileInput.value = null;
            return;
          } else {
            this.$emit("handleUpdatePatternFile", event.target.files[0]);
          }
        } else {
          this.$emit("handleUpdatePatternFile", null);
        }
      }
    },
    clickUpload() {
      if (this.user.role === "normal") {
        this.upload_process = false;
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        const albumName = this.$refs.album_name.value;
        if (
          // 이름 없을 경우 업로드 안되도록 수정
          albumName === "" ||
          albumName === null ||
          albumName === undefined
        ) {
          // 균열망도 앨범의 이름을 작성해주세요.
          this.$store.commit(
            "openAlert",
            `${this.$t("albumAlert.pleaseSetNameOfSomething", {
              something: this.$t("term.crackPatternMap"),
            })}`
          );
          return;
        }

        // JSON 데이터
        let body = {
          facilityId: this.$route.params.facilityId,
          facilityModel3DId: this.$route.params.facilityId,
          albumId: this.selectedAlbumInfo.id,
          albumName: this.$refs.album_name.value,
          albumDatetime: "2024-09-30T22:33:44Z",
          roiPartStatus: "Modeling Complete",
          createdDatetime: formatISO(new Date()),
        };

        this.$emit("clickUpload3DModel", body, this.$refs.reportUploadGage);
      }
    },
  },
};
</script>
