import {format} from 'date-fns'

export default class InspectionMission{
  constructor(inspectionMission){
    this.id = null;
    this.facilityMapId = null;
    this.name = null; 
    this.jsonPath = null;
    this.createdDatetime = null;
    if(inspectionMission){
      this.id = inspectionMission.id;
      this.facilityMapId = inspectionMission.facilityMapId;
      this.name = inspectionMission.name; 
      this.jsonPath = inspectionMission.jsonPath;
      this.createdDatetime = format(inspectionMission.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    }
    // this.id = null;
    // this.name = null; 
    // this.facilityMapId = null;
    // this.createdDatetime = null;
    // if(inspectionMission){
    //   this.id = inspectionMission.id;
    //   this.name = inspectionMission.name; 
    //   this.facilityMapId = inspectionMission.facilityMapId;
    //   this.createdDatetime = format(inspectionMission.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    // }
  }
}