import {format} from 'date-fns'

export default class DroneHardDataMap{
  constructor(data,existence){
    this.id = data.id;
    this.name = format(data.createdDatetime, "yyyyMMdd_HHmmss");
    this.image_list = [];
    this.open = false;
    this.checked = false;
    this.state = null;
    this.percentage = null;
    this.existence = existence;
  }
}