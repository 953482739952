<template>
  <div class="mapping_setting_tap_wrap">
    <div class="mapping_setting_tap button on">
      {{ $t("term.droneControl") }}
    </div>
    <div class="mapping_setting_tap button" @click="clickSettingTab(3)">
      {{ $t("term.pointCloud") }}
    </div>
  </div>
  <div class="mapping_setting_content_wrap">
    <div class="mapping_setting_sub_title">{{ $t("term.common") }}</div>
    <div class="mapping_setting_common">
      <div class="mapping_setting_common_title">
        {{ $t("term.communication") }}
      </div>
      <div class="mapping_setting_common_content_wrap">
        <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">
            {{ $t("term.moduleType") }}
          </div>
          <div
            class="mapping_setting_common_select"
            @click="clickDroneTypeSelect"
          >
            {{ selectedDroneType.name }}
          </div>
          <div
            class="mapping_setting_common_content_unlink_type button"
            v-if="selectedDroneType.name"
            @click="clickUnlinkDroneType"
          ></div>
          <div
            v-if="droneTypeSelectModal"
            class="mapping_setting_common_select_option_wrap"
            :style="{ height: getHeightDroneType }"
          >
            <div
              v-for="(droneType, index) in droneTypeList"
              :key="index"
              class="mapping_setting_common_select_option"
              @click="selectDroneType(droneType)"
            >
              {{ droneType.name }}
            </div>
          </div>
        </div>
        <div class="mapping_setting_common_content">
          <div class="mapping_setting_common_content_title">
            {{ $t("term.moduleId") }}
          </div>
          <div class="mapping_setting_common_select" @click="clickDroneSelect">
            {{ selectedDrone.droneSerialNumber }}
          </div>
          <div
            class="mapping_setting_common_content_unlink button"
            v-if="selectedDrone.droneSerialNumber"
            @click="clickUnlinkDrone"
          ></div>
          <div
            v-if="droneSelectModal"
            class="mapping_setting_common_select_option_wrap"
            id="mapping_drone"
            :style="{ height: getHeightDrone }"
          >
            <div
              v-for="(drone, index) in droneList"
              :key="index"
              class="mapping_setting_common_select_option"
              @click="selectDrone(drone)"
            >
              {{ drone.droneSerialNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  emits: ["changeSettingTab", "selectDrone", "selectDroneType"],
  data() {
    return {
      droneSelectModal: false,
      droneTypeSelectModal: false,
    };
  },
  props: {
    droneList: Array,
    selectedDrone: Object,
    droneTypeList: Array,
    selectedDroneType: Object,
  },
  computed: {
    ...mapState(["user"]),
    getHeightDrone() {
      if (this.droneList) {
        return this.droneList.length * 25 + "px";
      } else {
        return "0px";
      }
    },
    getHeightDroneType() {
      if (this.droneTypeList) {
        return this.droneTypeList.length * 25 + "px";
      } else {
        return "0px";
      }
    },
  },
  methods: {
    clickSettingTab(tabNumber) {
      this.$emit("changeSettingTab", tabNumber);
    },
    clickDroneSelect() {
      this.droneSelectModal = !this.droneSelectModal;
      if (this.droneSelectModal) this.droneTypeSelectModal = false;
    },
    clickDroneTypeSelect() {
      this.droneTypeSelectModal = !this.droneTypeSelectModal;
      if (this.droneTypeSelectModal) this.droneSelectModal = false;
    },
    selectDrone(drone) {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.$emit("selectDrone", drone);
      }
      this.droneSelectModal = false;
    },
    selectDroneType(droneType) {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.$emit("selectDroneType", droneType);
      }
      this.droneTypeSelectModal = false;
    },
    clickUnlinkDrone() {
      this.$emit("selectDrone");
    },
    clickUnlinkDroneType() {
      this.$emit("selectDroneType");
    },
  },
};
</script>

<style></style>
