<template>
  <div class="loading" ref="loading">
    <div class="loading_box">
      <span class="text">{{ $t("term.loading") }}</span>
      <span ref="loading_text"></span>
    </div>
    <progress
      id="progress_bar"
      ref="progress_bar"
      value="0"
      max="100"
    ></progress>
  </div>
</template>

<script>
export default {
  methods: {
    startLoadingAnimation() {
      this.$refs.loading.style.display = "flex";
    },
    stopLoadingAnimation() {
      this.$refs.loading.style.display = "none";
    },
    updateLoadingText(progress) {
      let loadingTextElement = this.$refs.loading_text;
      let loadingTextCount = Math.floor(progress / 200) % 3;
      loadingTextElement.innerText = ".".repeat(loadingTextCount + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: $main_white;
  background-color: black;
  z-index: 20;
}
.loading_box {
  font-size: 1.6rem;
}
#progress_bar {
  width: 30%;
  height: 2%;
  margin-top: 4px;
}
</style>
