import {format} from 'date-fns'

export default class Model{
  constructor(model){
    this.id = null;
    this.facilityModel3DId = null;
    this.albumId = null;
    this.albumName = null;
    this.albumDatetime = null;
    this.roiPartsStatus = null;
    this.roiPartModelUrl = null;
    this.createdDatetime = null;
    if(model){
      this.id = model.id;
      this.facilityModel3DId = model.facilityModel3DId;
      this.albumId = model.albumId;
      this.albumName = model.albumName;
      this.albumDatetime = format(model.albumDatetime, "yyyy-MM-dd HH:mm:ss");
      this.roiPartsStatus = model.roiPartsStatus;
      this.roiPartDownsizedModelUri = model.roiPartDownsizedModelUri;
      this.createdDatetime = format(model.createdDatetime, "yyyy-MM-dd HH:mm:ss");
    }
  }
}