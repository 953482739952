<template>
  <div id="InspectionHandyCameraSelectModal">
    <div class="mapping_setting_header">
      <div class="inspection_setting_title">{{ $t("term.selectCamera") }}</div>
      <div
        class="inspection_setting_header_x"
        @click="$emit('handleToggleChangeCameraModal', false)"
      ></div>
    </div>
    <ul class="handy_camera_list">
      <li
        class="handy_camera_item"
        :class="{ on: selectedVideoInputElement?.label === camera?.label }"
        v-for="camera in videoDeviceList"
        :key="camera.deviceId"
        @click="changeSelectedCamera(camera)"
      >
        {{ camera.label }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "InspectionHandyCameraSelectModal",
  emits: ["handleToggleChangeCameraModal", "selectVideoDevice"],
  props: {
    videoDeviceList: {
      type: Array,
      require: true,
    },
    selectedVideoInputElement: {
      type: Object,
      require: true,
    },
  },
  methods: {
    changeSelectedCamera(camera) {
      this.$emit("selectVideoDevice", camera);
    },
  },
};
</script>
<style lang="scss" scoped>
#InspectionHandyCameraSelectModal {
  position: fixed;
  top: calc(20% - 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 30%;
  max-width: 300px;
  height: 350px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  align-items: center;

  .inspection_setting_title {
    @include flexbox;
    width: 100px;
    font-size: 1.8rem;
    color: $main_white;
    font-weight: 700;
  }

  .inspection_setting_header_x {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: url("@/../public/images/x_white.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;
  }

  .handy_camera_list {
    width: 100%;
    max-height: 300px;
    overflow: auto;

    .handy_camera_item {
      width: 100%;
      padding: 10px 20px;
      color: $main_white;
      font-size: 1.4rem;
      cursor: pointer;
      transition: 0.15s;

      &.on {
        background: $main_color;
        color: #000;
      }

      &:hover {
        background: $main_color;
        color: #000;
      }
    }
  }
}
</style>
