<template>
  <header class="with_nav">
    <div class="header_wrap">
      <div class="left_area">
        <h1>
          <router-link :to="'/'">
            <img src="@/../public/images/header-logo.png" alt="go back to home"
          /></router-link>
        </h1>
        <nav>
          <div class="navigator_mapping nav_button" v-if="navigator != 'Home'">
            <button
              type="button"
              class="navigator_title"
              @click="mappingRouter"
              :class="{ 'on ': navigator == 'Mapping' }"
            >
              {{ $t("term.mapping") }}
            </button>
          </div>
          <div
            class="navigator_inspection nav_button"
            v-if="navigator != 'Home'"
          >
            <button
              type="button"
              class="navigator_title"
              @click="inspectionRouter"
              :class="{ 'on ': navigator == 'Inspection' }"
            >
              {{ $t("term.inspection") }}
            </button>
          </div>
          <div class="navigator_report nav_button" v-if="navigator != 'Home'">
            <button
              type="button"
              class="navigator_title"
              @click="reportRouter"
              :class="{ 'on ': navigator == 'Report' }"
            >
              {{ $t("term.report") }}
            </button>
          </div>
        </nav>
      </div>
      <!-- 로그아웃/사용자 관리/내 정보 수정 컴포넌트 -->
      <HeaderUserBox />
    </div>
  </header>
</template>

<script>
import HeaderUserBox from "@/component/layout/component/HeaderUserBox.vue";
import { addOutsideClickHandler } from "@/shared/util/handleOutsideClickHandler";
import { formatISO } from "date-fns";

import { mapState } from "vuex";

export default {
  components: {
    HeaderUserBox,
  },
  computed: {
    ...mapState(["user", "selectedFacility", "selectedFacilityMap", "api"]),
  },
  props: {
    navigator: String,
  },
  data() {
    return {};
  },
  methods: {
    reportRouter() {
      if (this.selectedFacility) {
        this.$router.push({
          name: "report",
          params: { facilityId: this.selectedFacility.id },
        });

        return;
      }

      const isConfirmed = confirm(
        `${this.$t("otherAlert.pleaseSelectFacilityAgain")} ${this.$t(
          "otherAlert.goBackToMainPage"
        )}`
      );

      if (isConfirmed) {
        this.$router.push("/");
      }
    },
    mappingRouter() {
      if (this.selectedFacility) {
        if (this.selectedFacility.mapList) {
          let emptyMap = this.selectedFacility.mapList.find(
            (map) => map.url == ""
          );
          if (emptyMap) {
            this.$router.push({
              name: "mapping",
              params: {
                facilityId: this.selectedFacility.id,
                mapId: emptyMap.id,
              },
            });
          } else {
            this.api.postMap(
              this.mappingRouterCallback,
              this.selectedFacility.id,
              formatISO(new Date())
            );
          }
        } else {
          this.api.postMap(
            this.mappingRouterCallback,
            this.selectedFacility.id,
            formatISO(new Date())
          );
        }
      } else {
        alert(
          `${this.$t("otherAlert.NoFacilitySelected")}\n${this.$t(
            "otherAlert.selectFacilityFromTheList"
          )}`
        );
      }
    },
    mappingRouterCallback(data) {
      let result = data.data.result;
      this.$router.push({
        name: "mapping",
        params: { facilityId: this.selectedFacility.id, mapId: result.id },
      });
    },
    inspectionRouter() {
      if (this.selectedFacilityMap) {
        this.$router.push({
          name: "inspection",
          params: {
            facilityId: this.selectedFacility.id,
            mapId: this.selectedFacilityMap.id,
          },
        });

        return;
      }

      const isConfirmed = confirm(
        `${this.$t("otherAlert.pleaseSelectMapAgain")} ${this.$t(
          "otherAlert.goBackToMainPage"
        )}`
      );

      if (isConfirmed) {
        this.$router.push("/");
      }
    },
    homeRouter() {
      this.$router.push({ name: "home" });
    },
  },
  mounted() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    this.clickHandlerRemover = addOutsideClickHandler(
      this.$refs.headerMenuButton,
      () => {
        this.isMenuShow = false;
      }
    );
  },
  beforeUnmount() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    if (this.clickHandlerRemover) {
      this.clickHandlerRemover();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/component/header";
</style>
