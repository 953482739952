/**
 * 입력된 값에서 허용되지 않는 특수문자와 공백을 제거하고 반환한다.
 * 허용되는 특수문자: _ - ,
 * 허용되지 않는 특수 문자: ~ ` ! @ # $ % ^ & * ( ) + = [ ] { } | . < > \ ? / * :
 * @param {*} event
 * @returns {string} 허용된 특수문자만 포함된 문자열
 */
export const removeInvalidSpecialChars = (event) => {
  const targetValue = event.target.value;

  // 허용되는 특수문자: _ - ,
  const invalidCharPattern = /[~`!@#$%^&*()+=\\[\]{}|.<>?/\\\s:]/g;

  // 허용되지 않는 특수문자와 공백 제거
  return targetValue.replace(invalidCharPattern, "");
};
