import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

const vertexShader = `  
  attribute float intensity;
  uniform float size;
  uniform float opacity;
  uniform bool colorMap;
  uniform float minHeight;
  uniform float maxHeight;
  uniform vec3 hexColor;
  uniform bool useHexColor;
  uniform mat4 customModelMatrix;

  out vec4 vColor;

  vec3 calculateColor(float intensity) {
    float value = 1.0 - intensity;
    value = clamp(value, 0.0, 1.0);
    float h = value * 5.0 + 1.0;
    float k = floor(h);
    float f = h - k;
    if (int(k) % 2 == 0) {
      f = 1.0 - f;
    }
    float n = 1.0 - f;
    if (k <= 1.0) {
      return vec3(n, 0.0, 1.0);
    }
    else if (k == 2.0) {
      return vec3(0.0, n, 1.0);
    }
    else if (k == 3.0) {
      return vec3(0.0, 1.0, n);
    }
    else if (k == 4.0) {
      return vec3(n, 1.0, 0.0);
    }
    else {
      return vec3(1.0, n, 0.0);
    }
  }
  
  void main() {
    if(useHexColor){
      vColor = vec4(hexColor, opacity);
      gl_PointSize = size;
    } else {
      vec3 colors[7];
      colors[0] = vec3(0.65, 0.4, 0.65);
      colors[1] = vec3(0.65, 0.45, 0.65);
      colors[2] = vec3(0.4, 0.4, 0.9);
      colors[3] = vec3(0.4, 0.8, 0.4);
      colors[4] = vec3(0.8, 0.8, 0.4);
      colors[5] = vec3(0.8, 0.6, 0.4);
      colors[6] = vec3(0.8, 0.4, 0.4);

      vec4 worldPosition = customModelMatrix * vec4(position, 1.0);
      vec3 vWorldPosition = worldPosition.xyz;
        
      float heightFactor = (vWorldPosition.y - minHeight) / (maxHeight - minHeight);

      float section = heightFactor * 6.0;
      int idx = int(section);
      float fract = section - float(idx);
      vec3 heightColor;
      if (idx < 6) {
        heightColor = mix(colors[idx], colors[idx + 1], fract);
      } else {
        heightColor = colors[6];
      }
      gl_PointSize = size;
      if(colorMap){
        vColor = vec4(heightColor, opacity);
      }
      else{
        vec3 color = calculateColor(intensity / 255.0);
        vColor = vec4(color, opacity);
      }
    }

    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

// Fragment Shader
const fragmentShader = `
  in vec4 vColor;
  
  void main() {        
    gl_FragColor = vColor;
  }
`;

export default class PCMappingVievwer {
  constructor(containerElement, pointCloudParams) {
    this.container = containerElement;
    this.pointCloudParams = pointCloudParams;
    const fps = 15;
    this.interval = 1000 / fps;
    this.init();
    this.points;
    this.droneModel;
    this.gltfLoader = new GLTFLoader();
    this.positionsArray = [];
    this.maxX = 0;
    this.minX = 0;
    this.maxY = 0;
    this.minY = 0;
    this.lineGeometry = new THREE.BufferGeometry();
  }

  init() {
    if (this.renderer) this.container.removeChild(this.renderer.domElement);

    this.scene = new THREE.Scene();

    // 렌더러 설정
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(
      this.container.offsetWidth,
      this.container.offsetHeight
    );
    this.renderer.sortObjects = true;
    this.container.appendChild(this.renderer.domElement);

    this.initView();

    var centerPos = new THREE.Vector3(0, 0, 0);
    this.scene.add(
      new THREE.ArrowHelper(
        new THREE.Vector3(5, 0, 0),
        centerPos,
        5,
        0xff0000,
        1,
        0.3
      )
    ); //x red
    this.scene.add(
      new THREE.ArrowHelper(
        new THREE.Vector3(0, 0, -5),
        centerPos,
        5,
        0x00ff00,
        1,
        0.3
      )
    ); //y green
    this.scene.add(
      new THREE.ArrowHelper(
        new THREE.Vector3(0, 5, 0),
        centerPos,
        5,
        0x0000ff,
        1,
        0.3
      )
    );

    const modelMatrix = new THREE.Matrix4();
    const userHexColor = new THREE.Color(this.pointCloudParams.hexColor);

    this.uniforms = {
      minHeight: { value: this.pointCloudParams.height.min },
      maxHeight: { value: this.pointCloudParams.height.max },
      customModelMatrix: { value: modelMatrix },
      colorMap: {
        type: "b",
        value: this.pointCloudParams.mode == "height" ? true : false,
      },
      size: { value: this.pointCloudParams.pointSize },
      opacity: { value: this.pointCloudParams.opacity },
      heightInfoVisible: { type: "b", value: false },
      useHexColor: {
        type: "b",
        value: this.pointCloudParams.mode === "pointHexColor" ? true : false,
      },
      hexColor: { value: userHexColor },
    };

    this.material = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent: true,
      vertexColors: true,
      uniforms: this.uniforms,
    });

    this.windowResizeEvent = this.onWindowResize.bind(this);
    window.addEventListener("resize", this.windowResizeEvent);

    this.render();
  }
  initView() {
    if (this.controls) this.controls.dispose();

    this.camera = new THREE.PerspectiveCamera(
      75,
      this.container.offsetWidth / this.container.offsetHeight,
      1,
      1000
    );
    this.camera.position.set(0, 5, 50);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.screenSpacePanning = true;
    this.controls.update();

    this.renderer.setClearColor(0x000000, 1);

    let ambientLight = new THREE.AmbientLight(0xffffff);
    this.scene.add(ambientLight);
  }

  onWindowResize() {
    if (this.camera) {
      this.camera.aspect =
        this.container.offsetWidth / this.container.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.offsetWidth,
        this.container.offsetHeight
      );
    }
  }

  render() {
    let now, delta;
    let then = Date.now();
    const frame = () => {
      this.animationFrameId = requestAnimationFrame(frame);
      now = Date.now();
      delta = now - then;
      if (delta < this.interval) return;
      this.renderer.render(this.scene, this.camera);
      then = now - (delta % this.interval);
    };
    this.animationFrameId = requestAnimationFrame(frame);
  }
  deleteDroneModel() {
    if (this.droneModel) {
      this.scene.remove(this.droneModel);
    }
  }
  createDroneModel() {
    this.deleteDroneModel();
    this.gltfLoader.load("/drone/scene.gltf", (gltf) => {
      let drone = gltf.scene;
      drone.scale.set(0.1, 0.1, 0.1);

      let geometrySphere = new THREE.SphereGeometry(1);
      let materialSphere = new THREE.MeshBasicMaterial({
        color: "#FFFFFF",
        transparent: true,
        opacity: 0.5,
      });
      let sphere = new THREE.Mesh(geometrySphere, materialSphere);

      let droneModel = new THREE.Group();
      droneModel.add(drone);
      droneModel.add(sphere);
      droneModel.position.set(0, 0, 0);

      if (this.scene) this.scene.add(droneModel);
      this.droneModel = droneModel;
    });
  }
  createPointsShader(callback, array) {
    var positions = new Float32Array(array.length * 3);
    var intensity = new Float32Array(array.length);

    for (let i = 0; i < array.length; i++) {
      positions[i * 3] = array[i].x;
      positions[i * 3 + 1] = array[i].y;
      positions[i * 3 + 2] = array[i].z;
      intensity[i] = array[i].i;

      if (-array[i].z < this.minX) {
        this.minX = -array[i].z;
      } else if (-array[i].z > this.maxX) {
        this.maxX = -array[i].z;
      }
      if (-array[i].x < this.minY) {
        this.minY = -array[i].x;
      } else if (-array[i].x > this.maxY) {
        this.maxY = -array[i].x;
      }
    }
    const particleGeometry = new THREE.BufferGeometry();
    particleGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    particleGeometry.setAttribute(
      "intensity",
      new THREE.BufferAttribute(intensity, 1)
    );

    // 포인트 클라우드 객체 생성
    const point = new THREE.Points(particleGeometry, this.material);
    this.scene.add(point);

    callback(point);
  }
  drawLine(x, y, z) {
    this.positionsArray.push(x, y, z);

    if (this.line != undefined) {
      this.scene.remove(this.line);
    }

    this.lineGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(new Float32Array(this.positionsArray), 3)
    );
    var material = new THREE.LineBasicMaterial({
      color: 0x00fff2,
      linewidth: 2,
    });
    this.line = new THREE.Line(this.lineGeometry, material);
    this.scene.add(this.line);
  }

  setHeightInfoVisible(visible) {
    this.material.uniforms.heightInfoVisible.value = visible;
  }

  /**
   * 헥스 컬러 모드의 참/거짓
   * @param {Boolean} visible
   */
  setUseHexColor(visible) {
    this.material.uniforms.useHexColor.value = visible;
  }

  /**
   * hex 컬러 값 받아서 three.color로 변환하고 uniforms에 넣어주기
   * @param {String} hex
   */
  setHexColor(hex) {
    const userHexColor = new THREE.Color(hex);
    this.material.uniforms.hexColor.value = userHexColor;
  }
}
