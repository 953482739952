<template>
  <div class="pattern_capture_list" :class="{ on: isCaptureList }">
    <div class="pattern_capture_list_title">{{ $t("term.capturedList") }}</div>
    <div class="pattern_capture_list_content">
      <div
        class="pattern_capture_list_item button"
        v-for="(captureInfo, index) in captureInfoList"
        :key="index"
        @click="selectCaptureInfo(index)"
      >
        <div class="capture_top">
          <div class="pattern_capture_list_item_name">
            {{ captureInfo.name }}
          </div>
          <div class="pattern_capture_list_item_subbutton_container">
            <button
              :disabled="false"
              type="button"
              class="pattern_capture_list_item_delete"
              @click.stop="deleteCaptureInfo(index)"
            ></button>
          </div>
        </div>
        <div class="capture_bottom">
          <!-- # : 입면도 생성과 관련된 작업 -->
          <template
            v-if="
              captureInfo.elevationCrackRecognitionInfo === null ||
              !captureInfo.elevationCrackRecognitionInfo
            "
          >
            <!-- # : 입면도 생성되지 않았고 분석도 되기 전 -->
            <button
              v-if="
                !captureInfoList[index].elevationSetInfo ||
                captureInfoList[index].elevationSetInfo?.elevationSetStatus ===
                  'Generation In Progress'
              "
              type="button"
              :disabled="true"
              class="pattern_capture_list_item_analyze button"
            >
              <!-- # : 분석 준비중 (입면도 생성 전) -->
              {{ $t("state.analyzePrepare") }}
            </button>
            <button
              v-else-if="
                captureInfoList[index]?.elevationSetInfo?.elevationSetStatus ===
                'Generation Complete'
              "
              type="button"
              :disabled="false"
              class="pattern_capture_list_item_analyze button"
              @click.stop="analyzeCaptureInfo(captureInfo)"
            >
              <!-- # : 분석 하기 (입면도 생성 완료) -->
              {{ $t("button.analyze") }}
            </button>
            <button
              v-else-if="
                captureInfoList[index]?.elevationSetInfo?.elevationSetStatus ===
                'Generation Failed'
              "
              type="button"
              :disabled="true"
              class="pattern_capture_list_item_analyze button"
            >
              <!-- # : 입면도 생성 실패 -->
              {{ $t("term.prepareAnalysisFailed") }}
            </button>
          </template>
          <template v-else>
            <!-- # : 입면도 생성되었고 분석 시작한 상태 -->
            <button
              v-if="
                captureInfoList[index].elevationCrackRecognitionInfo?.status ===
                'Recognition In Progress'
              "
              type="button"
              :disabled="true"
              class="pattern_capture_list_item_analyze button"
            >
              <!-- # : 분석중 -->
              {{ $t("state.analyzing") }}
            </button>
            <button
              v-else-if="
                captureInfoList[index].elevationCrackRecognitionInfo?.status ===
                'Recognition Failed'
              "
              type="button"
              :disabled="false"
              class="pattern_capture_list_item_analyze button"
              @click.stop="analyzeCaptureInfo(captureInfo)"
            >
              <!-- # : 재분석 -->
              {{ $t("state.reanalyzing") }}
            </button>
            <button
              v-else-if="
                captureInfoList[index].elevationCrackRecognitionInfo?.status ===
                'Recognition Complete'
              "
              type="button"
              class="pattern_capture_list_item_analyze button"
              @click.stop="showSummaryModal(index)"
            >
              <!-- # : 결과 보기 -->
              {{ $t("button.viewResult") }}
            </button>
            <button
              v-else
              type="button"
              :disabled="true"
              class="pattern_capture_list_item_analyze button"
            >
              <!-- # : 기본 버튼으로 분석 준비중이 나올 수 있도록 -->
              {{ $t("state.analyzePrepare") }}
            </button>
          </template>
          <!-- ui 변경됨에 따라 사라질 예정인 결과 보기 버튼 -->
          <!-- <button
          v-if="captureInfoList[index].elevationCrackRecognitionInfo?.status ===
              'Recognition Complete'"
            :disabled="
              captureInfoList[index].elevationCrackRecognitionInfo?.status !==
              'Recognition Complete'
            "
            type="button"
            class="pattern_capture_list_item_summary button"
            :class="{
              disabled:
                captureInfoList[index].elevationCrackRecognitionInfo?.status !==
                'Recognition Complete',
            }"
            @click.stop="showSummaryModal(index)"
          >
            {{ $t("button.viewResult") }}
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  emits: [
    "selectCaptureInfo",
    "deleteCaptureInfo",
    "showSummaryModal",
    "analyzeCaptureInfo",
  ],
  computed: {
    ...mapState(["user"]),
  },
  props: {
    isCaptureList: Boolean,
    captureInfoList: Array,
    elevataionSetList: Array,
  },
  methods: {
    analyzeCaptureInfo(data) {
      if (this.user.role === "normal") {
        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      }
      this.$emit("analyzeCaptureInfo", data);
    },
    selectCaptureInfo(index) {
      this.$emit("selectCaptureInfo", index);
    },
    deleteCaptureInfo(index) {
      if (this.user.role === "normal") {
        return this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      }

      if (confirm(this.$t("albumAlert.deleteCapturedImage"))) {
        this.$emit("deleteCaptureInfo", index);
      } else {
        return 0;
      }
    },
    showSummaryModal(index) {
      this.$emit("showSummaryModal", index);
    },
  },
};
</script>

<style lang="scss">
.pattern_capture_list {
  position: fixed;
  top: calc(50% - 250px);
  left: -300px;
  display: flex;
  flex-direction: column;
  background-color: rgba(32, 32, 32, 0.85);
  border: 1px solid rgba(1, 250, 254, 0.3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  width: 300px;
  height: 500px;
  padding: 10px 10px 10px 10px;
  z-index: 9;
  transition: 1s all;
}
.pattern_capture_list {
  &.on {
    left: 0px;
    transition: 1s all;
  }
}
.pattern_capture_list_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: rgb(255, 255, 255);
  font-size: 16px;
}
.pattern_capture_list_content {
  width: 100%;
  padding: 10px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(1, 250, 254, 0.8);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(1, 250, 254, 1);
  }
}

.pattern_capture_list_item {
  @include flexbox(center, center, column);
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  gap: 10px;

  &:hover .pattern_capture_list_item_delete {
    background-image: url("@/../public/images/x_regular.svg");
    background-size: 20px 20px;

    &:hover {
      background-image: url("@/../public/images/x_hover.svg");
      background-size: 20px 20px;
    }
  }

  /* &:hover .pattern_capture_list_item_summary {
    background-image: url("@/../public/images/summary.svg");
    background-size: 20px 20px;

    &:hover {
      background-image: url("@/../public/images/summary_hover.svg");
      background-size: 20px 20px;
    }

    &:disabled {
      background-image: url("@/../public/images/summary.svg");
      background-size: 20px 20px;
    }
  } */

  &:hover .pattern_capture_list_item_analyze {
    color: $main_white;

    &:hover {
      color: $main_color;
      background: #000;
    }

    &:disabled {
      color: $gray_6e6e82;

      &:hover {
        color: $gray_6e6e82;
        background: #000;
      }
    }
  }
}
.capture_top {
  @include flexbox(center, space-between, row);
  width: 100%;
}
.capture_bottom {
  width: 100%;
  @include flexbox(center, space-between, row);
  gap: 10px;

  button {
    width: 100%;
  }
}
.pattern_capture_list_item_name {
  font-size: 1.2rem;
}
.pattern_capture_list_item_subbutton_container {
  @include flexbox;
  gap: 8px;
  background-color: transparent;
}

.pattern_capture_list_item_delete {
  width: 30px;
  height: 30px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
}

.pattern_capture_list_item_delete {
  width: 30px;
  height: 30px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
}
.pattern_capture_list_item_analyze {
  font-size: 1.4rem;
  padding: 4px;

  &:disabled {
    color: $gray_6e6e82;
    box-shadow: none;
    border: 1px solid $gray_6e6e82;
  }
}

.pattern_capture_list_item_summary {
  padding: 4px;
  /* background: url("@/../public/images/summary_white.svg") no-repeat center;
  background-size: 20px 20px;

  &:disabled {
    background: url("@/../public/images/summary.svg") no-repeat center;
    background-size: 20px 20px;
  } */
}
</style>
