<template>
  <template v-if="this.selectedDroneType?.name !== 'Handy'">
    <div v-if="isVisible" class="inspection_monitor_modal_overlay">
      <div class="inspection_monitor_status_container">
        <div class="inspection_monitor_title">{{ $t("term.state") }}</div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">FCU</div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].fcu }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.battery") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].battery }}V
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            GPS {{ $t("term.mode") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].gpsMode }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.flightMode") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].flightMode }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.speed") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].speed }} &nbsp;&nbsp;
            <span style="color: white">m/s</span>
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.slamState") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].slamState }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.storage") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].storage }}%
          </div>
        </div>
      </div>
      <div
        class="inspection_monitor_status_container"
        :style="{ height: '90px' }"
      >
        <div class="inspection_monitor_title">
          {{ $t("term.locationFlightTime") }}
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div
            class="inspection_monitor_content_status"
            :style="{ width: '100%' }"
          >
            <div class="inspection_monitor_lf_value">
              <span style="color: white">x</span> &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId].pose.x.toFixed(2) }} </span>
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">y</span> &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId].pose.y.toFixed(2) }}</span>
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">z</span> &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId].pose.z.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div
            class="inspection_monitor_content_status"
            :style="{ width: '100%' }"
          >
            <div class="inspection_monitor_lf_value">
              <span style="color: white">{{ $t("term.hour") }}</span>
              &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId].flightTime.h }}</span>
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">{{ $t("term.minute") }}</span>
              &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId].flightTime.m }}</span>
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">{{ $t("term.second") }}</span>
              &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId].flightTime.s }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="inspection_monitor_status_container"
        :style="{ height: '120px' }"
      >
        <div class="inspection_monitor_title">
          {{ $t("term.sensorMonitor") }}
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.lidar") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].sensorState.lidar }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">IMU</div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].sensorState.imu }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.encoder") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].sensorState.encoder }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="isVisible" class="inspection_monitor_attitude_overlay">
      <div class="inspection_monitor_attitude_wrapper">
        <div class="inspection_monitor_attitude_header">
          <div class="inspection_monitor_attitude_header_title">ROLL</div>
          <div class="inspection_monitor_attitude_header_content">{{(droneData[this.currentDroneId].pose.roll).toFixed(0)}}°</div>
        </div>
        <div class="inspection_monitor_attitude_img" id="inspection_roll">
          <div class="inspection_monitor_roll_img" :style="rollStyle"></div>
        </div>
      </div>
      <div class="inspection_monitor_attitude_wrapper">
        <div class="inspection_monitor_attitude_header">
          <div class="inspection_monitor_attitude_header_title">PITCH</div>
          <div class="inspection_monitor_attitude_header_content">{{(droneData[this.currentDroneId].pose.pitch).toFixed(0)}}°</div>
        </div>
        <div class="inspection_monitor_pitch_img" :style="pitchStyle"></div>
        <div class="inspection_monitor_attitude_img" id="inspection_pitch"></div>
      </div>
      <div class="inspection_monitor_attitude_wrapper">
        <div class="inspection_monitor_attitude_header">
          <div class="inspection_monitor_attitude_header_title">YAW</div>
          <div class="inspection_monitor_attitude_header_content">{{(droneData[this.currentDroneId].pose.yaw).toFixed(0)}}°</div>
        </div>
        <div class="inspection_monitor_attitude_img" id="inspection_yaw">
          <div class="inspection_monitor_yaw_img" :style="yawStyle"></div>
        </div>
      </div>
    </div>
  </template>

  <!-- Handy 일 때 -->
  <template v-else>
    <div class="inspection_monitor_modal_overlay handy" v-if="isVisible">
      <div class="inspection_monitor_status_container">
        <div class="inspection_monitor_title">{{ $t("term.state") }}</div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.slamState") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].slamState }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.storage") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].storage }}%
          </div>
        </div>
      </div>
      <div
        class="inspection_monitor_status_container"
        :style="{ height: '90px' }"
      >
        <div class="inspection_monitor_title">
          {{ $t("term.location") }}
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div
            class="inspection_monitor_content_status"
            :style="{ width: '100%' }"
          >
            <div class="inspection_monitor_lf_value">
              <span style="color: white">x</span> &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span>
                  {{ droneData[currentDroneId]?.pose?.x }}
                </span>
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">y</span> &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId]?.pose?.y }}</span>
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">z</span> &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span> {{ droneData[currentDroneId]?.pose?.z }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div
            class="inspection_monitor_content_status"
            :style="{ width: '100%' }"
          >
            <div class="inspection_monitor_lf_value">
              <span style="color: white">roll</span>
              &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span>
                  {{
                    parseFloat(droneData[currentDroneId].pose.roll).toFixed(2)
                  }}</span
                >
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">pitch</span>
              &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span>
                  {{
                    parseFloat(droneData[currentDroneId].pose.pitch).toFixed(2)
                  }}</span
                >
              </div>
            </div>
            <div class="inspection_monitor_lf_value">
              <span style="color: white">yaw</span>
              &nbsp;&nbsp;
              <div :style="{ textAlign: 'end', width: '100%' }">
                <span>
                  {{
                    parseFloat(droneData[currentDroneId].pose.yaw).toFixed(2)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="inspection_monitor_status_container"
        :style="{ height: '120px' }"
      >
        <div class="inspection_monitor_title">
          {{ $t("term.sensorMonitor") }}
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.lidar") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].sensorState.lidar }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">IMU</div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].sensorState.imu }}
          </div>
        </div>
        <div class="inspection_monitor_content_wrapper">
          <div class="inspection_monitor_content_name">
            {{ $t("term.encoder") }}
          </div>
          <div class="inspection_monitor_content_status">
            {{ droneData[currentDroneId].sensorState.encoder }}
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    droneData: Array,
    currentDroneId: Number,
    selectedDroneType: Object,
  },
  emits: ["close"],
  computed : {
    rollStyle(){
      if(this.droneData[this.currentDroneId].pose.roll != undefined){
        return `transform: rotate(${this.droneData[this.currentDroneId].pose.roll}deg)`
      }
      else{
        return `transform: rotate(0deg)`
      }
    },
    pitchStyle(){
      if(this.droneData[this.currentDroneId].pose.pitch != undefined ){
        if(this.droneData[this.currentDroneId].pose.pitch <=0){
          let calc = (this.droneData[this.currentDroneId].pose.pitch * -0.555555) + 50;
          let calc_remain = 100 - calc;
          return `background: linear-gradient(to top, rgba(1, 250, 254, 0.5) ${calc}%, rgb(24,25,26) ${calc_remain}%)`
        }
        else{
          let calc = (this.droneData[this.currentDroneId].pose.pitch * 0.555555) + 50;
          let calc_remain = 100 - calc;
          return `background: linear-gradient(to bottom, rgb(24,25,26) ${calc}%, rgba(1, 250, 254, 0.5) ${calc_remain}%)`
        }
      }
      else{
        return `background: linear-gradient(to bottom, rgb(24, 25, 26) 50%, rgba(1,250,254,0.5) 50%)`
      }
    },
    yawStyle(){
      if(this.droneData[this.currentDroneId].pose.yaw != undefined ){
        return `transform: rotate(${-this.droneData[this.currentDroneId].pose.yaw + 90}deg)`
      }
      else{
        return `transform: rotate(0deg)`
      }
    }
  }
};
</script>

<style lang="scss">
.inspection_monitor_modal_overlay {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% / 24 * 5 - 10px);
  height: 470px;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
  /* backdrop-filter: blur(1px); */
  display: flex;
  flex-direction: column;
  align-items: center;

  &.handy {
    position: fixed;
    top: 82px;
    right: 10px;
    left: auto;
    bottom: auto;
    min-height: 200px;
    height: auto;

    .inspection_monitor_status_container {
      height: auto;
    }
  }
}

.inspection_monitor_status_container {
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 100%;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
}

.inspection_monitor_title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 30px;
  color: $main_color;
  font-size: 1.4rem;
  font-weight: bold;
}

.inspection_monitor_content_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100%;
}

.inspection_monitor_content_name {
  display: flex;
  align-items: center;
  height: 100%;
  width: 50%;
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
}

.inspection_monitor_content_status {
  @include flexbox;
  height: 20px;
  width: 40%;
  margin-left: auto;
  background-color: rgb(23, 23, 23);
  color: $main_color;
  font-size: 1.2rem;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
}

.inspection_monitor_lf_value {
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100% / 3);
  padding-left: 25px;
  padding-right: 25px;
  color: $main_color;
  font-size: 1.2rem;
}

.inspection_monitor_attitude_overlay {
  position: fixed;
  bottom: 20px;
  left: calc(50% - 175px);
  width: 350px;
  height: 110px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inspection_monitor_attitude_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 110px;
  height: 100%;
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
}

.inspection_monitor_attitude_header {
  display: flex;
  height: 20px;
  width: 100%;
  padding: 0px 10px 0px 10px;
  color: $main_color;
  font-size: 1.2rem;
}
.inspection_monitor_attitude_header_title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 50%;
}
.inspection_monitor_attitude_header_content{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 50%;
  color: white;
}

.inspection_monitor_attitude_img {
  position: relative;
  height: 90px;
  width: 100%;
}
.inspection_monitor_roll_img{
  position: absolute;
  top: calc(50% - 12.5px);
  left: calc(50% - 12.5px);
  width: 25px;
  height: 25px;
  background: url("@/../public/images/drone_bold_white.svg") no-repeat center;
  background-size: 25px, 25px;
}
.inspection_monitor_pitch_img{
  position: absolute;
  height: 80px;
  width: 80px;
  left: calc(50% - 40px);
  top: calc(50% - 30px);
  border-radius: 50%;
}
.inspection_monitor_yaw_img{
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background: url("@/../public/images/location_arrow_white.svg") no-repeat center;
  background-size: 10px, 10px;
}
#inspection_roll {
  background: url("@/../public/images/roll.png") no-repeat center;
  background-size: 80px, 80px;
}
#inspection_pitch {
  background: url("@/../public/images/pitch.png") no-repeat center;
  background-size: 80px, 80px;
}
#inspection_yaw {
  background: url("@/../public/images/yaw.png") no-repeat center;
  background-size: 80px, 80px;
}
</style>
