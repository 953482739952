<template>
  <div
    class="default_modal_wrapper"
    :class="type ? type : ''"
    v-if="type === 'hasTitle'"
  >
    <div class="default_modal_dim" @click="$emit('closeModal')"></div>
    <div class="default_modal_container">
      <div class="default_modal_box">
        <div class="default_modal_box_title_wrap">
          <h2>{{ title ? title : $t("term.setting_Camel") }}</h2>
          <button
            type="button"
            class="close_button"
            @click="$emit('closeModal')"
          ></button>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
  <div class="default_modal_wrapper" v-else>
    <div class="default_modal_dim" @click="$emit('closeModal')"></div>
    <div class="default_modal_container">
      <div class="default_modal_box">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomModalFrame",
  emits: ["closeModal"],
  props: {
    type: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.default_modal_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  @include flexbox;
  z-index: $zIndex_dim;

  &.hasTitle {
    .default_modal_container {
      min-width: 400px;

      @include mobile {
        min-width: 250px;
      }
    }

    .default_modal_box {
      padding: 24px;
    }
  }
}

.default_modal_dim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  @include flexbox;
  background: rgba(0, 0, 0, 0.8);
  z-index: $zIndex_dim;
}

.default_modal_container {
  position: relative;
  z-index: $zIndex_modal;
  border-radius: 6px;
  // box-shadow: $main_box_shadow;
  background: $main_black;
  color: $main_white;
  max-width: 90%;
}

.default_modal_box_title_wrap {
  @include flexbox(center, space-between, row);
  padding-bottom: 16px;
  border-bottom: 1px solid $black_353542;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: $main_white;
  }

  .close_button {
    width: 24px;
    height: 24px;
    background: url("@/../public/images/x_white.svg") no-repeat center;
    background-size: contain;
  }
}
</style>
