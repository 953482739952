export default class DroneHardData{
  constructor(data){
    this.facility = null;
    this.mapList = [];
    if(data){
      this.facility = {
        id : data.id,
        name : data.name,
        open : false,
      }
    }
  }
}