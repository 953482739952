import { i18n } from "@/main";

export const changeFacilityTypeToLanguage = (type) => {
  if(type === "bridge") {
    return i18n.global.t("facilityType.bridge");
  } else if (type === "structure") {
    return i18n.global.t("facilityType.structure");
  } else if (type === "incline") {
    return i18n.global.t("facilityType.incline");
  } else if (type === "tunnel") {
    return i18n.global.t("facilityType.tunnel");
  } else if (type === "other") {
    return i18n.global.t("facilityType.other");
  }
};
