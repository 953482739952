<template>
  <component :is="$route.meta.layout || 'main'">
    <div class="main korean">
      <MainLoadingVue ref="mainLoading" />
      <PatternSaveModal
        v-if="isPatternSaveModal"
        :isPatternSaveModal="isPatternSaveModal"
        @closeModal="isPatternSaveModal = false"
        @saveCapture="saveCapture"
      />
      <PatternCaptureList
        :isCaptureList="isCaptureList"
        :captureInfoList="captureInfoList"
        :elevataionSetList="elevataionSetList"
        @selectCaptureInfo="selectCaptureInfo"
        @deleteCaptureInfo="deleteCaptureInfo"
        @showSummaryModal="showSummaryModal"
        @analyzeCaptureInfo="analyzeCaptureInfo($event)"
      ></PatternCaptureList>
      <PatternSummaryModal
        :isVisible="isSummaryModalVisible"
        :summaryTypes="summaryTypes"
        :networkMapList="networkMapList"
        :networkMapSummaryList="networkMapSummaryList"
        :primaryDefectList="primaryDefectList"
        :dxfText="dxfText"
        @summaryTypeChanged="summaryTypeChanged"
        @summaryTypeNameChanged="summaryTypeNameChanged"
        @primaryDefectionChanged="primaryDefectionChanged"
        @dxfTabChanged="dxfTabChanged"
        @update:isVisible="isSummaryModalVisible = $event"
        @handleDeleteSummaryType="handleDeleteSummaryType"
      ></PatternSummaryModal>
      <div
        class="pattern_caputre_list_button"
        @click="handleCaptureList"
        :class="{ on: isCaptureList }"
      ></div>
      <div ref="viewer" class="view"></div>
      <div ref="pattern_edit_viewer" class="pattern_edit_viewer">
        <!-- <div class= "pattern_top_layout"> 
        <div class="pattern_top_item"  @click="downloadDXF">
            <img src="/image/download.svg" alt="Pattern Top" class="pattern_top_item_image" >
        </div>
      </div> -->
        <div ref="imageCanvas" class="capture_main_img">
          <div
            class="save_button button"
            v-if="captureImages[3]"
            @click="openSaveModal"
          ></div>
          <canvas ref="imageCanvasSrc" class="capture_main_img_src"> </canvas>
        </div>
        <div class="capture_img_container">
          <div class="capture_set">
            <div
              :class="
                selectedCaptureDeg == 0 ? 'capture_img_selected' : 'capture_img'
              "
            >
              <div
                class="capture_img_src"
                :style="getUrl(0)"
                @click="setMainCaptureDeg(0)"
              />
            </div>
            <div class="capture_name">0° view</div>
          </div>
          <div class="capture_set">
            <div
              :class="
                selectedCaptureDeg == 90
                  ? 'capture_img_selected'
                  : 'capture_img'
              "
            >
              <div
                class="capture_img_src"
                :style="getUrl(1)"
                @click="setMainCaptureDeg(90)"
              />
            </div>
            <div class="capture_name">90° view</div>
          </div>
          <div class="capture_set">
            <div
              :class="
                selectedCaptureDeg == 180
                  ? 'capture_img_selected'
                  : 'capture_img'
              "
            >
              <div
                class="capture_img_src"
                :style="getUrl(2)"
                @click="setMainCaptureDeg(180)"
              />
            </div>
            <div class="capture_name">180° view</div>
          </div>
          <div class="capture_set">
            <div
              :class="
                selectedCaptureDeg == 270
                  ? 'capture_img_selected'
                  : 'capture_img'
              "
            >
              <div
                class="capture_img_src"
                :style="getUrl(3)"
                @click="setMainCaptureDeg(270)"
              />
            </div>
            <div class="capture_name">270° view</div>
          </div>
        </div>
      </div>
      <PatternRangeModal
        v-if="loader"
        :rangeAxis="rangeAxis"
        :range="range"
        :rotateDeg="rotateDeg"
        :rangeLimit="rangeLimit"
        @setRangeAxis="setRangeAxis"
        @captureModel="captureModel"
        @setRange="setRange"
        @setRotateDeg="setRotateDeg"
      /></div
  ></component>
</template>

<script>
import * as RESTAPI from "@/shared/communication/RESTAPI";
import Panzoom from "@panzoom/panzoom";
import { mapState } from "vuex";
import { formatISO } from "date-fns";

import ModelLoader from "@/module/ModelLoader.js";

import MainLoadingVue from "../Common/MainLoading.vue";
import PatternRangeModal from "./PatternRangeModal.vue";
import PatternSaveModal from "./PatternSaveModal.vue";
import PatternCaptureList from "./PatternCaptureList.vue";
import PatternSummaryModal from "./PatternSummaryModal.vue";

import Site from "@/model/Site.js";
import Map from "@/model/Map.js";
import Range from "@/model/Range.js";
import CaptureInfo from "@/model/CaptureInfo.js";
import Model from "@/model/Model.js";
import ElevationSet from "@/model/ElevationSet";
import NetworkMap from "@/model/NetworkMap";
import NetworkMapSummary from "@/model/NetworkMapSummary";
import API from "@/shared/constant/api";
import SSE_CAPTURE from "@/module/Communication/SSE_CAPTURE";

export default {
  data() {
    return {
      captureInfoList: [],
      elevataionSetList: [],
      networkMapList: [],
      networkMapSummaryList: [],
      primaryDefectList: [],
      isPatternSaveModal: false,
      isCaptureList: false,
      isSummaryModalVisible: false,
      selectedAlbum: null,
      selectedCaptureDeg: 0,
      captureImages: [null, null, null, null],
      range: new Range(),
      rangeLimit: new Range({
        minHeight: 0,
        maxHeight: 100,
        minWidth: 0,
        maxWidth: 100,
        minDepth: 0,
        maxDepth: 100,
        boxMinHeight: 0,
        boxMinWidth: 0,
        boxMaxDepth: 100,
      }),
      rangeAxis: 0,
      rotateDeg: { x: 0, y: 0, z: 0 },
      loader: null,
      currentElevationId: null,
      currentElevationSetId: null,
      currentNetworkMapId: null,
      summaryTypes: [],
      currnetSortedElements: [],
      dxfText: null,
    };
  },
  computed: {
    ...mapState([
      "user",
      "api",
      "siteList",
      "selectedFacility",
      "selectedFacilityMap",
    ]),
  },
  components: {
    MainLoadingVue,
    PatternRangeModal,
    PatternSaveModal,
    PatternCaptureList,
    PatternSummaryModal,
  },
  watch: {
    selectedCaptureDeg() {
      this.drawImage(); // 선택된 각도가 변경될 때마다 이미지 다시 그리기
    },
  },
  methods: {
    getUrl(index) {
      if (this.captureImages[index]) {
        return { backgroundImage: `url(${this.captureImages[index]})` };
      } else {
        return null;
      }
    },
    openSaveModal() {
      this.isPatternSaveModal = true;
    },
    saveCapture(name) {
      this.api.postCaptureInfos(
        this.postCaptureInfosCallback,
        this.facilityModel.id,
        this.$route.params.modelId,
        name,
        this.range,
        this.rangeLimit,
        this.rotateDeg,
        this.verticalCapture,
        formatISO(new Date())
      );
    },
    async postCaptureInfosCallback(data) {
      this.isPatternSaveModal = false;
      let result = { ...data.data.result }; // 얕은 복사

      if (result) {
        this.api.captureProgramStart(
          this.facilityModel.id,
          this.$route.params.modelId,
          result,
          this.captureInfoListDataCallback
        );
      }
      this.captureInfoList.push(new CaptureInfo(result));
    },
    captureInfoListDataCallback(data, facilityModelId, modelId, result) {
      if (data.data.success) {
        /**
         * sse 처리
         */
        const url = `http://${API.IP}:${API.PORT}/api/facility-model-3d/${facilityModelId}/rois/roi-parts/${modelId}/editing-parameters/${result.id}/import-progress`;
        new SSE_CAPTURE(
          url,
          this.captureInfoList,
          result,
          this.captureInfoListUpdateCallback
        );
      }
    },
    captureInfoListUpdateCallback(newArray) {
      // sse complete인 경우 captureInfoList업데이트
      this.captureInfoList = newArray;

      // getElevationSets index로 결과 요약 불러오기 때문에 리스트 업데이트 해준다.
      this.api.getElevationSets(
        this.getElevationSetsCallback,
        this.facilityModel.id,
        this.$route.params.modelId
      );
    },
    async analyzeCaptureInfo(selectedCaptureData) {
      // 1. 우선 websocket 활용해서 균열망도 분석을 시작해 준다.
      var socket = new WebSocket(
        `ws://${API.IP}:${API.PORT}/ws/${this.user.userLoginId}/elevation-set/analyses`
      );
      socket.onopen = () => {
        socket.send(
          JSON.stringify({
            elevationSetId: selectedCaptureData.id,
          })
        );

        // 2. 시작되었으면 해당 아이템을 분석중으로 바뀔 수 있도록 우선 가짜 상태 데이터를 넣어준다.
        const matchedItem = this.captureInfoList.find(
          (item) => item.id === selectedCaptureData.id
        );

        if (matchedItem) {
          matchedItem.elevationCrackRecognitionInfo = {
            ...matchedItem.elevationCrackRecognitionInfo,
            status: "Recognition In Progress",
          };
        }

        console.log(`Analysis Web Socket Open`);
      };
      socket.onclose = () => {
        console.log(`Analysis Web Socket Close`);
      };
      socket.onmessage = (event) => {
        if (event.data.includes(`[오류]`)) {
          // 우선 오류가 났으니까 버튼을 재분석 버튼으로 바꿔주기
          const matchedItem = this.captureInfoList.find(
            (item) => item.id === selectedCaptureData.id
          );

          if (matchedItem) {
            matchedItem.elevationCrackRecognitionInfo = {
              ...matchedItem.elevationCrackRecognitionInfo,
              status: "Recognition Failed",
            };
          }
          // 그 다음 오류 알럿 처리
          if (event.data.includes(`컴퓨터 리소스`)) {
            this.$store.commit(
              "openAlert",
              this.$t("albumAlert.AllComputerResourcesAreInUse")
            );
          } else if (event.data.includes(`인공지능 분석중 오류`)) {
            this.$store.commit(
              "openAlert",
              this.$t("albumAlert.errorOccurredDuringAnalysis")
            );
          } else {
            this.$store.commit("openAlert", event.data);
          }
          socket.close();
        } else if (event.data.includes(`[완료]`)) {
          const matchedItem = this.captureInfoList.find(
            (item) => item.id === selectedCaptureData.id
          );

          if (matchedItem) {
            matchedItem.elevationCrackRecognitionInfo = {
              ...matchedItem.elevationCrackRecognitionInfo,
              status: "Recognition Complete",
            };
          }
          this.$store.commit(
            "openAlert",
            this.$t("albumAlert.analysisCompleted")
          );
          socket.close();
        }
      };
    },
    selectCaptureInfo(index) {
      this.setRotateDeg("y", this.captureInfoList[index].yaw);
      this.setRotateDeg("x", this.captureInfoList[index].roll);
      this.setRotateDeg("z", this.captureInfoList[index].pitch);
      this.range = new Range(this.captureInfoList[index]);
      this.loader.applyCropedColor(this.range);
      this.captureModel(this.captureInfoList[index].vertical);
    },
    deleteCaptureInfo(index) {
      this.api.deleteCaptureInfo(
        this.deleteCaptureInfosCallback,
        this.facilityModel.id,
        this.$route.params.modelId,
        this.captureInfoList[index].id
      );
    },

    deleteCaptureInfosCallback(data, infoId) {
      let result = data.data.result;
      if (result) {
        let captureInfoIndex = this.captureInfoList.findIndex(
          (captureInfo) => captureInfo.id == infoId
        );
        this.captureInfoList.splice(captureInfoIndex, 1);
      }
    },

    async showSummaryModal(index) {
      this.currentElevationSetId = this.elevataionSetList[index].id;
      this.currnetSortedElements = this.elevataionSetList[index].elements.sort(
        (a, b) => a.degree - b.degree
      );
      this.summaryTypes = this.currnetSortedElements.map((element, i) => ({
        id: i + 1,
        elementId: element.id,
        degree: element.degree,
        label: element.name || "-",
      }));

      this.summaryTypes.push({
        id: 5,
        elementId: 9999999999999,
        degree: 333,
        label: "합계",
      });

      this.networkMapList = await this.api.getNetworkMaps(
        this.facilityModel.id,
        this.$route.params.modelId,
        this.currentElevationSetId
      );

      const matchingElement = this.networkMapList.find(
        (networkMap) =>
          networkMap.elevationId === this.summaryTypes[index].elementId
      );

      const networkMapId = matchingElement.id;

      this.networkMapSummaryList = await this.api.getNetworkMapSummaries(
        this.facilityModel.id,
        this.$route.params.modelId,
        this.currentElevationSetId,
        networkMapId
      );

      this.primaryDefectList = await this.api.getPrimaryDefects(
        this.facilityModel.id,
        this.$route.params.modelId,
        this.currentElevationSetId,
        networkMapId
      );

      let noData = false;

      try {
        const urlWithCacheBusting = `${
          matchingElement.inspectionNetworkMapUrl
        }?t=${new Date().getTime()}`;
        const response = await fetch(urlWithCacheBusting);
        if (
          !response.ok ||
          response.status == 304 ||
          matchingElement.inspectionNetworkMapUrl == null
        ) {
          noData = true;
          this.dxfText = null;
          // return;
          throw new Error("Failed to fetch DXF file from API");
        }
        this.dxfText = await response.text();
      } catch (error) {
        console.error("Error fetching DXF file:", error);
      }

      this.$nextTick(() => {
        this.isSummaryModalVisible = true;
        if (noData) {
          this.$store.commit(
            "openAlert",
            this.$t("albumAlert.finishTaskInSiriusEditor")
          );
        }
      });

      this.currentNetworkMapId = networkMapId;
    },
    handleCaptureList() {
      this.isCaptureList = !this.isCaptureList;
    },
    async handleDeleteSummaryType(index) {
      const facilityModel3DId = this.$route.params.modelId;

      try {
        const findSameIdItem = this.elevataionSetList.find(
          (item) => item.id === this.currentElevationSetId
        );
        const elevationId = findSameIdItem.elements[index].id;
        const deleteResponse = await RESTAPI.deleteSummaryTab(
          facilityModel3DId,
          findSameIdItem.roiPartId,
          this.currentElevationSetId,
          elevationId
        );

        const deleteResult = deleteResponse.data;
        if (deleteResult.success) {
          this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.deletedSuccessfully")}`
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async summaryTypeChanged(index) {
      if (index == 4) {
        this.networkMapSummaryList = [];
        this.primaryDefectList = [];

        let summaryListCounter = 0;
        for (let i = 0; i < this.summaryTypes.length - 1; i++) {
          const matchingElement = this.networkMapList.find(
            (networkMap) =>
              networkMap.elevationId === this.summaryTypes[i].elementId
          );
          const networkMapId = matchingElement.id;
          const tempNetworkMapSummaryList =
            await this.api.getNetworkMapSummaries(
              this.facilityModel.id,
              this.$route.params.modelId,
              this.currentElevationSetId,
              networkMapId
            );
          const tempPrimaryDefectList = await this.api.getPrimaryDefects(
            this.facilityModel.id,
            this.$route.params.modelId,
            this.currentElevationSetId,
            networkMapId
          );
          if (tempPrimaryDefectList.length > 0) {
            this.primaryDefectList = [
              ...this.primaryDefectList,
              ...tempPrimaryDefectList,
            ];
          }
          if (tempNetworkMapSummaryList.length == 0) continue;
          for (let j = 0; j < tempNetworkMapSummaryList.length; j++) {
            if (summaryListCounter === 0) {
              this.networkMapSummaryList.push(tempNetworkMapSummaryList[j]);
            } else {
              if (tempNetworkMapSummaryList[j].count != null)
                this.networkMapSummaryList[j].count +=
                  tempNetworkMapSummaryList[j].count;
              if (tempNetworkMapSummaryList[j].quantity != null)
                this.networkMapSummaryList[j].quantity +=
                  tempNetworkMapSummaryList[j].quantity;
            }
          }
          summaryListCounter++;
        }

        const matchingElement = this.networkMapList.find(
          (networkMap) =>
            networkMap.elevationId === this.summaryTypes[0].elementId
        );
        try {
          const urlWithCacheBusting = `${
            matchingElement.inspectionNetworkMapUrl
          }?t=${new Date().getTime()}`;
          const response = await fetch(urlWithCacheBusting);
          if (
            !response.ok ||
            response.status == 304 ||
            matchingElement.inspectionNetworkMapUrl == null
          ) {
            this.dxfText = null;
            throw new Error("Failed to fetch DXF file from API");
          }
          this.dxfText = await response.text();
        } catch (error) {
          console.error("Error fetching DXF file:", error);
          // alert('SIRIUS Editor에서 작업을 마무리 하십시오.');
        }
      } else {
        const matchingElement = this.networkMapList.find(
          (networkMap) =>
            networkMap.elevationId === this.summaryTypes[index].elementId
        );
        const networkMapId = matchingElement.id;

        this.networkMapSummaryList = await this.api.getNetworkMapSummaries(
          this.facilityModel.id,
          this.$route.params.modelId,
          this.currentElevationSetId,
          networkMapId
        );
        this.primaryDefectList = await this.api.getPrimaryDefects(
          this.facilityModel.id,
          this.$route.params.modelId,
          this.currentElevationSetId,
          networkMapId
        );
        try {
          const urlWithCacheBusting = `${
            matchingElement.inspectionNetworkMapUrl
          }?t=${new Date().getTime()}`;
          const response = await fetch(urlWithCacheBusting);
          if (
            !response.ok ||
            response.status == 304 ||
            matchingElement.inspectionNetworkMapUrl == null
          ) {
            this.$store.commit(
              "openAlert",
              "SIRIUS Editor에서 작업을 마무리 하십시오."
            );
            this.dxfText = null;
            throw new Error("Failed to fetch DXF file from API");
          }
          this.dxfText = await response.text();
        } catch (error) {
          console.error("Error fetching DXF file:", error);
        }

        this.currentNetworkMapId = networkMapId;
      }
    },

    summaryTypeNameChanged(idx, id, name) {
      this.currnetSortedElements[idx].name = name;
      this.api.updateElevation(
        this.currnetSortedElements[idx],
        this.facilityModel.id,
        this.$route.params.modelId,
        this.currentElevationSetId,
        id
      );
    },

    primaryDefectionChanged(idx, id, currentState, cause, solution) {
      this.primaryDefectList[idx].currentState = currentState;
      this.primaryDefectList[idx].cause = cause;
      this.primaryDefectList[idx].solution = solution;

      this.api.updatePrimaryDefects(
        this.primaryDefectList[idx],
        this.facilityModel.id,
        this.$route.params.modelId,
        this.currentElevationSetId,
        this.currentNetworkMapId,
        id
      );
    },
    async dxfTabChanged(idx) {
      const matchingElement = this.networkMapList.find(
        (networkMap) =>
          networkMap.elevationId === this.summaryTypes[idx].elementId
      );
      try {
        const urlWithCacheBusting = `${
          matchingElement.inspectionNetworkMapUrl
        }?t=${new Date().getTime()}`;
        const response = await fetch(urlWithCacheBusting);
        if (
          !response.ok ||
          response.status == 304 ||
          matchingElement.inspectionNetworkMapUrl == null
        ) {
          this.dxfText = null;
          throw new Error("Failed to fetch DXF file from API");
        }
        this.dxfText = await response.text();
      } catch (error) {
        console.error("Error fetching DXF file:", error);
        // alert('SIRIUS Editor에서 작업을 마무리 하십시오.');
      }
    },
    getSite() {
      if (
        !this.selectedFacility ||
        this.selectedFacility.id != this.$route.params.facilityId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.facilityId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch("setSelectedFacility", new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.facilityId);
      this.api.getFacilityModels(
        this.getFacilityModelsCallback,
        this.$route.params.facilityId
      );
    },
    getMapsCallback(data) {
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.selectedFacility.mapList = mapList;
        let map = mapList.find((map) => map.id == this.$route.params.mapId);
        this.$store.commit("setSelectedFacilityMap", map);
      }
    },
    getFacilityModelsCallback(data) {
      this.facilityModel = data.data.result[0];
      this.api.getModel(
        this.getModelCallback,
        this.facilityModel.id,
        this.$route.params.modelId
      );
    },
    getModelCallback(data) {
      let result = data.data.result;
      if (result) {
        this.model = new Model(result);
        this.loadModel(this.model.roiPartDownsizedModelUri);
        this.api.getCaptureInfos(
          this.getCaptureInfosCallback,
          this.facilityModel.id,
          this.$route.params.modelId
        );
        this.api.getElevationSets(
          this.getElevationSetsCallback,
          this.facilityModel.id,
          this.$route.params.modelId
        );
      }
    },
    async getCaptureInfosCallback(data) {
      let result = data.data.result;
      this.captureInfoList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          this.captureInfoList.push(new CaptureInfo(result[i]));
        }

        if (this.captureInfoList.length > 0) {
          const facilityModel3DId = this.$route.params.modelId;
          const roiPartId = this.captureInfoList[0].roiPartId;
          const elevationSetId = this.captureInfoList[0].id;

          try {
            // response 값에 해당하는 아이디가 없을 경우 -> 분석 준비 비활성화
            const listResult = await RESTAPI.getElevationCrackRecognitionList(
              facilityModel3DId,
              roiPartId,
              elevationSetId
            );
            const crackRecognitionData = listResult.result;

            // 분석이 시작된 균열망도 리스트 배열에 같은 id를 가진 아이템이 있는지 체크해서 있으면 true 아니면 false
            if (crackRecognitionData !== null) {
              this.captureInfoList.forEach((item, index) => {
                const matchedData = crackRecognitionData.find(
                  (data) => data.elevationSetId === item.id
                );

                this.captureInfoList[index].elevationCrackRecognitionInfo =
                  matchedData;
              });
            }

            // 입면도의 생성 상태값을 받아와서 저장해 준다.
            // response 값에서 id와 일치하는 값이 없거나,Generation In Progress 상태일 때 입면도 생성중 버튼 생성
            // 그 외에는 elevationSetStatus 값에 맞춘 버튼을 보여준다.
            const elevation = await RESTAPI.getElevationSetList(
              facilityModel3DId,
              roiPartId
            );

            const elevationResult = elevation.data.result;

            this.captureInfoList.forEach((item) => {
              const matchedElevation = elevationResult.find(
                (e) => e.editingParameterId === item.id
              );
              item.elevationSetInfo = matchedElevation || null;
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    getElevationSetsCallback(data) {
      if (data.data.success) {
        const result = data.data.result;
        this.elevataionSetList = [];
        if (result) {
          for (let i = 0; i < result.length; i++) {
            this.elevataionSetList.push(new ElevationSet(result[i]));
          }
        }
      } else {
        console.error(
          "Failed to retrieve elevation set data or no data available"
        );
      }
    },
    getNetworkMapsCallback(data) {
      if (data.data.success) {
        const result = data.data.result;
        this.networkMapList = [];
        if (result) {
          for (let i = 0; i < result.length; i++) {
            this.networkMapList.push(new NetworkMap(result[i]));
          }
        }
      } else {
        console.error(
          "Failed to retrieve elevation set data or no data available"
        );
      }
    },

    async tgetNetworkMapSummaries(networkMapId) {
      this.networkMapSummaryList = await this.api.getNetworkMapSummaries(
        this.tgetNetworkMapSummariesCallback,
        this.facilityModel.id,
        this.$route.params.modelId,
        this.currentElevationSetId,
        networkMapId
      );
    },
    tgetNetworkMapSummariesCallback(data) {
      if (data.data.success) {
        const result = data.data.result;
        this.networkMapSummaryList = [];
        if (result) {
          for (let i = 0; i < result.length; i++) {
            this.networkMapSummaryList.push(new NetworkMapSummary(result[i]));
          }
        }
      } else {
        console.error(
          "Failed to retrieve elevation set data or no data available"
        );
      }
    },
    loadModel(url) {
      this.loader = new ModelLoader(
        this.$refs.viewer,
        this.setCaptureImage,
        this.$refs.mainLoading
      );
      this.loader.load(url, this.loadModelCallback);
    },
    loadModelCallback() {
      this.loader.resetMinMaxRange();
      let heightRange = this.loader.getHeightRange();
      let widthRange = this.loader.getWidthRange();
      let depthRange = this.loader.getDepthRange();

      this.range.maxHeight = parseFloat(heightRange.max.toFixed(1));
      this.range.minHeight = parseFloat(heightRange.min.toFixed(1));
      this.range.maxWidth = parseFloat(widthRange.max.toFixed(1));
      this.range.minWidth = parseFloat(widthRange.min.toFixed(1));
      this.range.maxDepth = parseFloat(depthRange.max.toFixed(1));
      this.range.minDepth = parseFloat(depthRange.min.toFixed(1));

      this.setRangeLimit();

      this.loader.applyCropedColor(this.range);
      this.loader.setBoundingBoxVisibility(true);
    },
    setRangeLimit() {
      let heightRangeLimit = this.loader.getMinMaxHeight();
      let widthRangeLimit = this.loader.getMinMaxWidth();
      let depthRangeLimit = this.loader.getMinMaxDepth();

      this.rangeLimit.maxHeight = parseFloat(heightRangeLimit.max.toFixed(1));
      this.rangeLimit.minHeight = parseFloat(heightRangeLimit.min.toFixed(1));
      this.rangeLimit.maxWidth = parseFloat(widthRangeLimit.max.toFixed(1));
      this.rangeLimit.minWidth = parseFloat(widthRangeLimit.min.toFixed(1));
      this.rangeLimit.maxDepth = parseFloat(depthRangeLimit.max.toFixed(1));
      this.rangeLimit.minDepth = parseFloat(depthRangeLimit.min.toFixed(1));
      this.rangeLimit.boxMinHeight = parseFloat(heightRangeLimit.boxMin);
      this.rangeLimit.boxMinWidth = parseFloat(widthRangeLimit.boxMin);
      this.rangeLimit.boxMaxDepth = parseFloat(depthRangeLimit.boxMax);
    },
    setResetRange() {
      let heightRangeLimit = this.loader.getMinMaxHeight();
      let widthRangeLimit = this.loader.getMinMaxWidth();
      let depthRangeLimit = this.loader.getMinMaxDepth();
      this.setRange("minHeight", heightRangeLimit.min.toFixed(1));
      this.setRange("maxHeight", heightRangeLimit.max.toFixed(1));
      this.setRange("minWidth", widthRangeLimit.min.toFixed(1));
      this.setRange("maxWidth", widthRangeLimit.max.toFixed(1));
      this.setRange("minDepth", depthRangeLimit.min.toFixed(1));
      this.setRange("maxDepth", depthRangeLimit.max.toFixed(1));
    },
    checkRangeLimit() {
      if (this.range.minHeight < this.rangeLimit.minHeight)
        this.range.minHeight = this.rangeLimit.minHeight;
      if (this.range.maxHeight > this.rangeLimit.maxHeight)
        this.range.maxHeight = this.rangeLimit.maxHeight;

      if (this.range.minWidth < this.rangeLimit.minWidth)
        this.range.minWidth = this.rangeLimit.minWidth;
      if (this.range.maxWidth > this.rangeLimit.maxWidth)
        this.range.maxWidth = this.rangeLimit.maxWidth;

      if (this.range.minDepth < this.rangeLimit.minDepth)
        this.range.minDepth = this.rangeLimit.minWidth;
      if (this.range.maxDepth > this.rangeLimit.maxDepth)
        this.range.maxDepth = this.rangeLimit.maxDepth;
    },
    drawImage() {
      if (
        !this.$refs.imageCanvas ||
        !this.captureImages[this.selectedCaptureDeg / 90]
      ) {
        return;
      }
      const canvas = this.$refs.imageCanvasSrc;
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = this.captureImages[this.selectedCaptureDeg / 90]; // 선택된 각도에 따른 이미지 URL

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
      };
    },
    setRangeAxis(number) {
      this.rangeAxis = number;
      this.loader.setPlaneVisible(number);
    },
    setMainCaptureDeg(deg) {
      this.selectedCaptureDeg = deg;
    },
    captureModel(verticalCapture) {
      this.isNewCapture = true;
      this.captureImages = [null, null, null, null];
      this.verticalCapture = verticalCapture;
      this.loader.captureModel(verticalCapture);
    },
    setRange(property, value) {
      switch (property) {
        case "minHeight":
          this.range.minHeight = parseFloat(value);
          break;
        case "maxHeight":
          this.range.maxHeight = parseFloat(value);
          break;
        case "minWidth":
          this.range.minWidth = parseFloat(value);
          break;
        case "maxWidth":
          this.range.maxWidth = parseFloat(value);
          break;
        case "minDepth":
          this.range.minDepth = parseFloat(value);
          break;
        case "maxDepth":
          this.range.maxDepth = parseFloat(value);
          break;
        default:
          break;
      }
      this.loader.applyCropedColor(this.range);
    },
    setRotateDeg(axis, value) {
      switch (axis) {
        case "x":
          this.rotateDeg.x = parseFloat(value);
          this.loader.rotateModel(axis, this.rotateDeg.x, this.rangeAxis);
          break;
        case "y":
          this.rotateDeg.y = parseFloat(value);
          this.loader.rotateModel(axis, this.rotateDeg.y, this.rangeAxis);
          break;
        case "z":
          this.rotateDeg.z = parseFloat(value);
          this.loader.rotateModel(axis, this.rotateDeg.z, this.rangeAxis);
          break;
        default:
          break;
      }
      this.setRangeLimit();
      this.setResetRange();
      this.loader.applyCropedColor(this.range);
    },
    setCaptureImage(deg) {
      if (deg == 0) this.captureImages[0] = this.loader.getCaptureImage(deg);
      else if (deg == 90)
        this.captureImages[1] = this.loader.getCaptureImage(deg);
      else if (deg == 180)
        this.captureImages[2] = this.loader.getCaptureImage(deg);
      else if (deg == 270)
        this.captureImages[3] = this.loader.getCaptureImage(deg);
      this.setMainCaptureDeg(deg);
      this.setupZoom();
      if (this.isNewCapture) {
        this.drawImage();
        this.isNewCapture = false;
      }
    },
    setupZoom() {
      if (!this.$refs.imageCanvas) {
        return;
      }

      this.panZoomCaptureImage = Panzoom(this.$refs.imageCanvasSrc, {
        contain: "outside",
        cursor: "default",
        minScale: 1,
        maxScale: 20,
      });
      this.$refs.imageCanvasSrc.addEventListener(
        "wheel",
        this.panZoomCaptureImage.zoomWithWheel
      );
      this.$refs.imageCanvas.addEventListener("contextmenu", function (event) {
        event.preventDefault(); // 기본 컨텍스트 메뉴의 나타나는 것을 방지
      });
    },
  },
  created() {
    this.model = new Model();
  },
  mounted() {
    this.getSite();
    this.drawImage();
  },
  beforeUnmount() {
    if (this.loader) {
      this.loader.clear();
    }
  },
};
</script>

<style lang="scss">
.pattern_caputre_list_button {
  position: fixed;
  top: calc(50% - 25px);
  left: 0px;
  @include flexbox;
  background-color: rgba(32, 32, 32, 0.85);
  background-image: url("@/../public/images/rightArrow_white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 25px;
  border: 1px solid rgba(1, 250, 254, 0.3);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  width: 30px;
  height: 50px;
  padding: 10px 10px 10px 10px;
  z-index: 9;
  cursor: pointer;
  transition: 1s all;
}
.pattern_caputre_list_button:hover {
  background-image: url("@/../public/images/rightArrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 25px;
}
.pattern_caputre_list_button.on {
  left: 300px;
  transition: 1s all;
  background-image: url("@/../public/images/leftArrow_white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 25px;
}
.pattern_caputre_list_button.on:hover {
  background-image: url("@/../public/images/leftArrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px 25px;
}
.view {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.pattern_edit_viewer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-left: 1px solid rgba(1, 250, 254, 0.5);
}
.capture_main_img {
  width: 100%;
  height: 80%;
  padding: 10px;
  overflow: hidden;
}
.save_button {
  position: fixed;
  bottom: 20%;
  right: 0px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/save.svg");
  background-size: 20px 20px;
  margin: 10px;
  z-index: 10;
}
.save_button:hover {
  background-image: url("@/../public/images/save_hover.svg");
  background-size: 20px 20px;
}
.capture_main_img_src {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.capture_img_container {
  display: flex;
  justify-content: space-between;
  height: 20%;
  width: 100%;
  padding: 0px 10px 10px 10px;
  z-index: 5;
}
.capture_set {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc((100% - 30px) / 4);
}
.capture_img {
  @include flexbox;
  height: 80%;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 4px 1px rgba(1, 250, 254, 0.5);
  background-color: transparent;
}
.capture_img_selected {
  @include flexbox;
  height: 80%;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 4px 1px #4169e1;
  background-color: transparent;
}
.capture_img_src {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
