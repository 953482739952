<template>
  <div class="filter_box">
    <div class="filter_select_group">
      <DefaultSelectBox
        v-if="$i18n.locale === 'KO'"
        class="focus small"
        @controlOptionToggle="regionOptionToggle"
        :isOptionOpen="isRegionOpen"
        :defaultName="$t('region.all')"
        :selectedName="this.clickedRegionValue"
        @update:isOptionOpen="isRegionOpen = $event"
      >
        <ul>
          <li
            v-for="(region, index) in regionList"
            :key="region"
            :id="region + index"
            @click="clickRegionOption(region)"
            :class="{ active: region === this.clickedRegionValue }"
          >
            <button class="option_text" type="button">{{ region }}</button>
          </li>
        </ul>
      </DefaultSelectBox>
      <DefaultSelectBox
        class="focus small"
        @controlOptionToggle="typeOptionToggle"
        :isOptionOpen="isTypeOpen"
        :defaultName="$t('facilityType.all')"
        :selectedName="this.clickedTypeValue?.name"
        @update:isOptionOpen="isTypeOpen = $event"
      >
        <ul>
          <li
            v-for="type in facilityTypeList"
            :key="type.name + type.id"
            :id="type.name + type.id"
            @click="clickTypeOption(type)"
            :class="{ active: type.name === this.clickedTypeValue?.name }"
          >
            <button class="option_text" type="button">{{ type.name }}</button>
          </li>
        </ul>
      </DefaultSelectBox>
    </div>
    <div
      class="filter_result_group"
      v-if="clickedRegionValue || clickedTypeValue || searchKeyword"
    >
      <ClearButton @handleClear="$emit('handleClearFilter')" />
    </div>
  </div>
</template>
<script>
import ClearButton from "@/component/button/ClearButton.vue";
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { mapState } from "vuex";

export default {
  name: "FacilityFilterGroup",
  emits: [
    "handleClearFilter",
    "handleClickTypeOption",
    "handleClickRegionOption",
  ],
  components: { DefaultSelectBox, ClearButton },
  computed: {
    ...mapState(["facilityFilterValue"]),
  },
  props: {
    clickedRegionValue: {
      type: [Object, null],
      required: true,
    },
    clickedTypeValue: {
      type: [Object, null],
      required: true,
    },
    searchKeyword: {
      type: [String, undefined],
      required: true,
    },
    facilityTypeList: {
      type: Array,
      required: true,
    },
    regionList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isRegionOpen: false,
      isTypeOpen: false,
    };
  },
  methods: {
    /**
     * 지역 선택 옵션 박스를 열고 닫는다.
     */
    regionOptionToggle() {
      this.isRegionOpen = !this.isRegionOpen;
    },
    /**
     * 지역 옵션 클릭 선택
     */
    clickRegionOption(item) {
      this.$emit("handleClickRegionOption", item);
      this.isRegionOpen = false;
    },
    /**
     * 시설 선택 옵션 박스를 열고 닫는다.
     */
    typeOptionToggle() {
      this.isTypeOpen = !this.isTypeOpen;
    },
    /**
     * 시설 선택 옵션 박스 클릭
     */
    clickTypeOption(item) {
      this.isTypeOpen = false;
      this.$emit("handleClickTypeOption", item);
    },
    /**
     * 초기화 버튼
     */
    // clear() {
    //   this.clickedTypeValue = null;
    //   this.clickedRegionValue = null;
    // },
  },
};
</script>
<style lang="scss" scoped>
.filter_box {
  @include flexbox(center, flex-start, row);
  gap: 12px;
  margin-top: 10px;

  .filter_select_group {
    @include flexbox(center, flex-start, row);
    gap: 10px;
  }

  .filter_result_group {
    @include flexbox(center, space-between, row);

    .result_list {
      @include flexbox(center, flex-start, row);
      gap: 10px;
    }
  }

  .clear_button {
    @include flexbox;
    gap: 4px;
    color: $main_white;

    &:hover {
      text-decoration: underline;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      background: url("@/../public/images/arrow_rotate_white.svg") no-repeat
        center;
      background-size: contain;
    }
  }
}
</style>
