<template>
  <div v-if="uploadState" class="report_upload_modal">
    <div class="report_upload_modal_header">{{ $t("term.albumUpload") }}</div>
    <div
      class="report_upload_modal_x"
      v-if="!upload_process"
      @click="closeUploadModal"
    ></div>
    <div class="report_upload_modal_content_wrap">
      <div class="report_upload_modal_content">
        <input
          class="report_upload_modal_file_name input"
          :placeholder="$t('placeholder.inputAlbumName')"
          ref="album_name"
          @input="checkInputLimit($event, 10)"
        />
      </div>
      <div class="report_upload_modal_content">
        <label class="report_upload_modal_input button" for="report_file_input">
          {{ $t("button.selectFile") }}
          <input
            id="report_file_input"
            ref="reportFileInput"
            type="file"
            @change="updateFile($event)"
          />
        </label>
        <div class="report_upload_modal_file_name">
          {{ fileName }}
          <div class="report_upload_modal_gage" ref="reportUploadGage"></div>
        </div>
        <div
          class="report_upload_modal_button button"
          v-if="!upload_process"
          @click="clickUpload"
        >
          {{ $t("button.send") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISO } from "date-fns";
import { mapState } from "vuex";

export default {
  emits: ["closeUploadModal", "clickUpload", "uploadAlbum"],
  props: {
    uploadState: Boolean,
    volume: Object,
  },
  computed: {
    ...mapState(["selectedFacility", "selectedFacilityMap", "user"]),
  },
  data() {
    return {
      upload_process: false,
      fileName: this.$t("placeholder.selectFile"),
      file: null,
    };
  },
  methods: {
    checkInputLimit(event, limit) {
      if (event.target.value.length > limit) {
        this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.valueExceedLimitNotAllowed", {
            limit: limit,
          })}`
        );
        event.target.value = event.target.value.substr(0, limit);
      }
    },
    closeUploadModal() {
      this.$emit("closeUploadModal");
      this.fileName = this.$t("placeholder.selectFile");
      this.file = null;
      this.$refs.reportFileInput.value = null;
    },
    updateFile(event) {
      if (!this.upload_process) {
        if (event.target.files.length > 0) {
          let allowedExtensions = ["zip", "tgz", "tar"];
          if (
            !allowedExtensions.includes(
              event.target.files[0].name.split(".").pop().toLowerCase()
            )
          ) {
            this.$store.commit(
              "openAlert",
              `${this.$t("albumAlert.isNotCorrectFileType")}\n${this.$t(
                "albumAlert.pleaseSelectCorrectFileType",
                { fileTypes: "zip, tgz, tar" }
              )}`
            );
            this.$refs.reportFileInput.value = null;
          } else {
            this.fileName = event.target.files[0].name;
            this.file = event.target.files[0];
          }
        } else {
          this.fileName = this.$t("placeholder.selectFile");
        }
      }
    },
    clickUpload() {
      this.upload_process = true;
      if (this.user.role == "normal") {
        this.upload_process = false;
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        if (this.file && this.$refs.album_name.value) {
          if (this.file.size > 21474836480) {
            this.upload_process = false;
            this.$store.commit(
              "openAlert",
              `${this.$t("albumAlert.cannotExceedFileCapacity", {
                capacity: "20GB",
              })}`
            );
          } else {
            let body = {
              facilityId: this.$route.params.facilityId,
              facilityMapId: this.selectedFacilityMap.id,
              cameraId: 1,
              name: this.$refs.album_name.value,
              photoCount: 0,
              createdDatetime: formatISO(new Date()),
            };
            this.$emit("clickUpload", body, this.file);
          }
        } else if (!this.file) {
          this.upload_process = false;
          this.$store.commit("openAlert", this.$t("placeholder.selectFile"));
        } else if (!this.$refs.album_name.value) {
          this.upload_process = false;
          this.$store.commit(
            "openAlert",
            this.$t("placeholder.inputAlbumName")
          );
        }
      }
    },
    // postAlbumsCallback(result) {
    //   let formData = new FormData();
    //   formData.append("file", this.file);
    //   this.$axios
    //     .post(
    //       `http://1.222.89.166:20020/api/facilities/${this.$route.params.facilityId}/albums/${result.id}`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //         onUploadProgress: (progressEvent) => {
    //           var percentComplete = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           if (this.$refs.reportUploadGage) {
    //             this.$refs.reportUploadGage.style.width = percentComplete + "%";
    //           }
    //           if (percentComplete === 100)
    //             this.$refs.reportUploadGage.style.width = "0%";
    //         },
    //       }
    //     )
    //     .then((data) => {
    //       this.$refs.reportFileInput.value = null;
    //       this.$emit("uploadAlbum", result.id, data.data.result);
    //       this.closeUploadModal();
    //     })
    //     .catch();
    // },
    postAlbumsCallback(result) {
      let formData = new FormData();
      formData.append("file", this.file);
      this.$emit(
        "uploadAlbum",
        result.id,
        formData,
        this.$refs.reportUploadGage
      );

      // this.$axios.post(
      //     `http://:20020/api/facilities/${this.$route.params.facilityId}/albums/${result.id}`,
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //       onUploadProgress: (progressEvent) => {
      //         var percentComplete = Math.round(
      //           (progressEvent.loaded * 100) / progressEvent.total
      //         );
      //         if (this.$refs.reportUploadGage) {
      //           this.$refs.reportUploadGage.style.width = percentComplete + "%";
      //         }
      //         if (percentComplete === 100)
      //           this.$refs.reportUploadGage.style.width = "0%";
      //       },
      //     }
      //   )
      //   .then((data) => {
      //     this.$refs.reportFileInput.value = null;
      //     this.$emit("uploadAlbum", result.id, data.data.result);
      //     this.closeUploadModal();
      //   })
      //   .catch();
    },
  },
};
</script>

<style lang="scss">
.report_upload_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: calc(50% - 200px);
  top: calc(50% - 45px);
  width: 400px;
  height: 140px;
  z-index: 10;
  background: rgba(23, 23, 23, 0.6);
  border-radius: 10px;
  box-shadow: $main_box_shadow;
}
.report_upload_modal_header {
  @include flexbox;
  color: $main_white;
  font-size: 2rem;
  background-color: rgba(32, 32, 32, 0.85);
  width: 100%;
  height: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.report_upload_modal_x {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  background: url("@/../public/images/x_white.svg") no-repeat center;
  background-size: 20px 20px;
  cursor: pointer;
}
.report_upload_modal_content_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100px;
}
.report_upload_modal_content {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50px;
  padding: 0px 20px 0px 20px;
}
.report_upload_modal_input {
  @include flexbox;
  margin-right: 10px;
  width: 60px;
  height: 30px;
  font-size: 1.2rem;
}
#report_file_input {
  display: none;
}
.report_upload_modal_file_name {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 130px);
  height: 30px;
  background-color: rgba(32, 32, 32, 0.6);
  border-radius: 5px;
  color: $main_white;
  font-size: 1rem;
  padding-left: 10px;
  overflow: hidden;
}
.report_upload_modal_gage {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0%;
  height: 30px;
  background-color: rgba(1, 250, 254, 0.6);
}

.report_upload_modal_button {
  @include flexbox;
  margin-left: 10px;
  width: 50px;
  height: 30px;
  font-size: 1.4rem;
}
</style>
