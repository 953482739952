import { i18n } from "@/main";

/**
 * 영어로된 권한을 알맞은 언어로 변환해주는 함수.
 * @param {String} role
 * @returns 각 언어 선택에 알맞은 언어 반환.
 */
export const changeRoleToLanguage = (role) => {
  if (role === "admin") {
    return i18n.global.t("term.administrator");
  } else if (role === "manager") {
    return i18n.global.t("term.manager");
  } else {
    return i18n.global.t("term.normal");
  }
};
